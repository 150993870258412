import React, { useEffect, useState } from "react";
import { Table, Container, Alert, Spinner, Modal, Form, Button } from "react-bootstrap";
import { configJson } from "../../../Constent/config";
import { MdEdit } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

const SpecialtiesTable = () => {
  const [specialties, setSpecialties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);  // State to control modal visibility
  const [editingSpecialty, setEditingSpecialty] = useState(null);  // State to store the specialty being edited
  const navigate = useNavigate();

  // Fetch specialties data on component mount
  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await fetch(`${configJson.backend_URL}/specialties/`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch specialties.");
        }
        const data = await response.json();
        setSpecialties(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSpecialties();
  }, []);

  // Handle edit button click
  const handleEdit = (specialty) => {
    setEditingSpecialty(specialty); // Set the specialty to be edited
    setShowModal(true); // Show modal
  };

  // Handle save after editing
  const handleSaveEdit = async () => {
    // Optimistically update the local state first
    setSpecialties(prevSpecialties =>
      prevSpecialties.map(specialty =>
        specialty.speciality_id === editingSpecialty.speciality_id ? editingSpecialty : specialty
      )
    );
  
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/edit/${editingSpecialty.speciality_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingSpecialty), // Send updated specialty data to the backend
      });
  
      if (response.ok) {
        // If needed, you can fetch the updated data from the response
        const updatedSpecialty = await response.json();
      } else {
        alert("Failed to update specialty");
      }
    } catch (error) {
      console.error("Error updating specialty:", error);
      // Revert the state to the previous one if the update fails
      setSpecialties(prevSpecialties =>
        prevSpecialties.map(specialty =>
          specialty.speciality_id === editingSpecialty.speciality_id ? { ...specialty, ...editingSpecialty } : specialty
        )
      );
    } finally {
      setShowModal(false);  // Close the modal
      setEditingSpecialty(null);  // Clear the editing specialty
    }
  };
  
  

  // Handle input change for editing specialty
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingSpecialty({ ...editingSpecialty, [name]: value });
  };

  return (
    <Container>
      <div className="specialityClass" style={{ marginTop: "20px" }}>
        <h2>Specialties List</h2>
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : specialties.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Edit</th> 
              </tr>
            </thead>
            <tbody>
              {specialties.map((specialty) => (
                <tr key={specialty.speciality_id}>
                  <td>{specialty.specialty_name}</td>
                  <td>{specialty.description || "N/A"}</td>
                  <td>
                    <MdEdit
                      onClick={() => handleEdit(specialty)}  // Open the edit modal
                      style={{ cursor: 'pointer', color: 'blue' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="info">No specialties found</Alert>
        )}

        <button type="button" className="btn btn-secondary mt-3" onClick={() => navigate("/superadmindashboard")}>
          Back to dashboard
        </button>

        {/* Edit Specialty Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Specialty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editingSpecialty && (
              <Form>
                <Form.Group controlId="specialtyName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="specialty_name"
                    value={editingSpecialty.specialty_name}
                    onChange={handleInputChange}
                    placeholder="Enter specialty name"
                    required
                  />
                </Form.Group>
                
                <Form.Group controlId="description" className="mt-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={editingSpecialty.description}
                    onChange={handleInputChange}
                    placeholder="Enter description"
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveEdit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default SpecialtiesTable;
