import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { configJson } from "../../Constent/config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { SignInButton } from "../Login-page/Components/SignupBtton";
import TopBar from "../Home/Components/Topbar/TopBar";
import { SocialMedia } from "../ThemeStyle/RegisterStyle";
import { SuperadminForm, SuperadminContainer } from "./SuperAdminStyle";
import aticon from "../../assets/signup/at.png";
import keyicon from "../../assets/signup/key.png";
import eyeicon from "../../assets/signup/eye.png";
import eyeClose from "../../assets/signup/eyeclose.png";
import MobileTopBar from "../Home/Components/Topbar/MobileTopBar";
import axios from "axios"; // Import axios to make API requests
import App from "../../App.css"

/* All imports should be consolidated and used from an include file*/

const SuperAdminLogin = () => {
  const [, setToken] = useState("");
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { instance } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const navigate = useNavigate();

  //google model size
  const signWidth =
    window.innerWidth < 767
      ? "290px"
      : window.innerWidth >= 768 && window.innerWidth <= 1023
      ? "400px"
      : "400px";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to the superadmin/login endpoint
      const response = await axios.post(
        `${configJson.backend_URL}/superadmin/login`,
        {
          superadmin_username: formData.username,
          superadmin_password: formData.password,
        }
      );

      // Check the response status
      if (response.data.status) {
        // If login is successful
        console.log("Login successful: ", response.data);

        // Store the token if needed
        sessionStorage.setItem("Super_admin_token", response.data.token); // Store the token from backend

        // Navigate to the super admin dashboard
        navigate("/superadmindashboard");
      } else {
        // If login fails (invalid credentials, etc.)
        setErrorMessage(response.data.message); // Set the error message from the backend
        setShowErrorModal(true);
        alert(response.data.message); // Show the error message in an alert
      }
    } catch (error) {
      // Handle general errors (network issues, etc.)
      if (error.response && error.response.data) {
        // Show error message from the backend
        setErrorMessage(error.response.data.message);
        alert(error.response.data.message); // Show the error message in an alert
      } else {
        // Generic error handling (e.g., no response from server)
        setErrorMessage("An error occurred. Please try again.");
        alert("An error occurred. Please try again."); // Generic alert for unknown errors
      }
      setShowErrorModal(true);
    }
  };

  //passwors show hidden
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  //Error model show hidden
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  //update Size
  const updateSize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };
  useEffect(() => {
    updateSize();

    // Add resize event listener
    window.addEventListener("resize", updateSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  useEffect(() => {
    const token = localStorage.clear();
    setToken("");
  }, []);

  return (
    <SuperadminContainer ref={containerRef}>
      {containerWidth < 767 ? (
        <MobileTopBar titleName="Login" />
      ) : (
        <TopBar showUser={false} />
      )}
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center min-vh-100"
      >
        <div
          className="login-box p-4 border rounded-5 shadow-sm w-100 full-container"
          style={{
            maxWidth: "450px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <div className="header-text">
            <h3 className="header-text-part">Super Admin Login</h3>
          </div>
          <SuperadminForm>
            <Form onSubmit={handleSubmit} autocomplete="off">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Example@gmail.com"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
                <span className="input-img-container">
                  <img
                    src={aticon}
                    title=""
                    alt=""
                    className="sign-input-image"
                  />{" "}
                  <span className="sign-input-or">|</span>
                </span>
              </Form.Group>
              <Form.Group className="sh-pass" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="****************"
                  required
                  style={{ backgroundColor: "white", color: "black" }}
                />
                <span className="input-img-container">
                  <img
                    src={keyicon}
                    title=""
                    alt=""
                    className="sign-input-image"
                  />{" "}
                  <span className="sign-input-or">|</span>
                </span>

                <span className="show-pass">
                  {showPassword ? (
                    <img
                      onClick={togglePasswordVisibility}
                      src={eyeClose}
                      title=""
                      alt=""
                      className="sign-input-image-close"
                    />
                  ) : (
                    <img
                      onClick={togglePasswordVisibility}
                      src={eyeicon}
                      title=""
                      alt=""
                      className="sign-input-image"
                    />
                  )}
                </span>
              </Form.Group>
              <div className="text-center">
                <Button type="submit" className="doc-log-sub">
                  Submit
                </Button>
              </div>
            </Form>
          </SuperadminForm>
          <p className="text-end mb-0 loginlinks">
            <Link to={"/login"}>Login</Link>
          </p>
        </div>
        
      </Container>

      <Modal
        show={showErrorModal}
        onHide={handleCloseErrorModal}
        centered
        style={{ backgroundColor: "white", color: "black" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>
            <i
              className="fa fa-exclamation-triangle text-danger"
              aria-hidden="true"
            ></i>
          </div>
          <div className="pt-3">{errorMessage}</div>
        </Modal.Body>
      </Modal>
    </SuperadminContainer>
  );
};

export default SuperAdminLogin;