import React from "react";
import { Tab, Tabs, TabContent, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import AssessmentPage from "../AssessmentTemplate/AssessmentPage";
import DoctorDetails from "./DoctorDetails";
import "./DoctorDetails.css"

const Settings = () => {
  return (
    <Sidebar setuser={true}>
      <Container>
        <div className="heading-text">
          <h4 className="gradient-text">Settings</h4>
          <span class="heading-text-line"></span>
        </div>
       
        <Tabs defaultActiveKey="doctordetails" id="settings-tabs">
        <Tab eventKey="doctordetails" title="General">
            <TabContent>
              <div className="p-3">
               <DoctorDetails />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="assessment" title="Assessment Setup">
            <TabContent>
              <div className="p-3">
                <AssessmentPage />
              </div>
            </TabContent>
          </Tab>
          {/* <Tab eventKey="profile" title="Business Profile">
          <TabContent>
            <div className="p-3">
              <h4>Business Profile</h4>
              <p>Settings and information related to your business profile.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="billing" title="Billing">
          <TabContent>
            <div className="p-3">
              <h4>Billing</h4>
              <p>Manage your billing information here.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="staff" title="My Staff">
          <TabContent>
            <div className="p-3">
              <h4>My Staff</h4>
              <p>Details about staff members.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="opportunities" title="Opportunities">
          <TabContent>
            <div className="p-3">
              <h4>Opportunities</h4>
              <p>Manage opportunities and leads here.</p>
            </div>
          </TabContent>
        </Tab> */}
        </Tabs>
      </Container>
    </Sidebar>
  );
};

export default Settings;
