import React, { useEffect, useState } from "react";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import Table from "react-bootstrap/Table";
import { ConversationHistoryContainer, TableHeader } from "../Doctorstyle";
import searchicon from "../../../assets/sidebar/searchicon.png";
import detailicon from "../../../assets/medscribe/Completed.png";
import Processingimg from "../../../assets/medscribe/Loading 2.gif";
import { configJson } from "../../../Constent/config";
import { Mybtn } from "../../Engine-Page/mainEngine/MainEngineStyle";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import AssessmentModal from "./AssessmentModal";
import { jwtDecode } from "jwt-decode";
import { Alert, Spinner } from "react-bootstrap";
import App from "../../../App.css";
import {
  convertToKolkataTimeDOB,
  convertToKolkataTimeFormat,
} from "../../../helpers";

export default function Visithistory() {
  const [searchvalue, setSearchvalue] = useState("");
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("visitPage") ? localStorage.getItem("visitPage") : 1
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPage = 7;
  // const [headingTest, setHeadingText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  // const [currentAssessment, setCurrentAssessment] = useState("");
  // const handleMouseEnter = (event, assessment) => {
  //   setCurrentAssessment(assessment);
  //   setShowModal(true);
  // };
  const handleMouseLeave = () => {
    setShowModal(false);
  };

  // Filter data based on search term
  const filteredData = patients.filter((item) => {
    // const patientDate = new Date(item.interaction_date.split("T")[0]);
    // const from = fromDate ? new Date(fromDate) : null;
    // const to = toDate ? new Date(toDate) : null;
    const patientDate = convertToKolkataTimeDOB(item.interaction_date);
    const from = fromDate ? convertToKolkataTimeDOB(fromDate) : null;
    const to = toDate ? convertToKolkataTimeDOB(toDate) : null;

    const matchesSearch =
      item.Patient.first_name
        .toLowerCase()
        .includes(searchvalue.toLowerCase()) ||
      item.Patient.last_name.toLowerCase().includes(searchvalue.toLowerCase());

    const withinDateRange =
      (!from || patientDate >= from) && (!to || patientDate <= to);

    return matchesSearch && withinDateRange;
  });

  // Calculate paginated data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Next button click handler
  const handleNextPage = () => {
    if (startIndex + itemsPerPage < filteredData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
      localStorage.setItem("visitPage", currentPage + 1);
    }
  };

  // Previous button click handler (if needed)
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      localStorage.setItem("visitPage", currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    localStorage.setItem("visitPage", pageNumber);
  };

  //get vist History
  async function getVisitHistory(doctor_id) {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/${doctor_id}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        setPatients(result);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      getVisitHistory(decoded.doctor_id);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const visiblePageButtons = 5;

  const pageNumbers = [];
  const startPage = Math.max(
    1,
    currentPage - Math.floor(visiblePageButtons / 2)
  );
  const endPage = Math.min(totalPages, startPage + visiblePageButtons - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Sidebar setuser={true}>
      <ConversationHistoryContainer>
        <div className="heading-text">
          <h4 className="gradient-text">Visit History</h4>
          <span className="heading-text-line"></span>
        </div>
        <div className="search-items">
          <div className="date-filters">
            <div className="date-header">
              <div className="date">
                <input
                  type="date"
                  className="date-field"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
              <span className="arrow">
                <FaArrowRight />
              </span>
              <div className="date">
                <input
                  type="date"
                  className="date-field"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          </div>
          <input
            className="input-text"
            type="text"
            name="search"
            placeholder="Patient Name"
            value={searchvalue}
            onChange={(e) => {
              setSearchvalue(e.target.value);
              setCurrentPage(1);
            }}
          />
          <span className="search-icon-container">
            <img
              src={searchicon}
              alt=""
              title=""
              className="search-input-icon"
            />{" "}
          </span>
        </div>
        <TableHeader>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : patients.length > 0 ? (
            <Table
              borderless
              responsive
              className="table-styled tablecustomshrink"
            >
              <thead style={{ textAlign: "center" }}>
                <tr className="text-center">
                  {/* <th>Id</th> */}
                  <th>Patient Name</th>
                  {/* <th>Doctor Name</th> */}
                  <th>Visit Date/Time</th>
                  {/* <th>Assessment</th>
                <th>Plan</th> */}

                  <th className="text-center">Soap Notes</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {paginatedData.map((patient, index) => (
                  <tr key={index}>
                    {/* <td>{patient.interaction_id}</td> */}
                    <td>
                      {patient.Patient.first_name.charAt(0).toUpperCase() +
                        patient.Patient.first_name.slice(1).toLowerCase()}{" "}
                      {patient.Patient.last_name.charAt(0).toUpperCase() +
                        patient.Patient.last_name.slice(1).toLowerCase()}
                    </td>
                    <td>
                      {convertToKolkataTimeFormat(patient.interaction_date)}
                      {/* {new Date(patient.interaction_date)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")} <span>/</span>
                      {patient.interaction_date.replace( */}
                      {/* /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}Z$/,
                        "$2"
                      )} */}
                    </td>
                    {/* <td
                    onMouseEnter={(e) => {
                      handleMouseEnter(e, patient.assessment);
                      setHeadingText("Assessment");
                    }}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: "pointer" }}
                  >
                    {patient.assessment.split(" ").slice(0, 3).join(" ")}
                    <span className="link-line">...</span>
                  </td>
                  <td

                    onMouseEnter={(e) => {
                      handleMouseEnter(e, patient.plan);
                      setHeadingText("Plan");
                    }}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: "pointer" }}
                  >
                    {patient.plan.split(" ").slice(0, 3).join(" ")}
                    <span className="link-line">...</span>
                  </td> */}

                    {patient.interaction_status === "1" ? (
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="table-record-btn"
                          onClick={() =>
                            navigate("/soapnotes", {
                              state: {
                                patientFirstName: patient.Patient.first_name,
                                patientLastName: patient.Patient.last_name,
                                interaction_id: patient.interaction_id,
                                visitdate: patient.interaction_date,
                              },
                            })
                          }
                        >
                          <img
                            src={detailicon}
                            title=""
                            alt=""
                            className="table-record-icon"
                          />
                        </button>
                      </td>
                    ) : (
                      <td style={{ textAlign: "center" }}>
                        <div className="Processing-container">
                          <img
                            src={Processingimg}
                            title="Processing"
                            alt=""
                            className="Processing-icon"
                          />
                          <p className="process-text">Processing...</p>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="info">No Patient History found</Alert>
          )}

          {showModal && (
            <AssessmentModal
              show={showModal}
              handleClose={handleMouseLeave}
              // assessment={currentAssessment}
              // headingName={headingTest}
            />
          )}
          <div className="next-btn-container my-2">
            {currentPage === 1 ? (
              <div></div>
            ) : (
              <Mybtn>
                <Button className="new-style-btn" onClick={handlePrevPage}>
                  Previous
                </Button>
              </Mybtn>
            )}

            <div className="flex items-center gap-2">
              {/* Always show the first page */}
              {startPage > 1 && (
                <>
                  <Button
                    className={`!border-none ${
                      currentPage === 1 ? "!bg-gray-400" : "!bg-[#414040]"
                    }`}
                    onClick={() => handlePageClick(1)}
                  >
                    1
                  </Button>
                  {startPage > 2 && <span>...</span>}
                </>
              )}

              {/* Show visible page buttons */}
              {pageNumbers.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  className={`!border-none ${
                    pageNumber === currentPage
                      ? "!bg-gray-400"
                      : "!bg-[#414040]"
                  }`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </Button>
              ))}

              {/* Always show the last page */}
              {endPage < totalPages && (
                <>
                  {endPage < totalPages - 1 && <span>...</span>}
                  <Button
                    className={`!border-none ${
                      currentPage === totalPages
                        ? "!bg-gray-400"
                        : "!bg-[#414040]"
                    }`}
                    onClick={() => handlePageClick(totalPages)}
                  >
                    {totalPages}
                  </Button>
                </>
              )}
            </div>
            {startIndex + itemsPerPage >= filteredData.length ? (
              <p></p>
            ) : (
              <Mybtn>
                <Button className="new-style-btn" onClick={handleNextPage}>
                  Next
                </Button>
              </Mybtn>
            )}
          </div>
        </TableHeader>
      </ConversationHistoryContainer>
    </Sidebar>
  );
}
