import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { configJson } from "../../../Constent/config";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import detailicon from "../../../assets/table/search-file.png";
import { TableContainers } from "../Doctorstyle";

const InteractionTable = () => {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { state } = useLocation();
  const [data, setData] = useState({
    patient_id: state?.patient_id || "",
    doctor_id: state?.doctor_id || "",
    first_name: state?.first_name || "",
    last_name: state?.last_name || "",
  });
  console.log(data);
  const fetchInteractions = async (patientId, doctorId) => {
    console.log(patientId, doctorId);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/${patientId}/${doctorId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }
      const result = await response.json();
      console.log(result);
      setInteractions(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(data.patient_id, data.doctor_id);
    fetchInteractions(data.patient_id, data.doctor_id);
  }, [data.patient_id, data.doctor_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Sidebar setuser={true}>
      <TableContainers>
        <div className="heading-text">
          <h4 className="gradient-text">Interaction List</h4>
          <span className="heading-text-line"></span>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Interaction ID</th>
              <th>Patient Name</th>
              <th>Patient ID</th>
              <th>Doctor ID</th>
              <th>interaction status</th>
              <th>Date</th>
              <th className="text-center">Details</th>
            </tr>
          </thead>
          <tbody>
            {interactions.length > 0 ? (
              interactions.map((interaction) => (
                <tr key={interaction.interaction_id}>
                  <td>{interaction.interaction_id}</td>
                  <td>
                    {" "}
                    <span>
                      {data.first_name.charAt(0).toUpperCase() +
                        data.first_name.slice(1).toLowerCase()}{" "}
                      {data.last_name.charAt(0).toUpperCase() +
                        data.last_name.slice(1).toLowerCase()}
                    </span>
                  </td>
                  <td>{interaction.patient_id}</td>
                  <td>{interaction.doctor_id}</td>
                  <td>{interaction.interaction_status}</td>
                  <td>
                    {new Date(interaction.created_at).toLocaleDateString()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      className="table-record-btn"
                      //   onClick={() =>
                      //     navigate("/interactionlist", {
                      //       state: {
                      //         patient_id: patient.patient_id,
                      //         doctor_id: patient.doctor_id,
                      //         first_name:patient.first_name,
                      //         last_name:patient.last_name
                      //       },
                      //     })
                      //   }
                    >
                      <img
                        src={detailicon}
                        title=""
                        alt=""
                        className="table-record-icon"
                      />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No interactions found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainers>
    </Sidebar>
  );
};

export default InteractionTable;
