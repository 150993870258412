import React, { useState } from "react";
import { FaTimes, FaCamera,FaPaperPlane } from "react-icons/fa";
import ChatAudioText from "../Engine-Page/mainEngine/Components/ChatAudioText"; 
// import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";

const AdminChat = () => {
  const [isRes, setIsRes] = useState(false);
  const [messages, setMessages] = useState([
    { type: "bot", text: "Hey, I'm meds bot, how can I assist you?" },
    { type: "bot", text: "Here is the frequently used chipset" },
  ]);
  const [inputText, setInputText] = useState("");
  const [imageFile, setImageFile] = useState(null);
  // const [staticQns, setStaticQns] = useState('')

  // Function to handle image file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(URL.createObjectURL(file));
    }
  };

  // Function to simulate bot response
  const simulateBotResponse = (text) => {
    const lowerText = text.toLowerCase();

    if (lowerText.includes("add doctor")) {
      addForm("doctor");
    } else if (lowerText.includes("add technician")) {
      addForm("technician");
    } else if (lowerText.includes("add front office staff")) {
      addForm("front office staff");
    }
  };

  // Add form fields based on the selected option
  const addForm = (formType) => {
    let formFields = [];
    let botText = "";

    if (formType === "doctor") {
      botText = "Sure, I need a few details about the doctor";
      formFields = [
        { label: "Name", value: "" },
        { label: "License Number", value: "" },
        { label: "Phone Number", value: "" },
        { label: "Email", value: "" },
        { label: "Password", value: "" },
      ];
    } else if (formType === "technician") {
      botText = "Please provide the technician's details";
      formFields = [
        { label: "Name", value: "" },
        { label: "Certification ID", value: "" },
        { label: "Phone Number", value: "" },
        { label: "Email", value: "" },
      ];
    } else if (formType === "front office staff") {
      botText = "Enter details for the front office staff";
      formFields = [
        { label: "Name", value: "" },
        { label: "Position", value: "" },
        { label: "Phone Number", value: "" },
        { label: "Email", value: "" },
      ];
    }

    setMessages((prev) => [
      ...prev,
      { type: "bot", text: botText },
      { type: "form", fields: formFields },
    ]);
  };

  // Handle sending user messages
  const handleSendMessage = () => {
    if (inputText.trim()) {
      setMessages([...messages, { type: "user", text: inputText }]);
      setInputText("");
      simulateBotResponse(inputText);
    }
  };

  // Render image preview if available
  const renderImagePreview = () => {
    if (imageFile) {
      return <img src={imageFile} alt="Preview" width={50} className="preview-image" />;
    }
    return null;
  };

  return (
    <div className="sticky w-full bottom-0 p-4 bg-white flex flex-col gap-2 container z-3">
      {/* Chatbot responses section */}
      {isRes && (
        <div className="bg-[#F6F6F6] border border-[#BBBBBB] rounded-xl pl-4 pb-4 pr-2 pt-2 flex flex-col gap-2 h-[303px] text-sm overflow-auto">
          {/* Close button */}
          <div
            className="text-sm flex justify-end cursor-pointer"
            onClick={() => setIsRes(false)}
          >
            <FaTimes />
          </div>

          {/* Messages rendering */}
          {messages.map((message, index) => (
            <div key={index} className="flex items-center gap-2">
              {message.type === "bot" && (
                <>
                  <img src="/bot.png" width={20} alt="Bot" />
                  <div>{message.text}</div>
                </>
              )}
              {message.type === "user" && (
                <div className="rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]">
                  <img src="/user.png" width={15} alt="User" />
                </div>
              )}
              {message.type === "form" && (
                <div className="bg-[#EAEAEA] rounded p-3 py-2 w-full">
                  {message.fields.map((field, idx) => (
                    <div key={idx} className="font-semibold">
                      {field.label}:
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          {/* Chipset buttons */}
          <div className="flex gap-2">
            <button
              onClick={() => simulateBotResponse("Add Doctor")}
              className="py-1 px-4 text-white rounded"
              style={{
                background: "linear-gradient(to right, #1C1C1C, #4D4D4D)",
              }}
            >
              Add Doctor
            </button>
            <button
              onClick={() => simulateBotResponse("Add Technician")}
              className="py-1 px-4 text-white rounded"
              style={{
                background: "linear-gradient(to right, #1C1C1C, #4D4D4D)",
              }}
            >
              Add Technician
            </button>
            <button
              onClick={() => simulateBotResponse("Add Front Office Staff")}
              className="py-1 px-4 text-white rounded"
              style={{
                background: "linear-gradient(to right, #1C1C1C, #4D4D4D)",
              }}
            >
              Add Front Office Staff
            </button>
          </div>
        </div>
      )}

      {/* Chatbot input and interaction section */}
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-4 p-2 px-4 bg-[#EEEEEE] border border-[#BBBBBB] rounded-3xl">
          <input
            type="text"
            placeholder="Ask your questions.."
            className="flex-1 bg-transparent outline-none border-none"
            onClick={() => setIsRes(true)}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          {renderImagePreview()}
          <div className="flex items-center gap-3">
            <button
              onClick={() => document.getElementById('imageUpload').click()}
              className="text-xl mr-1"
            >
              <FaCamera />
            </button>
            <input
              type="file"
              id="imageUpload"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileChange}
            />
            <ChatAudioText setVoiceInputText={(text) => setInputText(text)} />
            <button onClick={handleSendMessage} className="text-xl">
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </div>
{/* 
      <ChatbotQuery
          // organizationId={decode.organization_id}
          // doctorId={decode.doctor_id}
          // patientId={interactions?.patient_id}
          staticQns={staticQns}
          setStaticQns={setStaticQns}
        /> */}

    </div>
  );
};

export default AdminChat;
