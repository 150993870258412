import React, { useState, useEffect, useRef } from 'react'
import Sidebar from '../Home/Components/Sidebar/Sidebar'
import { FaSearch } from 'react-icons/fa'
import { Switch, Modal } from 'antd';
import './frontoffice.css'
import { configJson } from '../../Constent/config';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { formatDate } from '../../helpers';
import { useReactToPrint } from 'react-to-print';
import { Spinner } from 'react-bootstrap';
import Webcam from 'react-webcam';
import UploadFiles from '../Engine-Page/mainEngine/Components/UploadFiles';
import ChatbotQuery from '../Engine-Page/mainEngine/Components/ChatbotQuery';
import { DateTime } from "luxon"
import axios from 'axios';


const data = []

const TechActivity = () => {
    const [content, setContent] = useState('allPatients')
    const [patients, setPatients] = useState([])
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchPatient, setSearchPatient] = useState("");
    const [isModal, setIsModal] = useState(false)
    const [isRes, setIsRes] = useState(false)
    const [openCam, setOpenCam] = useState(false)
    const [isFrontCamera, setIsFrontCamera] = useState(true)
    const [file, setFile] = useState(null)
    const cameraRef = useRef(null)
    const [uploadModal, setUploadModal] = useState(false)
    const [userDetails, setUserDetails] = useState({
        patientId: "",
        doctorId: "",
      });

    const [history, setHistory] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const fetchPatients = async (organization_id, startDate, endDate) => {
        setLoading(true)
        try {
          const response = await fetch(`${configJson.backend_URL}/interaction/tech/feed/${organization_id}/${startDate}/${endDate}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`, // Include the token for authorization
            },
          });
          if (!response.ok) throw new Error("Failed to fetch patients");

          const data = await response.json();
          setPatients(data);
        } catch (error) {
          console.error("Error fetching patients:", error);
        } finally {
            setLoading(false)
        }
      };

      useEffect(() => {
        const token1 = sessionStorage.getItem("orgtoken");
        const utcDate = DateTime.now().setZone("utc");

        // Format the current UTC date to just the date (YYYY-MM-DD)
        const formattedUTCDate = utcDate.toFormat("yyyy-MM-dd");

        // Create a variable for the date 5 days ago
        const last5Days = utcDate.minus({ days: 5 });
        const formattedLast5Days = last5Days.toFormat("yyyy-MM-dd");
        if (token1) {
          const decoded = jwtDecode(token1);
          fetchPatients(decoded.organization_id, formattedLast5Days, formattedUTCDate); // Fetch patients based on decoded org_id
        } else {
          navigate("/login"); // Navigate to login if token is not present
        }
      }, [navigate]);

    //   console.log(patients)

    const handleSearch = (e) => {
        setSearchPatient(e.target.value);
    };

    const filteredByDate = (list) => {
        if (!startDate && !endDate) return list;

        const start = new Date(startDate);
        const end = new Date(endDate);

        return list.filter((data) => {
          const updatedAt = new Date(data.created_at);
          return (
            (!startDate || updatedAt >= start) && (!endDate || updatedAt <= end)
          );
        });
      };

   
    const filteredPatients = patients?.filter((interaction) =>
        interaction?.Patient?.first_name?.toLowerCase().includes(searchPatient.toLowerCase())
    );

    const patientList = filteredByDate(filteredPatients)?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
    });


    const handleCapture = () => {
        if (file !== null) {
            setFile(null);
        }
        
        const screenshot = cameraRef?.current?.getScreenshot();
        
        if (screenshot) {
            // Convert base64 data URL to a Blob
            const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(base64.split(',')[1]);
            const byteArrays = [];
        
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
        
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }
        
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
        
            return new Blob(byteArrays, { type: contentType });
            };
        
            // Convert base64 to Blob
            const blob = base64ToBlob(screenshot, 'image/jpeg');
            
            // Create a File object with the new name
            const fileName = `${new Date().toISOString()}.jpg`;
            const renamedFile = new File([blob], fileName, { type: 'image/jpeg' })
        
            // Set the renamed file
            setFile(renamedFile);
        }
    };

    const handleSwitchCamera = () => {
        setIsFrontCamera((prev) => !prev);
    };

     // Stop the webcam stream whenever openCam becomes false
     useEffect(() => {
        if (!openCam && cameraRef.current) {
            const stream = cameraRef.current.video.srcObject;
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                cameraRef.current.video.srcObject = null;  // Clear video source
            }
        }
    }, [openCam]);

    const  getVisitHistory = async (id, startDate, endDate) => {
        setIsLoading(true)
        try {
          const response = await axios.get(`${configJson.backend_URL}/interactionDetail/details/notes/${id}/${startDate}/${endDate}`);
          setHistory(response?.data)
        } catch (error) {
          console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

      useEffect(() => {
        const token = sessionStorage.getItem("orgtoken");
        const utcDate = DateTime.now().setZone("utc");

    // Format the current UTC date to just the date (YYYY-MM-DD)
        const formattedUTCDate = utcDate.toFormat("yyyy-MM-dd");

        // Create a variable for the date 5 days ago
        const last5Days = utcDate.minus({ days: 5 });
        const formattedLast5Days = last5Days.toFormat("yyyy-MM-dd");

        if (token) {
          const decoded = jwtDecode(token);
        //   console.log(decoded)
            console.log("Fetching visit history for", formattedLast5Days, "to", formattedUTCDate);

          getVisitHistory(decoded.organization_id, formattedLast5Days, formattedUTCDate);
        } else {
          navigate("/login");
        }
      }, [navigate]);

    

  return (
    <Sidebar setuser={true}>
        <UploadFiles 
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        patientId={userDetails?.patientId}
        doctorId={userDetails?.doctorId}
        setUploadModal={setUploadModal}
        />
        
        <div className='flex items-center justify-center w-full m-auto sticky top-0 z-50 bg-white'>
            <div
            className={`m-auto !w-1/2 cursor-pointer border-b-2  ${content === 'allPatients' ? '!border-[#ACACAC]' : ''}`}
            style={{borderColor: 'transparent'}}
            onClick={() => setContent('allPatients')}
            >
                <img src={`/patient.png`} className='m-auto' width={32} />
            </div>
            <div
            className={`m-auto !w-1/2 cursor-pointer border-b-2  ${content === 'patient' ? '!border-[#ACACAC]' : ''}`}
            style={{borderColor: 'transparent'}}
            onClick={() => setContent('patient')}
            >
                <img src={`/follow-up.png`} className='m-auto' width={32} />
            </div>
        </div>
        {content === 'allPatients' && 
        <div className='flex items-center justify-end sticky top-[34px] z-40 py-4 px-4 sm:px-10 bg-white'>
            <div className="flex flex-col sm:flex-row items-end sm:items-center gap-2">
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2 text-xs p-2 border-[1px] border-[#C0C0C0] w-fit rounded-lg h-[32px]">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="outline-none cursor-pointer bg-transparent"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="outline-none cursor-pointer bg-transparent"
                    />
                    <img src={`/calendar.png`} alt="" width={12} />
                    </div>
                </div>
                <div className="flex items-center p-2 h-[32px] border-[1px] border-[#C0C0C0] rounded-lg text-sm placeholder:text-sm">
                    <input
                    className="ml-2 bg-transparent outline-none"
                    type="text"
                    placeholder="Search by Name, Id.."
                    onChange={handleSearch}
                    value={searchPatient}
                    />
                    <FaSearch />
                </div>
            </div>
        </div>}
        {content === 'allPatients' &&
        <div className='text-textColor flex flex-col gap-4 px-2 sm:px-10'>
            {!loading ? <div className='flex flex-col gap-4 mb-5'>
                {patientList?.map((data, index) => {
                    // console.log(data)
                    return (
                        <div
                        className='bg-[#F4F4F4] rounded-2xl p-2 sm:p-4 flex flex-col gap-3 drop-shadow-lg'
                        key={index}
                        >
                            <div className='flex items-center justify-between'>
                                <div className='flex items-center gap-2'>
                                    <div className='rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]'>
                                        <img src={`/user.png`} width={15} />
                                    </div>
                                    <div>
                                        <div className='text-base font-bold'>{data.Patient.first_name} {data.Patient.last_name}</div>
                                        <div className='text-sm flex items-center gap-1'>
                                            <div className='font-bold'>Follow-Up</div>
                                            <div>{formatDate(data.created_at)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row items-center gap-2'>
                                    <button
                                    className='px-3 py-1 rounded-2xl text-white text-sm'
                                    style={{ background: 'linear-gradient(to right, #1C1C1C, #4D4D4D)' }}
                                    onClick={() => {
                                        setUserDetails({
                                            patientId: data.patient_id,
                                            doctorId: data.doctor_id
                                        })
                                        setUploadModal(true)
                                    }}
                                    >
                                        Documents
                                    </button>
                                    {/* <Switch checkedChildren="In" unCheckedChildren="Out" /> */}
                                </div>
                            </div>
                            <div className='border-[#9C9C9C] border-[1px] rounded-2xl px-6 py-2 bg-white min-h-32 text-[#666666]'>
                                <div className='flex items-center gap-1 font-semibold'>
                                    <span>Patient ID:</span>
                                    <span>{data.patient_id}</span>
                                </div>
                                {data?.InteractionDetails.length > 0 ? (
                                    data.InteractionDetails.map((detail, index) => {
                                        if (detail.interaction_detail_type === 'soap-notes') {
                                            // const planText = detail.processedFileContent.match(/\*\*(Treatment )?Plan:\*\* (.*?)(?=\n|$)/g);
                                            return (
                                                <div className='mt-2' key={index}>
                                                    {detail.processedFileContent.match(/\*\*(Treatment )?Plan:\*\* (.*?)(?=\n|$)/g)}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                ) : <></>}

                            </div>
                        </div>
                    )})
                }
            </div>
            :
            <div className='w-full flex items-center justify-center h-[30vh]'><Spinner className='m-auto' /> </div>
            }
        </div>
        }

        {content === 'patient' &&
        <div className='px-2 sm:px-10 py-4 flex flex-col gap-4'>
            {isLoading ? 
            <Spinner animation='border' className='m-auto' /> 
            : 
            <>
              {history?.filter((data) => {
                    const createdAtDate = new Date(data.created_at);
                    const today = new Date();
                    return (
                        createdAtDate.getDate() === today.getDate() &&
                        createdAtDate.getMonth() === today.getMonth() &&
                        createdAtDate.getFullYear() === today.getFullYear()
                        );
                    })
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map((data) => {
                return (
                    <div 
                    className='bg-[#F4F4F4] rounded-2xl p-4 flex flex-col gap-3 drop-shadow-lg text-textColor w-full' 
                    >
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center gap-2'>
                                <div className='rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]'>
                                    <img src={`/user.png`} width={15} />
                                </div>
                                <div>
                                    <div className='text-base font-bold'>{data?.Patient?.first_name} {data?.Patient?.last_name}</div>
                                    <div className='text-sm flex items-center gap-1'>
                                        <span className='font-bold'>Follow-Up</span>
                                        <span>{formatDate(data?.created_at)}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <Switch checkedChildren="In" unCheckedChildren="Out" /> */}
                        </div>
                        <div className='border-[#9C9C9C] border-[1px] rounded-2xl px-6 py-2 bg-white'>
                            {/* <div className='font-semibold'>Existing patient:</div> */}
                            <div className=' flex items-center gap-1'>
                                <span className='font-semibold'>Name:</span>
                                <span>{data?.Patient?.first_name} {data?.Patient?.last_name}</span>
                            </div>
                            <div className=' flex items-center gap-1'>
                                <span className='font-semibold'>DOB:</span>
                                <span>{data?.Patient?.date_of_birth}</span>
                            </div>
                            <div className=' flex items-center gap-1'>
                                <span className='font-semibold'>ID:</span>
                                <span>{data?.patient_id}</span>
                            </div>
                            <div className=' flex items-center gap-1'>
                                <span className='font-semibold'>Phone No.:</span>
                                <span>{data?.Patient?.phone_number}</span>
                            </div>
                            <div className=' flex items-center gap-1'>
                                <span className='font-semibold'>Doctor:</span>
                                <span>{data?.Doctor?.first_name} {data?.Doctor?.last_name}</span>
                            </div>
                            {data?.InteractionDetails?.map((detail) => {
                                if (detail?.interaction_detail_type == 'soap-notes') {
                                    return (
                                        <>
                                            <div className='font-bold my-2'>{detail.processedFileContent.match(/\*\*(Treatment )?Plan:\*\* (.*?)(?=\n|$)/g)}</div>
                                            {/* <div className='font-bold my-2'>Doctor suggest to take Blood test, X-ray, and visit DR Raja for 2nd opinion</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div>
                                            <div>Blood test _ _ _ _ _ _ _ _ _ _ _ Room No 12</div> */}
                                        </>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                        <button
                        className='px-4 py-1 text-white w-fit rounded-[25px] m-auto opacity-60'
                        style={{ background: 'linear-gradient(to right, #1C1C1C, #4D4D4D)' }}
                        onClick={() => setIsModal(true)}
                        disabled
                        >
                            Prescription
                        </button>
                    </div>
                )
            })}
            </>}
        </div>
        }

        <PrescModal
        isModal={isModal}
        setIsModal={setIsModal}
        />

 
        <Modal
            open={openCam} 
            onClose={() => setOpenCam(false)}
            onCancel={() => setOpenCam(false)}
            footer={false}
        >
            {file == null && 
            <div className='mt-4'>
                <Webcam
                    audio={false}
                    ref={cameraRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                    facingMode: !isFrontCamera ? 'user' : { exact: 'environment' },
                    }}
                />
                <button onClick={handleSwitchCamera}>Switch Camera</button>
            </div>
            }
            <button 
                className="text-blue-500"
                onClick={handleCapture}
            >
                Take Photo
            </button>
        </Modal>

        <div className='sticky w-full bottom-0 p-4 pb-0 bg-white flex flex-col gap-2'>
           {/* <ChatbotQuery /> */}
        </div>
    </Sidebar>
  )
}

export default TechActivity


const PrescModal = ({ isModal, setIsModal }) => {
    const printRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => {
            // console.log(printRef.current);
            return printRef.current;
        },
    });

    return (
        <Modal
        open={isModal}
        onClose={() => setIsModal(false)}
        onCancel={() => setIsModal(false)}
        title=' '
        footer={false}
        >
            <div
            className='bg-[#FAFAFA] p-2 rounded'
            ref={printRef}
            >
                <div className='flex items-center justify-between'>
                    <div>
                        <img src={`/gunam.png`} alt='Gunam' width={200} />
                    </div>
                    <div className='text-right'>
                        <div className='font-bold'>Gunam Super Speciality Hospital</div>
                        <div className='text-sm'>Health Care You Deserve </div>
                        <div className='font-bold'>Dr. John</div>
                        <div className='text-sm'>MDS, MBBS</div>
                    </div>
                </div>
                <div className='border-b border-[#696969] my-4'></div>

                <div className='flex items-center flex-wrap justify-around'>
                    <div className='flex items-center gap-1'>
                        <div>Patient Name:</div>
                        <div>Christy</div>
                    </div>
                    <div className='flex items-center gap-1'>
                        <div>Age:</div>
                        <div>36</div>
                    </div>
                    <div className='flex items-center gap-1'>
                        <div>Age:</div>
                        <div>36</div>
                    </div>
                    <div className='flex items-center gap-1'>
                        <div>Date:</div>
                        <div>24-10-2024</div>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Medication</th>
                            <th>Dosage &#40;M-A-N&#41;</th>
                            <th>Period</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Paracetomal 100</td>
                            <td>1-0-1</td>
                            <td>5 Days</td>
                            <td>9am & 9pm AF</td>
                        </tr>
                    </tbody>
                </table>

                <div className='mt-20 text-right'>Seal & Signature</div>
            </div>
            <div className='flex items-center justify-end mt-2'>
                    {/* <ReactToPrint
                    trigger={() =>
                        <button className='text-white px-4 py-1 rounded-2xl'
                        style={{ background: 'linear-gradient(to right, #1C1C1C, #4D4D4D)' }}>Print</button>}
                        content={() => printRef.current}
                    /> */}

                    <button
                    className='text-white px-4 py-1 rounded-2xl'
                    style={{ background: 'linear-gradient(to right, #1C1C1C, #4D4D4D)' }}
                    onClick={handlePrint}
                    >
                        Print
                    </button>
                </div>
        </Modal>
    )
}