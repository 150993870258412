import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { Mybtn } from "../MainEngineStyle";

function TextAreaModal({ show, onClose, subdata, data }) {
  const [textareaValue, setTextareaValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    subdata(textareaValue); // Pass the textarea value up to the parent
  };
  useEffect(() => {
    if (show) {
      setTextareaValue(data || "");
    }
  }, [show, data]);
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>SOAP Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTextarea">
            <Form.Control
              as="textarea"
              rows={15}
              value={textareaValue}
              onChange={(e) => setTextareaValue(e.target.value)}
            />
          </Form.Group>
          <Mybtn className="text-center">
            <Button variant="primary" type="submit" className="new-style-btn">
              Submit
            </Button>
          </Mybtn>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default TextAreaModal;
