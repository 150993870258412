import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
// import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
// import { formatDate, renderMedia } from "../../helpers";
// import { jwtDecode } from "jwt-decode";
// import { FaCamera, FaPrint } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
// import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import Feed from "./feed";

const SidePatients = () => {
  const [loading, setLoading] = useState(true);
  const [interactions, setInteractions] = useState([]);
  // const [expanded, setExpanded] = useState(null);
  // const [showReadBtn, setShowReadBtn] = useState({});
  // const [decode, setDecode] = useState({});
  // const [uploadModal, setUploadModal] = useState(false);
  // const [userDetails, setUserDetails] = useState({
  //   patientId: "",
  //   doctorId: "",
  //   interactionId: "",
  // });
  const [selectedPatientName, setSelectedPatientName] = useState("");
  // const [name, setName] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef([]);
  const location = useLocation();
  console.log(location)

  const queryParams = new URLSearchParams(location.search);
  const selectedPatientId = queryParams.get('id');

  const style = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  };

  const fetchInteractions = async (patientId, name) => {
    setLoading(true);
    try {
      const response = await fetch(`
            ${configJson.backend_URL}/interactionDetail/getAllInteractionDetails/patient/${patientId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }
      const result = await response.json();
      setInteractions(result);
      setSelectedPatientName(name);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedPatients = JSON.parse(localStorage.getItem("patients")) || [];
    // console.log(storedPatients)
    // if(storedPatients.length===0){
    //   navigate("/activity")
    // }
    const updatedPatients = storedPatients.filter(
      (patient) => patient.id === id
    );
      // setName(updatedPatients[0].name);
      fetchInteractions(selectedPatientId, updatedPatients[0]?.name);
  }, [id, location]);


  // useEffect(() => {
  //   fetchInteractions(id,name);
  // }, [id,name]);
  // const sortedInteractions = interactions?.Interactions?.sort((a, b) => {
  //   return new Date(b.updated_at) - new Date(a.updated_at);
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //     ref.current.forEach((el, index) => {
  //       if (el) {
  //         const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
  //         const maxVisibleHeight = lineHeight * 3;
  //         const shouldShowReadMore =
  //           el.scrollHeight > el.clientHeight &&
  //           el.scrollHeight > maxVisibleHeight;

  //         setShowReadBtn((prev) => ({
  //           ...prev,
  //           [index]: shouldShowReadMore,
  //         }));
  //       }
  //     });
  //   };

  //   window.addEventListener("resize", handleResize);

  //   handleResize(); // Trigger the calculation initially

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [ref]);

  // const handlePrint = (detailId) => {
  //   const contentToPrint = ref.current[detailId];
  //   const printFrame = document.createElement("iframe");
  //   printFrame.style.display = "none";
  //   document.body.appendChild(printFrame);
  //   const doc = printFrame.contentDocument;
  //   doc.write(`
  //         <!DOCTYPE html>
  //         <html>
  //           <body>
  //             ${contentToPrint.innerHTML}
  //           </body>
  //         </html>
  //       `);
  //   doc.close();
  //   printFrame.contentWindow.print();
  //   document.body.removeChild(printFrame);
  // };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      // const decoded = jwtDecode(token);
      // console.log("DESoocede", decoded);
      // setDecode(decoded);
    } else {
      navigate("/login");
    }
  }, []);

  // const toggleExpand = (index) => {
  //   setExpanded((prevIndex) => (prevIndex === index ? null : index));
  // };

  // useEffect(() => {
  //   setUserDetails({
  //     patientId: interactions?.patient_id,
  //     doctorId: interactions?.doctor_id,
  //     interactionId: interactions?.Interactions?.interaction_id,
  //   });
  // }, [interactions]);

  // console.log(userDetails)

  return (
    <Sidebar setuser={true} setInteractions={setInteractions}>
      {/* <TokenPageContainer>
          <UploadFiles 
          show={uploadModal}
          onClose={() => setUploadModal(false)}
          userDetails={userDetails}
          setUploadModal={setUploadModal}
          fetchInteractions={fetchInteractions}
          />

           <div className='py-2 px-4'>
                <div className='flex items-center justify-between'>
                    <div className='text-lg font-semibold'>{interactions.first_name} {interactions.last_name} Visit History</div>
                    <button
                    className='rounded-full px-4 py-1 text-white text-sm'
                    style={{ background: 'linear-gradient(to right, #1C1B1B, #5B5B5B)' }}
                    onClick={() => setUploadModal(true)}
                    >
                        Upload
                    </button>
                </div>
                {!loading ? <div className="rounded-lg flex flex-col gap-4 pt-4 feed">
                {sortedInteractions?.length > 0 ? (
                sortedInteractions?.map((data) => {
                    return (
                    <div className="m-auto w-full border p-4 py-2 rounded-lg flex flex-col gap-1 cursor-pointer bg-gray-200 transition-all duration-300">
                        <div className="flex flex-col gap-2 sticky top-0 p-2 bg-gray-200 rounded-b-lg z-50">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center justify-between w-full gap-2">
                            <div className="flex items-center gap-1">
                                <div className="font-semibold text-lg">
                                {interactions.first_name} {interactions.last_name}
                                </div>
                                <div>-</div>
                                <div className="text-sm">
                                {formatDate(data?.updated_at)}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        {data?.InteractionDetails.length > 0 ? (
                        data.InteractionDetails.map((detail, index) => {
                            const excludedTypes = [
                            "mri",
                            "blood test",
                            "ct scan",
                            'processed-speech-to-txt',
                            'x-ray',
                            'ultrasound',
                            'ecg',
                            'biopsy',
                            'ct angiography',
                            'nuclear medicine',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            'soap-error',
                            ];

                            if (!detail?.processedFileContent) return null;
                            if (excludedTypes.includes(detail?.interaction_detail_type))
                            return null;

                            const contentType = renderMedia(
                            detail?.processedFileContent
                            )?.type;

                            const isExpanded = expanded === detail.detail_id;

                            return (
                            <div
                                key={index}
                                className={`p-2 my-1 ${
                                contentType == "audio" ? "" : "bg-white rounded-2xl"
                                }`}
                            >
                                {(detail?.interaction_detail_type == 'raw-audio-txt' || detail?.interaction_detail_type == 'soap-notes') && 
                                <div className="flex items-center justify-end">
                                    <div 
                                    className="bg-gray-200 rounded-full p-2 w-fit"
                                    onClick={() => handlePrint(detail.detail_id)}>
                                        <FaPrint />
                                    </div>
                                </div>
                                }
                                <div className={`flex flex-col gap-2`}>
                                <div
                                    className={`${
                                    contentType == "audio" ? "!h-fit" : ""
                                    }`}
                                    style={isExpanded ? null : style}
                                    ref={(el) => (ref.current[detail.detail_id] = el)}
                                >
                                    {renderMedia(detail?.processedFileContent)}
                                </div>
                                </div>
                                {renderMedia(detail?.processedFileContent)?.type !==
                                "audio" && showReadBtn[detail.detail_id] && (
                                <div
                                    className="w-fit float-right cursor-pointer text-blue-500"
                                    onClick={() => toggleExpand(detail.detail_id)}
                                >
                                    {isExpanded ? "Show less" : "Read more"}
                                </div>
                                )}
                            </div>
                            );
                        })
                        ) : <div className="text-center">No details available</div> }
                    </div>
                    );
                })
                ) : (
                <div className="text-center text-lg font-semibold mt-10">
                    No interactions available
                </div>
                )}
                </div> : <div className='w-full flex items-center justify-center mt-10'><Spinner animation='border' /></div> }
           </div>
        </TokenPageContainer> */}
      {loading ? (
       <div className="flex items-center justify-center h-full">
       <Spinner animation="border" />
     </div>
      ) : (
        <div >
          {/* style={{marginTop:"110px"}} */}
          <Feed
            interactions={interactions}
            selectedPatientName={selectedPatientName}
            fetchInteractions={fetchInteractions}
          />
        </div>
      )}
    </Sidebar>
  );
};

export default SidePatients;
