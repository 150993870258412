import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import {
  ConversationHistoryContainer,
  TableHeader,
} from "../Doctor-Page/Doctorstyle";
import { Alert, Button, Spinner, Table } from "react-bootstrap";
import AssessmentModal from "../Doctor-Page/Components/AssessmentModal";
import { Mybtn } from "../Engine-Page/mainEngine/MainEngineStyle";
import searchicon from "../../assets/sidebar/searchicon.png";
import detailicon from "../../assets/table/search-file.png";
import Processingimg from "../../assets/medscribe/Loading 2.gif";
import { FaArrowRight } from "react-icons/fa6";
import { convertToKolkataTimeFormat } from "../../helpers";

function InteractionPatient() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    patient_id: state?.patientId || "",
    patientFirstName: state?.patientFirstName || "",
    patientLastName: state?.patientLastName || "",
  });
  const [interaction, setInteraction] = useState([]);
  const [searchvalue, setSearchvalue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPage = 7;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState("");
  const [headingTest, setHeadingText] = useState("");

  const handleMouseEnter = (event, assessment) => {
    setCurrentAssessment(assessment);
    setShowModal(true);
  };

  const handleMouseLeave = () => {
    setShowModal(false);
  };

  // Filter data based on search term
  const filteredData = interaction.filter((item) => {
    console.log(item);
    const patientDate = new Date(item.interaction_date.split("T")[0]);
    console.log(patientDate);
    const from = fromDate ? new Date(fromDate) : null;
    console.log(from);
    const to = toDate ? new Date(toDate) : null;
    console.log(to);

    const matchesSearch =
      item.Doctor.first_name
        .toLowerCase()
        .includes(searchvalue.toLowerCase()) ||
      item.Doctor.last_name.toLowerCase().includes(searchvalue.toLowerCase());

    const withinDateRange =
      (!from || patientDate >= from) && (!to || patientDate <= to);

    return matchesSearch && withinDateRange;
  });

  // Calculate paginated data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  // Next button click handler
  const handleNextPage = () => {
    if (startIndex + itemsPerPage < filteredData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Previous button click handler (if needed)
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  //get vist History
  async function GetInteraction(patientId) {
    console.log(patientId);
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/list/${patientId}`,
        requestOptions
      );
      console.log(response);
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setInteraction(result);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  console.log(interaction);
  useEffect(() => {
    if (state.patientId) {
      GetInteraction(state.patientId);
    }
  }, []);
  return (
    <Sidebar setuser={true}>
      <ConversationHistoryContainer>
        <div className="header-name-container">
          <div className="heading-text">
            <h4 className="gradient-text">
              Patient Name :
              {" " +
                formData.patientFirstName.charAt(0).toUpperCase() +
                formData.patientFirstName.slice(1).toLowerCase() +
                " " +
                formData.patientLastName.charAt(0).toUpperCase() +
                formData.patientLastName.slice(1).toLowerCase()}
            </h4>
            <span className="heading-text-line"></span>
          </div>
        </div>
        <div className="search-items">
          <div className="date-filters">
            <div className="date-header">
              <div className="date">
                <input
                  type="date"
                  className="date-field"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
              <span className="arrow">
                <FaArrowRight />
              </span>
              <div className="date">
                <input
                  type="date"
                  className="date-field"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          </div>
          <input
            className="input-text"
            type="text"
            name="search"
            placeholder="Doctor Name"
            value={searchvalue}
            onChange={(e) => {
              setSearchvalue(e.target.value);
              setCurrentPage(1);
            }}
          />
          <span className="search-icon-container">
            <img
              src={searchicon}
              alt=""
              title=""
              className="search-input-icon"
            />{" "}
          </span>
        </div>
        <TableHeader>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : interaction.length > 0 ? (
            <Table borderless responsive className="table-styled ">
              <thead>
                <tr>
                  <th>Doctor Name</th>
                  <th>Visit Date</th>
                  <th className="text-center">Visit Details</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((patient, index) => (
                  <tr
                    key={index}
                    onClick={() =>
                      navigate("/soapnotes", {
                        state: {
                          patientFirstName: patient.Patient.first_name,
                          patientLastName: patient.Patient.last_name,
                          interaction_id: patient.interaction_id,
                          visitdate: patient.interaction_date,
                        },
                      })
                    }
                  >
                    <td>
                      {
                        <span>
                          {patient.Doctor.first_name.charAt(0).toUpperCase() +
                            patient.Doctor.first_name
                              .slice(1)
                              .toLowerCase()}{" "}
                          {patient.Doctor.last_name.charAt(0).toUpperCase() +
                            patient.Doctor.last_name.slice(1).toLowerCase()}
                        </span>
                      }
                    </td>
                    <td>
                      {convertToKolkataTimeFormat(patient.interaction_date)}
                    </td>
                    {/* <td
                    onMouseEnter={(e) => {
                      handleMouseEnter(e, patient.assessment);
                      setHeadingText("Assessment");
                    }}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: "pointer" }}
                  >
                    {patient.assessment.split(" ").slice(0, 3).join(" ")}
                    <span className="link-line">...</span>
                  </td>
                  <td

                    onMouseEnter={(e) => {
                      handleMouseEnter(e, patient.plan);
                      setHeadingText("Plan");
                    }}
                    onMouseLeave={handleMouseLeave}
                    style={{ cursor: "pointer" }}
                  >
                    {patient.plan.split(" ").slice(0, 3).join(" ")}
                    <span className="link-line">...</span>
                  </td> */}
                    <td style={{ textAlign: "center" }}>
                      <button
                        className="table-record-btn"
                        onClick={() =>
                          navigate("/soapnotes", {
                            state: {
                              patientFirstName: patient.Patient.first_name,
                              patientLastName: patient.Patient.last_name,
                              interaction_id: patient.interaction_id,
                              visitdate: patient.interaction_date,
                            },
                          })
                        }
                      >
                        <img
                          src={detailicon}
                          title=""
                          alt=""
                          className="table-detail-icon"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="info">No Patient History found</Alert>
          )}

          {showModal && (
            <AssessmentModal
              show={showModal}
              handleClose={handleMouseLeave}
              assessment={currentAssessment}
              headingName={headingTest}
            />
          )}
          <div className="next-btn-container">
            {currentPage === 1 ? (
              <p></p>
            ) : (
              <Mybtn>
                <Button className="new-style-btn" onClick={handlePrevPage}>
                  Previous
                </Button>
              </Mybtn>
            )}
            {startIndex + itemsPerPage >= filteredData.length ? (
              <p></p>
            ) : (
              <Mybtn>
                <Button className="new-style-btn" onClick={handleNextPage}>
                  Next
                </Button>
              </Mybtn>
            )}
          </div>
        </TableHeader>
      </ConversationHistoryContainer>
    </Sidebar>
  );
}

export default InteractionPatient;
