import React, { useState, useEffect, useContext, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import AddDoctorModal from "./AddDoctorModal";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
import "./DoctorsTable.css";
import TopBar from "../Home/Components/Topbar/TopBar";
import userimage from "../../assets/user/user51.png";
import deleteicon from "../../assets/table/delete.png";
import editicon from "../../assets/table/edit.png";

import {
  TableContainer,
  SearchContainer,
  AddNewDoctorContainer,
  PersonCard,
  EditDeleteButton,
} from "./Doctorstyle";
import EditDoctorModal from "./EditDoctorModel";
import { jwtDecode } from "jwt-decode";

const DoctorsTable = () => {
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false);
  const [showEditDoctorModal, setShowEditDoctorModal] = useState(false);
  const [token] = useContext(UserContext);
  const [searchvalue, setSearchvalue] = useState("");
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [doctorData, setDoctorData] = useState({});
  const [decode, setDecode] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [doctorsPerPage] = useState(5); // Doctors per page
  const navigate = useNavigate();

  // Api Call for Get All Doctor
  const fetchData = async (organization_id) => {
    setIsLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${configJson.backend_URL}/doctor/${organization_id}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch doctors");
      }
      const data = await response.json();
      setDoctors(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Set token and fetch doctors
  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      setDecode(decoded);
      fetchData(decoded.organization_id);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // Add Doctor Model show
  const handleAddDoctorClick = () => {
    setShowAddDoctorModal(true);
  };

  // Add Doctor Model hide
  const handleAddDoctorModalClose = () => {
    setShowAddDoctorModal(false);
    fetchData(decode.organization_id);
  };

  const handleEditDoctorClick = (doctor) => {
    setDoctorData(doctor);
    setShowEditDoctorModal(true);
  };

  // Edit model close
  const handleEditDoctorModalClose = () => {
    setShowEditDoctorModal(false);
    fetchData(decode.organization_id);
  };

  // Set edited data
  const setEditedData = (data) => {
    if (data) {
      let selectData = doctors.filter(
        (doctorlist) => doctorlist.id !== data.id
      );
      setDoctors([...selectData, data]);
    }
  };

  // Function to update size
  const updateSize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateSize();

    // Add resize event listener
    window.addEventListener("resize", updateSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  // Delete doctor API call
  async function deleteDoctorData(id) {
    try {
      const requestOption = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        `${configJson.backend_URL}/doctor/delete/${id}`,
        requestOption
      );
      if (!response.ok) {
        throw new Error("Failed to delete doctor.");
      } else {
        let removeData = doctors.filter((doctorlist) => doctorlist.id !== id);
        setDoctors(removeData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (isLoading) {
    return (
      <Container className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
        <p>Loading Members...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center my-5">
        <Alert variant="danger">You are not authorized. Kindly login.</Alert>
      </Container>
    );
  }

  // Pagination Logic
  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = doctors.slice(indexOfFirstDoctor, indexOfLastDoctor);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div ref={containerRef}>
      <EditDoctorModal
        show={showEditDoctorModal}
        handleClose={handleEditDoctorModalClose}
        token={token}
        doctorData={doctorData}
        setEditedData={setEditedData}
      />
      <TopBar showUser={true} />
      <div style={{ marginTop: "100px" }}></div>
      <Container>
        <Row className="text-center"></Row>

        <Row>
          <center>
            {/* <h4>Our Medscribe List</h4> */}
          </center>
          <Col>
            <AddNewDoctorContainer>
              <AddDoctorModal
                show={showAddDoctorModal}
                handleClose={handleAddDoctorModalClose}
                token={token}
              />
            </AddNewDoctorContainer>
            <SearchContainer>
              <div className="search-btn">
                <input
                  type="text"
                  value={searchvalue}
                  onChange={(e) => setSearchvalue(e.target.value)}
                  className="search-field"
                  placeholder="Search for doctors here"
                />
                <div className="btn-log">
                  <Button 
                    type="button"
                    className="add-doctor"
                    onClick={handleAddDoctorClick}
                  >
                    Add Member
                  </Button>
                </div>
              </div>
            </SearchContainer>
            <h1 className="text-center">Doctor List</h1>
            {containerWidth > 767 ? (
              <TableContainer>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="table-styled text-center"
                >
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Edit Doctor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentDoctors
                      .filter((names) => {
                        return searchvalue.toLowerCase() === ""
                          ? names
                          : names.first_name
                              .toLowerCase()
                              .includes(searchvalue.toLowerCase()) ||
                              names.last_name
                                .toLowerCase()
                                .includes(searchvalue.toLowerCase()) ||
                              names.email
                                .toLowerCase()
                                .includes(searchvalue.toLowerCase());
                      })
                      .map((doctor) => (
                        <tr key={doctor.id}>
                          <td>{doctor.first_name}</td>
                          <td>{doctor.last_name}</td>
                          <td>{doctor.email}</td>

                          <td>
                            <EditDeleteButton>
                              <button
                                type="button"
                                className="edit-btn"
                                onClick={() => handleEditDoctorClick(doctor)}
                              >
                                <img
                                  className="edit-btn-image"
                                  src={editicon}
                                  alt="Edit Doctor Details"
                                  title="Edit Doctor Details"
                                />
                              </button>

                              {/* <button
      type="button"
      className="delete-btn"
      onClick={() => deleteDoctorData(doctor.id)}
    >
      <img
        className="delete-btn-image"
        src={deleteicon}
        alt="Delete Doctor Details"
        title="Delete Doctor Details"
      />
    </button> */}
                            </EditDeleteButton>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </TableContainer>
            ) : (
              <PersonCard>
                {currentDoctors
                  .filter((names) => {
                    return searchvalue.toLowerCase() === ""
                      ? names
                      : names.first_name
                          .toLowerCase()
                          .includes(searchvalue.toLowerCase()) ||
                          names.last_name
                            .toLowerCase()
                            .includes(searchvalue.toLowerCase()) ||
                          names.email
                            .toLowerCase()
                            .includes(searchvalue.toLowerCase());
                  })
                  .map((doctor) => (
                    <div className="card-container" key={doctor.id}>
                      <div className="doctor-image">
                        <img src={userimage} alt="doctor" />
                      </div>
                      <div className="doctor-detail">
                        <h5>
                          {doctor.first_name} {doctor.last_name}
                        </h5>
                        <p>{doctor.email}</p>
                        <p>{doctor.role}</p> {/* Display Role */}
                      </div>
                      <EditDeleteButton>
                        <img
                          src={editicon}
                          alt="edit"
                          onClick={() => handleEditDoctorClick(doctor)}
                        />
                        <img
                          src={deleteicon}
                          alt="delete"
                          onClick={() => deleteDoctorData(doctor.id)}
                        />
                      </EditDeleteButton>
                    </div>
                  ))}
              </PersonCard>
            )}
          </Col>
        </Row>

        {/* Pagination Controls */}
        <div className="pagination">
          {[...Array(Math.ceil(doctors.length / doctorsPerPage))].map(
            (_, index) => (
              <Button
                key={index}
                onClick={() => paginate(index + 1)}
                style={{ marginRight: "5px" }}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </Button>
            )
          )}
        </div>
      </Container>
    </div>
  );
};

export default DoctorsTable;
