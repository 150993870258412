import styled from "styled-components";
export const RecordActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  .intraction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    background-color: #b4bfb473;
    border-radius: 15px;
    margin-top: 85px;
    .user-details-container {
      .user-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .audio-container {
      padding: 10px;
    }
    .soapnotes-container {
      display: flex;
      flex-direction: column;
      justify-content: left;
      padding: 0px 10px 10px 10px;
      border: 1px solid black;
      border-radius: 15px;
      background-color: white;
      height: 155px;
      overflow: auto;
    }
    .dobid-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 3px;
      .text-data {
        color: black;
      }
    }
  }
  .chat-Bot {
    position: fixed;
    bottom: 0px;
    width: 75%;
    @media (max-width: 768px) {
      width: 55%;
    }
  }
`;

export const SoapnotesModelcontainer = styled.div`
  .modal-dialog {
    max-width: 90%; /* Set modal width to 90% of the screen */
    width: 90%; /* Ensure that width is applied */
  }
`;

export const SoapEditorModalContainer = styled.div`
  display: flex;
  flex-direction: column; /* Left and right layout for larger screens */
  gap: 20px; /* Space between editor and preview */

  .markdown-input {
    flex: 1; /* Take up remaining space */
    height: 70%;
    max-heigth: 70%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none; /* Prevent resizing */
  }

  .preview-container {
    flex: 1; /* Take up remaining space */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    height: 70%;
    max-heigth: 70%;
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Stack vertically on mobile */

    .markdown-input,
    .preview-container {
      width: 100%; /* Full width on mobile */
    }
  }
`;
export const Aibot = styled.div`
  // position: sticky;
  // bottom: 0px;
  width: 100%;
  padding: 15px 0 0 0;
  background-color: white;
  // z-index: 99;
`;

export const RecordContainer = styled.div`
  .record-group-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    // margin-right: 50px;
    // margin-top: 25px;
    // gap: 5px;
    .start-stop-btn {
      background-color: #bbbbbb;
      // display: flex;
      // flex-direction: row;
      // background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      // width: 254px;
      // height: 72px;
      // border-radius: 156px;
      // padding: 9px;
      .button-content {
        display: flex;
        flex-direction: row;
        .rec-btn-icon {
          // border: 1px solid black;
          // border-radius: 50%;
          background-color: #bbbbbb;
          color: black;
          // width: 49px;
          text-align: center;
          // height: 49px;
          align-items: center;
          display: flex;
          justify-content: center;
          font-size: 23px;
        }
      }
    }
    @media (max-width: 767px) {
      gap: 2px;
      width: 350px;
      height: auto;
      margin-right: 0px;
    }
    .audio {
      width: 254px;
      height: 72px;
    }
  }
`;
