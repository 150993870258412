// src/SuperadminRegister.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { configJson } from "../../Constent/config";

const SuperadminRegister = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${configJson.backend_URL}/register-superadmin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    superadmin_username: username,
                    superadmin_password: password,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(`Success! User ID: ${data.userId}`);
                alert(`Superadmin registered successfully! User ID: ${data.userId}`);
            } else {
                setMessage(`Error: ${data.message}`);
                alert(`Error: ${data.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h4>Superadmin Registration</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Register</button>
                            </form>
                            {message && <div className="mt-3 alert alert-info">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuperadminRegister;
