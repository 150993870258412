import React, { useEffect, useRef, useState } from "react"
import {
  NotesContainer,
  PrintContainer,
  PrintSoapContainer,
  SoapNotesContainer,
  SoapNotesShowContainer,
} from "../Engine-Page/mainEngine/MainEngineStyle"
import { Button } from "react-bootstrap";
import editicon from "../../assets/Notes/edit.png"
import { configJson } from "../../Constent/config";
import { useLocation, useNavigate } from "react-router-dom";
// import MessageModel from "./MessageModel";
import ReactMarkdown from "react-markdown";
import ReactToPrint from "react-to-print";
import TextAreaModal from '../Engine-Page/mainEngine/Components/TextAreaModal'
import InteractionDetailModal from "../Engine-Page/mainEngine/Components/SpeechText";
import { MdTextFields, MdLocalPrintshop } from "react-icons/md";
import { jwtDecode } from "jwt-decode";
import { Spinner } from "react-bootstrap";

// import { FaMicrophone } from "react-icons/fa6";

function Notes({ setIsInteractionDetail, interaction_id, patientName, interaction_date, setIsContent }) {
  const [notes, setNotes] = useState("");
  const [doctorNotes, setDoctorNotes] = useState("");
  const [decode, setDecode] = useState({});
  const [showModal, setShowModal] = useState(false);
  // const [message, setMessage] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef();
  const componentRefSoap = useRef();
  // const [formData, setFormData] = useState({
  //   patientFirstName: state?.patientFirstName || "",
  //   patientLastName: state?.patientLastName || "",
  //   date: state?.date || "",
  //   doctorEmail: state?.doctorEmail || "",
  //   doctorName: state?.doctorName || "",
  //   doctorlastName: state?.doctorlastName || "",
  //   soap_note_id: state?.interaction_id || "",
  // });
  const [showEditNotes, setShowEditNotes] = useState(false);
  const [speech, setSpeech] = useState("");
  const [audio, setAudio] = useState("");
  const [loading, setLoading] = useState(true);

  // State for modal visibility
  const [showModalText, setShowModalText] = useState(false);

  // Assuming 'date' is in ISO format like '2024-10-03T20:07:00.000Z'
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0"); // Get hours in UTC
    const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Get minutes in UTC
    return `${hours}:${minutes}`; // Format as HH:MM
  };

  const handleShow = () => setShowModalText(true);
  const handleClose = () => setShowModalText(false);

  //get soap notes
  async function GetNotes(id) {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      // its vera ai table data show
      //let response;
      // if (mail === "ragul@adshi5.com") {
      //   response = await fetch(
      //     `${configJson.backend_URL}/interactionVera/${soap_note_id}`,
      //     requestOptions
      //   );
      // } else {
      //   response = await fetch(
      //     `${configJson.backend_URL}/interactionDetail/${soap_note_id}`,
      //     requestOptions
      //   );
      // }
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/${id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        //console.log(result)
        if (result) {
          const soapNote = result.find(
            (item) =>
              item.interaction_detail_type === "soap-notes" ||
              item.interaction_detail_type === "processed-speech-to-txt"
          );
          const processedSpeechToText = result.find(
            (item) =>
              item.interaction_detail_type === "raw-audio-txt" ||
              item.interaction_detail_type === "processed-audio"
          );
          const audio_data = result.find(
            (item) => item.interaction_detail_type === "Audio url"
          );
          if (soapNote) {
           // console.log(soapNote.processedFileContent);
              const text = soapNote.processedFileContent.replace(/^## SOAP Notes\s*/i, '');  //soap nodes removed
            //const text = soapNote.processedFileContent;
            setNotes(text);
          } else {
            setNotes("soap notes Empty");
          }
          if (processedSpeechToText) {
            setSpeech(processedSpeechToText.processedFileContent);
          } else {
            setSpeech("speech to text Empty");
          }
          if (audio_data) {
            setAudio(audio_data);
            if (audio_data.detail_notes !== null) {
              setDoctorNotes(audio_data.detail_notes);
            }
          }
        } else {
          alert(result.message);
        }
      } else {
        alert(response);
        console.log(response);
      }
    } catch (error) {
      alert(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      if (interaction_id) {
        GetNotes(interaction_id);
      }
    } else {
      navigate("/login");
    }
  }, [interaction_id, navigate]);

  //form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          detail_notes: doctorNotes,
          detail_id: audio.detail_id,
          interaction_detail_type: audio.interaction_detail_type,
        }),
      };
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/update`,
        requestOptions
      );
      if (response.ok) {
        // const data = await response.json();
        navigate("/token");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //mail send
  // async function mailsend() {
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         soap_report: notes,
  //         patient_fname: formData.patientFirstName,
  //         patient_lname: formData.patientLastName,
  //         doctor_name: formData.doctorName,
  //         doctor_email: formData.doctorEmail,
  //       }),
  //     };
  //     const response = await fetch(
  //       `${configJson.backend_URL}/send_email`,
  //       requestOptions
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       setMessage(data);
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  //send mail
  // const handlMailSend = async () => {
  //   try {
  //     const requestOptions = {
  //       method: "PUT",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         doctorNotes: doctorNotes,
  //       }),
  //     };
  //     const response = await fetch(
  //       `${configJson.backend_URL}/soap_notes_edit/${state.soap_note_id}`,
  //       requestOptions
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       mailsend();
  //     }
  //   } catch (error) {
  //     alert(error);
  //     console.log(error);
  //   }
  // };
  //show or close model
  // const setShowModals = (data) => {
  //   setShowModal(false);
  // };
  useEffect(() => {
    if (showModal === true) {
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  }, [showModal]);

  //Text area
  // const ShowEditedNotes = () => setShowEditNotes(true);
  const hideEditedNotes = () => setShowEditNotes(false);
  const handleTextArea = (value) => {
    setNotes(value);
    hideEditedNotes();
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" }; // Format: 24 Apr, 2023
    return new Date(dateString).toLocaleDateString("en-GB", options); // Use 'en-GB' for this format
  };
  return (
      <SoapNotesContainer>
        <div className="flex items-center justify-between">
          <div className="text-lg font-semibold flex items-center gap-2">
            <div>
              Patient Name : {' '}
              {patientName ? patientName : ""}
            </div>
            <div>
              Date : {' '}
              {new Date(interaction_date)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-")}
            </div>
            <div>
              Time : {' '}
              {new Date(interaction_date).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </div>
          </div>
          <button 
          className="py-1 px-4 rounded-3xl text-white"
          onClick={() => {
            setIsInteractionDetail(false)
            setIsContent(false)
          }}
          style={{
            background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
          }}
          >
            Back
          </button>
        </div>
        {/* <div className="header-name-container"> */}
          {/* <p className="name-tag">
            <span className="name-text">Patient Name :</span>
            <span className="name-name">
              {" "}
              {formData.patientFirstName.charAt(0).toUpperCase() +
                formData.patientFirstName.slice(1).toLowerCase() +
                " " +
                formData.patientLastName.charAt(0).toUpperCase() +
                formData.patientLastName.slice(1).toLowerCase()}
            </span>
          </p> */}
          {/* {decode.first_name == null ? (
            ""
          ) : (
            <p className="name-tag">
              <span className="name-text">Doctor Name :</span>
              <span className="name-name">
                {decode.first_name.charAt(0).toUpperCase() +
                  decode.first_name.slice(1).toLowerCase() +
                  " " +
                  decode.last_name.charAt(0).toUpperCase() +
                  decode.last_name.slice(1).toLowerCase()}
              </span>
            </p>
          )} */}
          {/* <p className="name-tag">
            <span className="name-text">Date :</span>
            <span className="name-name">
              {new Date(date).toLocaleDateString("en-GB").replace(/\//g, "-")}
            </span>
          </p> */}
          {/* <p className="name-tag">
            <span className="name-text">Time :</span>
            <span className="name-name">
              {formatTime(date)} 
            </span>
          </p>
        </div>
        <div className="Line-header">
          <hr className="gradient-line" />
          <hr className="black-line" />
        </div> */}
        {loading ? (
          <div className="flex items-center justify-center h-[40vh]"><Spinner animation="border" /></div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <SoapNotesShowContainer>
                <div className="text-header">
                  <p className="text-header-text">Summary</p>
                  <div className="text-btn-container">
                    <ReactToPrint
                      trigger={() => (
                        <button type="button" className="text-btn-top">
                          <MdLocalPrintshop size={25} />
                        </button>
                      )}
                      content={() => componentRefSoap.current}
                    />
                    <button
                      className="text-btn-top"
                      type="button"
                      onClick={handleShow}
                    >
                      <MdTextFields size={25} />
                    </button>
                    {/* <button
                      className="text-btn-top"
                      type="button"
                      onClick={handleAudioShow}
                    >
                      <FaMicrophone size={25} />
                    </button> */}
                    <button
                      className="text-header-text"
                      type="button"
                      // onClick={ShowEditedNotes}
                    >
                      <img src={editicon} title="" alt="" />
                    </button>
                  </div>
                </div>
                <hr className="text-line" />
                <NotesContainer>
                  <ReactMarkdown>{notes}</ReactMarkdown>
                </NotesContainer>
              </SoapNotesShowContainer>
              <div>
                <div className="form-design">
                  <div className="form-text">
                    <label htmlFor="Notes-text" className="lable-text">
                      Notes
                    </label>
                    <textarea
                      style={{
                        fontFamily: " monospace",
                      }}
                      name="doctorNotes"
                      type="textarea"
                      id="Notes-text"
                      value={doctorNotes}
                      onChange={(e) => setDoctorNotes(e.target.value)}
                    />
                  </div>

                  <div className="record-group-button-header">
                    {/* <ReactToPrint
                      trigger={() => (
                        <Button type="button" className="color-btn">
                          Print Notes
                        </Button>
                      )}
                      content={() => componentRef.current}
                    /> */}

                    <div className="record-group-button">
                      <Button type="submit" className="color-btn save">
                        Confirm & Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <TextAreaModal
              show={showEditNotes}
              data={notes}
              onClose={hideEditedNotes}
              subdata={handleTextArea}
            />
            <PrintContainer ref={componentRef} className="print-content">
              <p>
                <span>Patient Name : </span>
                {patientName}
              </p> 
               <p>
                <span>Date : </span> {new Date(interaction_date).toLocaleDateString("en-GB").replace(/\//g, "-")}
              </p>
              <h3>Notes</h3>
              <p>{doctorNotes}</p>
            </PrintContainer>
            <PrintSoapContainer ref={componentRefSoap} className="">
               <p>
                <span>Patient Name : </span>
                {patientName}
              </p>
              <p>
                <span>Date : </span>{" "}
                <span>
                  {new Date(interaction_date)
                    .toLocaleDateString("en-GB")
                    .replace(/\//g, "-")}
                </span>{" "}
                <span></span>
                <span>
                  {new Date(interaction_date).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </span>
              </p> 
              <h3>SOAP Notes</h3>
              <ReactMarkdown>{notes}</ReactMarkdown>
            </PrintSoapContainer>

            {/* <MessageModel
              showModal={showModal}
              messageData={message}
              setShowModal={setShowModals}
            /> */}
            {/* Pass the found interaction detail to the modal */}
            <InteractionDetailModal
              show={showModalText}
              handleClose={handleClose}
              interactionDetail={speech}
              url={audio}
              patientName={patientName}
              // formData={formData}
              date={interaction_date}
            />
          </>
        )}
      </SoapNotesContainer>
  );
}

export default Notes;
