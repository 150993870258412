import React, { useEffect, useState } from "react";
// import { InteractionPatientContainer } from "../Doctor-Page/Components/DoctorFeed/DoctorFeedStyle";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import { DateTime } from "luxon";
// import { configJson } from "../../Constent/config";
// import { MdLocalPrintshop } from "react-icons/md";
// import { IoIosCopy } from "react-icons/io";
// import { RiFileEditFill } from "react-icons/ri";
import { RecordActivityContainer } from "./ActivityStyle";
import waveicon from "../../assets/medscribe/sound.png";
import ReactMarkdown from "react-markdown";
import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import MicRecorder from "mic-recorder-to-mp3";
import MessageModel from "../Engine-Page/mainEngine/Components/MessageModel";
import { useStopwatch } from "react-timer-hook";
import { Spinner } from "react-bootstrap";
import { configJson } from "../../Constent/config";
import {
  convertToKolkataTime,
  convertToKolkataTimeDOB,
  convertToUTC,
} from "../../helpers";
import axios from "axios";
import { toast } from "react-toastify";
const Mp3Recorder = new MicRecorder({ bitRate: 129 });

function RecordingActivity({ setContent }) {
  const [interaction, setInteraction] = useState({});
  const [decode, setDecode] = useState({});
  // const [staticQns, setStaticQns] = useState("");
  const [checked, setChecked] = useState(false);
  const pageRole = "doctor";
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [blobUrl, setBlobUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [utcdate, setUtcdate] = useState("");
  const [date, setDate] = useState(null);
  const [notes, setNotes] = useState(null);
  console.log(checked);
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const navigate = useNavigate();

  //get vist History
  async function getPatientHistory(patient_id) {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/onedata/${patient_id}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        const notesdata = result.filter((data) => {
          return data.interaction_detail_type === "soap-notes";
        });
        setNotes(notesdata[0].processedFileContent);
      } else {
        console.log(response);
        setNotes("No previous medical history");
      }
    } catch (error) {
      console.log(error);
      setMessage(error);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  }

  //   const fetchDoctorRecording = async (
  //     doctorId,
  //     interactionStatus,
  //     interactionType
  //   ) => {
  //     setLoading(true);

  //     try {
  //       const response = await fetch(
  //         `${configJson.backend_URL}/interaction/Recording/${doctorId}/${interactionStatus}/${interactionType}`
  //       );

  //       if (!response.ok) {
  //         const errorData = await response.json();
  //         setMessage(errorData);
  //         setShowModal(true);
  //       }
  // console.log(response)
  //       const data = await response.json();
  //       console.log("message", data);
  //       if (data.status === true) {
  //         console.log(data);

  //         setInteraction(data.interactions);
  //         startRecording();
  //         getPatientHistory(data.patient_id);
  //       } else {
  //         setLoading(true);
  //         setTimeout(() => {
  //           fetchDoctorRecording(doctorId, interactionStatus, interactionType);
  //         }, 6000);
  //       }
  //     } catch (err) {
  //       setMessage(err.message);
  //       setShowModal(true);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // const fetchDoctorRecording = async (
  //   doctorId,
  //   interactionStatus,
  //   interactionType,
  //   retryCount = 0
  // ) => {
  //   setLoading(true);

  //   try {
  //     const response = await axios.get(
  //       `${configJson.backend_URL}/interaction/Recording/${doctorId}/${interactionStatus}/${interactionType}`
  //     );
  //     console.log("Response Data:", response);
  //     const data = response.data; // Axios automatically parses JSON
  //     console.log("Response Data:", data);

  //     if (data.status === true) {
  //       // If status is true, process the data and update state
  //       setInteraction(data.interactions);
  //       startRecording();
  //       getPatientHistory(data.interactions.patient_id);
  //     } else {
  //       // Retry mechanism if the response status is not true
  //       if (retryCount < 3) {
  //         setTimeout(() => {
  //           fetchDoctorRecording(doctorId, interactionStatus, interactionType, retryCount + 1);
  //         }, 6000);
  //       } else {
  //         // Display an error message after reaching retry limit
  //         setMessage("Retry limit reached. Please try again later.");
  //         setShowModal(true);
  //       }
  //     }
  //   } catch (err) {
  //     // Handle errors from the server response or other issues
  //     setMessage(err.response?.data?.message || err.message || "An error occurred");
  //     setShowModal(true);
  //   } finally {
  //     // Ensure loading is set to false after the process completes
  //     setLoading(false);
  //   }
  // };
  const fetchDoctorRecording = async (
    doctorId,
    interactionStatus,
    interactionType,
    retryCount = 0
  ) => {
    setLoading(true);

    try {
      console.log(
        "Attempting to fetch data with:",
        doctorId,
        interactionStatus,
        interactionType
      );

      const response = await axios.get(
        `${configJson.backend_URL}/interaction/Recording/${doctorId}/${interactionStatus}/${interactionType}`
      );
      console.log("Response Data:", response.data);

      const data = response.data;

      if (data.status === true) {
        setInteraction(data.interactions);
        startRecording();
        if (data.interactions?.patient_id) {
          getPatientHistory(data.interactions.patient_id);
        } else {
          setMessage("Patient ID is not available in the response.");
          setShowModal(true);
        }
      } else {
        if (retryCount < 3) {
          setTimeout(() => {
            fetchDoctorRecording(
              doctorId,
              interactionStatus,
              interactionType,
              retryCount + 1
            );
          }, 6000);
        } else {
          setMessage("Retry limit reached. Please try again later.");
          setShowModal(true);
        }
      }
    } catch (err) {
      if (retryCount < 3) {
        setTimeout(() => {
          fetchDoctorRecording(
            doctorId,
            interactionStatus,
            interactionType,
            retryCount + 1
          );
        }, 6000);
      } else {
        console.log("Retry limit reached. Please try again later.");
        toast.error("Patient not available");
        setContent("patients");
      }
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An unexpected error occurred";
      console.log(errorMessage);
      setLoading(true);
      // setMessage(errorMessage);
      // setShowModal(true);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      console.log("DESoocede", decoded);
      setDecode(decoded);
      if (decoded) {
        fetchDoctorRecording(decoded.doctor_id, "99", "true");
      }
      const formattedDate = convertToUTC();
      const d = convertToKolkataTime(formattedDate);
      setUtcdate(formattedDate);
      setDate(d);
      console.log(d);
    } else {
      navigate("/login");
    }
  }, []);

  const handleChecked = (data) => {
    if (data) {
      setChecked(true);
      startRecording();
    } else {
      setChecked(false);
      stopRecording();
    }
  };
  const handleToggle = (checked) => {
    console.log("checked", checked);
    setChecked(checked);
    if (checked) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  //mic-recorder-to-mp3
  //start Audio recording
  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
        // setRecordtime(0);
        // setRecordtimeminutes(0);
        start();
        setChecked(true);
      })
      .catch((e) => console.error(e));
  };

  //stop Audio recording
  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        // setBlobUrl(blobURL);
        setIsRecording(false);
        // setRecordtime(seconds);
        // setRecordtimeminutes(minutes);
        handleCode(seconds, minutes, blobURL);
        reset();
        pause();
      })
      .catch((e) => console.log(e));
  };

  async function updatedata(url) {
    setIsLoading(true);
    const mydata = {
      interaction_id: interaction.interaction_id,
      interaction_status: 0,
      interaction_notes: "soap",
      interaction_date: utcdate,
      interaction_detail_type: "Audio url",
      audioUrl: url,
      interaction_type: "false",
    };
    try {
      const response = await axios.put(
        `${configJson.backend_URL}/interaction/updatedata`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        console.log("Audio upload success full");
        setContent("patients");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  //Api Call for Audio upload backend
  const handleCode = async (seconds, minutes, blobUrl) => {
    console.log(seconds, minutes, blobUrl);
    //   e.preventDefault();
    setIsLoading(true);

    if (!blobUrl) {
      setMessage("Please record audio or upload a file.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }

    if (minutes <= 0 && seconds <= 25) {
      setMessage("Minimum recording is 25 seconds");
      setShowModal(true);
      setIsLoading(false);
      reset();
      pause();
      return;
    }

    const audioBlob = await fetch(blobUrl).then((res) => res.blob());

    let result = date.replace(/[-:]/g, "_").replace(", ", "_");

    const formData2 = new FormData();
    formData2.append(
      "file_name",
      `audio_files/${interaction.patient_id}_${result}.mp3`
    );
    formData2.append("audio_file", audioBlob, `${interaction.patient_id}.mp3`);
    console.log(formData2, result);
    try {
      // const response = await fetch("https://back.denotes.app/upload_audio", {
      //check connectivity
      const response = await fetch(
        `${configJson.backend_URL}/soapPrompt/upload`,
        {
          method: "POST",
          body: formData2,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Audio stored in bucket");
        const url = result.gcs_uri;
        await updatedata(url);
      } else {
        let errorMessage = "Failed to submit form data.";
        if (response.status === 400) {
          errorMessage = "Bad request. Please check your inputs.";
        } else if (response.status === 404) {
          errorMessage = "API endpoint not found.";
        }
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(`An error occurred while submitting the form.${error}`);
    } finally {
      setIsLoading(true);
    }
  };

  //show or close model
  const setShowModals = () => {
    setShowModal(false);
  };
  // useEffect(() => {
  //   if (isLoading) {
  //     setMessage("audio upload ....");
  //     setShowModal(true);
  //   }
  // }, [isLoading]);

  //close Model 2s
  useEffect(() => {
    if (showModal === true) {
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  }, [showModal]);

  return (
    <RecordActivityContainer>
      {!isLoading ? (
        <>
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {interaction ? (
                <>
                  <div className="chat-Bot">
                    <ChatbotQuery
                      organizationId={decode.organization_id}
                      doctorId={decode.doctor_id}
                      patientId={interaction.patient_id}
                      // staticQns={staticQns}
                      // setStaticQns={setStaticQns}
                      role={pageRole}
                      ISChecked={checked}
                      setChecked={handleChecked}
                    />
                  </div>
                  <div className="intraction-container">
                    <div className="user-details-container">
                      <div className="user-details">
                        <div className="flex items-center gap-2">
                          <div className="rounded-full bg-[#D9D9D9] w-fit p-1 size-[23px]">
                            <img src={`/user.png`} width={15} />
                          </div>
                          <div>
                            <div className="text-base font-bold">
                              {interaction?.Patient?.first_name}{" "}
                              {interaction?.Patient?.last_name}
                            </div>
                            <div className="text-sm flex items-center gap-1">
                              {/* <span className="font-bold">Follow-Up</span> */}
                              <span>
                                {convertToKolkataTimeDOB(
                                  interaction?.updated_at
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <Switch
                          checked={checked}
                          onChange={handleToggle}
                          checkedChildren="In"
                          unCheckedChildren="Out"
                        />
                      </div>
                    </div>
                    <div className="soapnotes-container">
                      <div className="text-header">
                        <ReactMarkdown>{notes}</ReactMarkdown>
                      </div>
                    </div>
                    <div className="dobid-container">
                      <span className="text-data">
                        <b>DOB:</b>
                        {interaction?.Patient?.date_of_birth}
                      </span>{" "}
                      <span className="text-data">
                        <b>ID:</b>
                        {interaction?.Patient?.patient_id}
                      </span>{" "}
                      <span className="text-data">
                        <b> Phone Number:</b>
                        {interaction?.Patient?.phone_number}
                      </span>
                      <span className="text-data">
                        <img src={waveicon} alt="" title="" />
                      </span>
                    </div>
                  </div>

                  {/* <div className="mb-1 audio">
            <audio src={blobUrl} controls className="w-100" />
          </div> */}
                  <MessageModel
                    showModal={showModal}
                    messageData={message}
                    setShowModal={setShowModals}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <h1>processing...</h1>
      )}
    </RecordActivityContainer>
  );
}

export default RecordingActivity;
