import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import { jwtDecode } from "jwt-decode";
import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";
import { FaCamera, FaPrint } from "react-icons/fa";
import "./table.css";
import { convertToKolkataTimeFormat, formatDate, renderMedia } from "../../helpers";
import logo from "../../assets/gunam.png";
import { IoIosCopy } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdLocalPrintshop } from "react-icons/md";
import { RiFileEditFill } from "react-icons/ri";
import MarkdownEditorModal from "../Doctor-Page/Components/DoctorFeed/SoapNotesEditer";
import { Aibot } from "./ActivityStyle";

const isMobile = () => window.innerWidth <= 768;

const Feed = ({ interactions, selectedPatientName, fetchInteractions }) => {
  const navigate = useNavigate();
  const [mediaSrc, setMediaSrc] = useState();
  const [mediaModal, setMediaModal] = useState(false);
  const [images, setImages] = useState([]);
  const [decode, setDecode] = useState({});
  const [userDetails, setUserDetails] = useState({
    patientId: "",
    doctorId: "",
    interactionId: "",
  });
  const [imgExpanded, setImgExpanded] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [showReadBtn, setShowReadBtn] = useState({});
  const ref = useRef([]);
  const [uploadModal, setUploadModal] = useState(false);
  // const [staticQns, setStaticQns] = useState("");
  const comnotes = useRef();
  const [copied, setCopied] = useState(false);
  const [editmodel, setEditmodel] = useState(false);
  const printRef = useRef({});
  const imgRef = React.createRef();

  const style = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  };
  const getDivContent = () => comnotes.current?.innerText || "";

  const handelOpenmodel=()=>{
    console.log("click")
    setEditmodel(true);
  }
  const handleCloseModel=()=>{
    console.log("click")
    setEditmodel(false);
  
  }

  const fetchImages = () => {
    setMediaModal(true);
    const types = [
      "mri",
      "blood test",
      "ct scan",
      "x-ray",
      "ultrasound",
      "ecg",
      "biopsy",
      "ct angiography",
      "nuclear medicine",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    interactions.Interactions?.forEach((interaction) => {
      interaction.InteractionDetails.forEach((detail) => {
        if (types.includes(detail.interaction_detail_type)) {
          setImages((prevImages) => [
            ...prevImages,
            detail.processedFileContent,
          ]);
        }
      });
    });
  };

  const sortedInteractions = interactions?.Interactions?.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const handleClose = () => {
    setMediaModal(false);
    setImages([]);
  };

  // const handleShowImages = (imageUrls) => {
  //   setMediaSrc(imageUrls);
  //   setMediaModal(true);
  // };

  const toggleExpand = (index) => {
    setExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleImgExpand = (index) => {
    setImgExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleResize = () => {
    console.log('inn')
    ref.current.forEach((el, index) => {
      if (el) {
        const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
        const maxVisibleHeight = lineHeight * 3; // Height of 3 lines

        // Compare clientHeight and scrollHeight
        const shouldShowReadMore =
          el.scrollHeight > el.clientHeight &&
          el.scrollHeight > maxVisibleHeight;

        setShowReadBtn((prev) => ({
          ...prev,
          [index]: shouldShowReadMore,
        }));
      }
    });
  };

  useEffect(() => {
    
    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger the calculation initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      console.log("DESoocede", decoded);
      setDecode(decoded);
    } else {
      navigate("/login");
    }
  }, []);

  const handlePrint = (detailId) => {
    const contentToPrint = ref.current[detailId];

    // Create a hidden iframe element
    const printFrame = document.createElement("iframe");
    printFrame.style.display = "none";
    document.body.appendChild(printFrame);

    // Set the iframe content to the content to be printed
    const doc = printFrame.contentWindow.document;
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          
        </head>
        <body>
          <div class="flex items-center justify-between">
            <img src="${logo}" alt='Logo' />
            <div class="text-right">
              <div class="font-bold">Gunam Super Speciality Hospital</div>
              <div class="text-sm">Health Care You Deserve</div>
              <div class="font-bold">Dr. ${decode.first_name} ${decode.last_name}</div>
            </div>
          </div>
          <div class='flex items-center gap-1 font-bold'>
            <div>Patient:</div>
            <div>${interactions?.first_name} ${interactions?.last_name}</div>
          </div>
          ${contentToPrint.innerHTML}

          <script src="https://cdn.tailwindcss.com"></script>
        </body>
      </html>
    `);
    doc.close();

    // Trigger the print dialog using the iframe
    printFrame.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  };

  useEffect(() => {
    setUserDetails({
      patientId: interactions?.patient_id,
      doctorId: interactions?.doctor_id,
      interactionId: interactions?.Interactions?.interaction_id,
    });
  }, [interactions]);

  const openFullScreen = (event) => {
    const imgElement = event.target;

    // Ensure `isMobile` function is defined correctly
    if (typeof isMobile === "function" && !isMobile()) {
      // Check for Fullscreen API support
      if (imgElement.requestFullscreen) {
        imgElement.requestFullscreen();
      } else if (imgElement.mozRequestFullScreen) {
        imgElement.mozRequestFullScreen(); // Firefox
      } else if (imgElement.webkitRequestFullscreen) {
        imgElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (imgElement.msRequestFullscreen) {
        imgElement.msRequestFullscreen(); // IE/Edge
      } else {
        console.error("Fullscreen API is not supported by this browser.");
        return;
      }

      // Update the imgRef if it's a React ref
      if (imgRef && typeof imgRef === "object" && imgRef.current) {
        imgRef.current = imgElement;
      }
    } else {
      // For mobile, open image in a new tab instead of fullscreen
      const mediaSrcUrl = mediaSrc ? mediaSrc : imgElement.src;
      window.open(mediaSrcUrl, "_blank");
    }
  };

  return (
    <>

      {/* <div>
        <ChatbotQuery
          organizationId={decode.organization_id}
          doctorId={decode.doctor_id}
          patientId={interactions?.patient_id}
          staticQns={staticQns}
          setStaticQns={setStaticQns}
        />
      </div> */}
      {/* <MediaModal
        mediaModal={mediaModal}
        handleClose={handleClose}
        images={images}
        userDetails={userDetails}
      /> */}
      <UploadFiles
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        patientId={userDetails?.patientId}
        doctorId={userDetails?.doctorId}
        setUploadModal={setUploadModal}
        fetchInteractions={fetchInteractions}
      />
      <div>
        <button
          className="rounded-3xl text-white py-2 px-3"
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
          onClick={() => setUploadModal(true)}
        >
          Documents
        </button>
        </div>
      <Aibot>
        <ChatbotQuery
          organizationId={decode.organization_id}
          doctorId={decode.doctor_id}
          patientId={interactions?.patient_id}
          // staticQns={staticQns}
          // setStaticQns={setStaticQns}
        />
      </Aibot>
      <div className="rounded-lg flex flex-col gap-4 pt-4 feed mb-10">
        {sortedInteractions?.length > 0 ? (
          sortedInteractions?.map((data) => {
            const imageUrls = data?.InteractionDetails.filter((detail) =>
              ["jpg", "jpeg", "png", "gif", "jfif"].includes(
                detail?.processedFileContent?.split(".").pop().toLowerCase()
              )
            ).map((detail) => ({
              url: detail?.processedFileContent,
              date: detail?.created_at,
            }));

            return (
              <div className="m-auto w-full border p-2 sm:p-4 py-2 rounded-lg flex flex-col gap-1 cursor-pointer bg-gray-200 transition-all duration-300">
                <div className="flex flex-col gap-2 sticky top-0 p-2 bg-gray-200 rounded-b-lg z-40">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between w-full gap-2">
                      <div className="flex items-center gap-1">
                        <div className="font-semibold sm:text-lg">
                          {interactions?.first_name} {interactions?.last_name}
                        </div>
                        <div>-</div>
                        {/* <div>{data?.interaction_type}</div>
                      <div>-</div> */}
                        <div className="text-sm">
                          {convertToKolkataTimeFormat(data?.updated_at)}
                        </div>
                      </div>
                      {/* <div className='text-center'>
                        <button 
                        className='rounded-3xl text-white p-2'
                        onClick={() => {
                          // handleShowImages(imageUrls);
                          setUploadModal(true)
                        }}
                        style={{ background: 'linear-gradient(to right, #1C1B1B, #5B5B5B)' }}
                        >
                          <FaCamera />
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                {data?.InteractionDetails.length > 0 ? (
                  data.InteractionDetails.map((detail, index) => {
                    // console.log(detail)
                    const excludedTypes = [
                      "mri",
                      "blood test",
                      "ct scan",
                      "processed-speech-to-txt",
                      "x-ray",
                      "ultrasound",
                      "ecg",
                      "biopsy",
                      "ct angiography",
                      "nuclear medicine",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "soap-error",
                    ];

                    if (!detail?.processedFileContent) return null;
                    if (excludedTypes.includes(detail?.interaction_detail_type))
                      return null;

                    const contentType = renderMedia(
                      detail?.processedFileContent
                    )?.type;

                    const isExpanded = expanded === detail.detail_id;

                    return (
                      <div
                        key={index}
                        className={`p-2 my-1 ${
                          contentType == "audio" ? "" : "bg-white rounded-2xl"
                        } text-sm sm:text-base`}
                        ref={comnotes}
                      >
                        {/* {(detail?.interaction_detail_type == "raw-audio-txt" ||
                          detail?.interaction_detail_type == "soap-notes") && (
                          <div className="flex items-center justify-end">
                            <div
                              className="bg-gray-200 rounded-full p-2 w-fit"
                              onClick={() => handlePrint(detail.detail_id)}
                            >
                              <FaPrint />
                            </div>
                          </div>
                        )} */}
                        {detail?.interaction_detail_type == "soap-notes" && (
                          <>
                            {editmodel ? (
                              <MarkdownEditorModal
                                data={detail.processedFileContent}
                                id={detail.detail_id}
                                show={editmodel}
                                patient_id={interactions.patient_id}
                                name={`${interactions?.first_name} ${interactions?.last_name}`}
                                handleClose={handleCloseModel}
                                fetchInteractions={fetchInteractions}
                              />
                            ) : (
                              ""
                            )}
                            <div className="flex items-center justify-end">
                            {copied && <span className="copy-feedback">Copied!</span>}
                              <button
                                type="button"
                                className="text-btn-top  p-1 w-fit"
                                onClick={handelOpenmodel}
                              >
                                <RiFileEditFill size={15} />
                              </button>
                              <CopyToClipboard
                                text={getDivContent()}
                                onCopy={() => setCopied(true)}
                              >
                                <button
                                  type="button"
                                  className="text-btn-top  p-1 w-fit"
                                >
                                  <IoIosCopy size={15} />
                                </button>
                              </CopyToClipboard>
                              <div
                                className=" p-1 w-fit"
                                onClick={() => handlePrint(detail.detail_id)}
                              >
                                <MdLocalPrintshop />
                              </div>
                            </div>
                          </>
                        )}
                        <div className={`flex flex-col gap-2`}>
                          <div
                            className={`${
                              contentType == "audio" ? "!h-fit" : ""
                            }`}
                            style={isExpanded ? null : style}
                            ref={(el) => (ref.current[detail.detail_id] = el)}
                            onClick={() =>
                              setMediaSrc(detail?.processedFileContent)
                            }
                          >
                            {renderMedia(
                              detail?.processedFileContent,
                              openFullScreen
                            )}
                          </div>
                        </div>
                        {renderMedia(detail?.processedFileContent)?.type !==
                          "audio" &&
                          showReadBtn[detail.detail_id] && (
                            <div
                              className="w-fit float-right cursor-pointer text-blue-500"
                              onClick={() => toggleExpand(detail.detail_id)}
                            >
                              {isExpanded ? "Show less" : "Read more"}
                            </div>
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">No details available</div>
                )}
                {/* {imageUrls?.length > 0 ? (
                  <>
                    <div className="flex flex-row items-center gap-4 flex-wrap">
                      {imageUrls.map((media, index) => {
                        return (
                          <div
                            key={index}
                            className="text-center flex flex-col gap-2 m-auto"
                          >
                            <div className="mt-2 text-gray-500">
                              {formatDate(media.date)}
                            </div>
                            <img
                              src={media?.url}
                              alt=""
                              className="rounded-lg cursor-pointer m-auto"
                              width={200}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
              </div>
            );
          })
        ) : (
          <div className="text-center text-lg font-semibold mt-10">
            No interactions available
          </div>
        )}
      </div>
    </>
  );
};

export default Feed;

const MediaModal = ({ mediaModal, handleClose, images, userDetails }) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [zoom, setZoom] = useState(1); // for zooming the image
  const imgRef = useRef(null);
  const data = userDetails;

  // Helper to detect if device is mobile
  const isMobile = () => window.innerWidth <= 768;

  // const openFullScreen = (event) => {
  //   const imgElement = event.target;
  //   if (!isMobile()) {
  //     if (imgElement.requestFullscreen) {
  //       imgElement.requestFullscreen();
  //     } else if (imgElement.mozRequestFullScreen) {
  //       // Firefox
  //       imgElement.mozRequestFullScreen();
  //     } else if (imgElement.webkitRequestFullscreen) {
  //       // Chrome, Safari and Opera
  //       imgElement.webkitRequestFullscreen();
  //     } else if (imgElement.msRequestFullscreen) {
  //       // IE/Edge
  //       imgElement.msRequestFullscreen();
  //     }
  //     imgRef.current = imgElement;
  //   } else {
  //     // For mobile, open image in a new tab instead of fullscreen
  //     window.open(mediaSrc.url, "_blank");
  //   }
  // };

  const closeFullScreen = () => {
    if (!isMobile()) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.mozFullScreenElement) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.msFullscreenElement) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  const zoomIn = () => {
    if (imgRef.current) {
      setZoom((prevZoom) => prevZoom + 0.1);
      imgRef.current.style.transform = `scale(${zoom + 0.1})`;
    }
  };

  const zoomOut = () => {
    if (imgRef.current && zoom > 1) {
      setZoom((prevZoom) => prevZoom - 0.1);
      imgRef.current.style.transform = `scale(${zoom - 0.1})`;
    }
  };

  const handleCancel = () => {
    setShowDropdown(false);
  };

  return (
    <Modal show={mediaModal} onHide={handleClose}>
      {showDropdown && (
        <UploadFiles
          show={showDropdown}
          onClose={handleCancel}
          interactionId={userDetails?.interactionId}
          patientId={userDetails?.patientId}
        />
      )}
      <Modal.Header closeButton>
        <Modal.Title>Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-[65vh] overflow-auto text-center">
        <button
          className="rounded text-white p-2 px-4 w-fit m-auto"
          onClick={() => setShowDropdown(true)}
          style={{ background: "linear-gradient(to right, #1C1B1B, #5B5B5B)" }}
        >
          Upload Documents
        </button>
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="text-center flex flex-col gap-2 mt-2">
              {/* <div className="mt-2 text-gray-500">
                {new Date(image.date).toLocaleString()}
              </div> */}
              <img
                src={image}
                alt=""
                className="rounded-lg cursor-pointer m-auto"
                // onClick={openFullScreen}
                ref={imgRef}
              />
            </div>
          ))
        ) : (
          <p className="mt-6">No documents to display.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>

      {/* Fullscreen image controls */}
      {!isMobile() && document.fullscreenElement && (
        <div className="fullscreen-controls">
          <button onClick={zoomIn}>Zoom In</button>
          <button onClick={zoomOut}>Zoom Out</button>
          <button onClick={closeFullScreen}>Close Fullscreen</button>
        </div>
      )}
    </Modal>
  );
};
