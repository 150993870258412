import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { configJson } from "../../Constent/config";
import { AddNewDoctor, EditDoctorForm } from "./Doctorstyle";
import { Form, Button } from "react-bootstrap";

const EditDoctorModal = ({
  show,
  handleClose,
  token,
  setErrorMessage,
  doctorData,
  setEditedData,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: doctorData,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [specialties, setSpecialties] = useState([]); // State to hold specialties
  const [selectedSpecialty, setSelectedSpecialty] = useState(doctorData?.speciality_id || ""); // State to hold selected specialty

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number, // Include phone number in the submission
          license_no: data.license_no, // Include license number in the submission
          sub: data.subscription,
          speciality_id: selectedSpecialty, // Include specialty ID in the submission
        }),
      };
      const response = await fetch(
        `${configJson.backend_URL}/doctor/update_doctor/${data.id}`,
        requestOptions
      );

      if (!response.ok) {
        // Handle error response
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Something went wrong when updating doctor details.");
      } else {
        const updatedDoctor = await response.json();
        setEditedData(updatedDoctor); // Pass updated data back
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Failed to update doctor details. Please try again.");
    } finally {
      setIsSubmitting(false); // Enable submit button
    }
  };

  // Fetch specialties
  useEffect(() => {
    const fetchSpecialties = async () => {
      try {
        const response = await fetch(`${configJson.backend_URL}/specialties`); // Adjust the URL according to your API
        const data = await response.json();
        setSpecialties(data); // Set specialties from response
      } catch (error) {
        console.error("Error fetching specialties:", error);
      }
    };

    fetchSpecialties();
  }, []);

  // Update form fields when doctorData changes
  useEffect(() => {
    if (doctorData) {
      setValue("id", doctorData.doctor_id);
      setValue("first_name", doctorData.first_name);
      setValue("last_name", doctorData.last_name);
      setValue("email", doctorData.email);
      setValue("phone_number", doctorData.phone_number); // Set phone number
      setValue("license_no", doctorData.license_no); // Set license number
      setValue("subscription", doctorData.sub);
      setValue("org_id", doctorData.org_id);
      setValue("org_name", doctorData.org_name);
      setSelectedSpecialty(doctorData.speciality_id); // Set the specialty
    }
  }, [doctorData, setValue]);

  return (
    <Modal show={show} onHide={handleClose} >
      <AddNewDoctor>
        <Modal.Header closeButton>
          <div className="head-text-container">
            <h2 className="head-text">Edit the doctor's details</h2>
          </div>
        </Modal.Header>
        <Modal.Body className="p-3">
          <EditDoctorForm>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="custom-form"
              autoComplete="off"
            >
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter doctor's name"
                  isInvalid={!!errors.first_name}
                  {...register("first_name", {
                    required: "First name is required",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "First name should not contain any special characters.",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter doctor's last name"
                  isInvalid={!!errors.last_name}
                  {...register("last_name", {
                    required: "Last name is required",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Last name should not contain any special characters.",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_name?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter doctor's email"
                  isInvalid={!!errors.email}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Invalid email address",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter doctor's phone number"
                  isInvalid={!!errors.phone_number}
                  {...register("phone_number", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9\s-]+$/,
                      message: "Phone number should contain only numbers, spaces, and dashes.",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formLicenseNo">
                <Form.Label>License No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter doctor's license number"
                  isInvalid={!!errors.license_no}
                  {...register("license_no", {
                    required: "License number is required",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.license_no?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Specialty Dropdown */}
              <Form.Group controlId="formSpecialty">
                <Form.Label>Specialty</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedSpecialty}
                  onChange={(e) => setSelectedSpecialty(e.target.value)} // Update selected specialty
                >
                  <option value="">Select Specialty</option>
                  {specialties.map((specialty) => (
                    <option
                      key={specialty.speciality_id} // Using speciality_id as key
                      value={specialty.speciality_id} // Using speciality_id as value
                    >
                      {specialty.specialty_name} 
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div className="btn-log">
                <Button type="submit" className="reg-sub-btn" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Form>
          </EditDoctorForm>
        </Modal.Body>
      </AddNewDoctor>
    </Modal>
  );
};

export default EditDoctorModal;
