import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Modal } from 'react-bootstrap';
import { configJson } from "../../Constent/config";
import { MdEdit, MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {

  Row,
  Col,

  Form,
} from "react-bootstrap";

const OrganizationsTable = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingOrganization, setEditingOrganization] = useState(null); // For editing
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const navigate = useNavigate();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [organizationsPerPage] = useState(5); // Number of organizations to display per page

   // Fetch organizations data
   const fetchOrganizations = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/superadmin/`); // Replace with your actual API endpoint
      const data = await response.json();

      if (response.ok) {
        setOrganizations(data.data);
      } else {
        setError(data.message || "Error fetching organizations");
      }
    } catch (err) {
      setError("Failed to fetch organizations");
    } finally {
      setLoading(false);
    }
  };

  // Fetch organizations data when the component mounts
  useEffect(() => {
    fetchOrganizations();
  }, []);

  // Handle edit button click
  const handleEdit = (org) => {
    setEditingOrganization(org); // Open edit mode for the clicked organization
    setShowModal(true); // Show modal
  };

  // Handle delete button click
  const handleDelete = async (orgId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this organization?");
    if (confirmDelete) {
      try {
        const response = await fetch(`${configJson.backend_URL}/superadmin/${orgId}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          setOrganizations(organizations.filter(org => org.organization_id !== orgId));
          alert("Organization deleted successfully");
        } else {
          alert("Failed to delete organization");
        }
      } catch (error) {
        console.error("Error deleting organization:", error);
      }
    }
  };

  // Handle save after editing
  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/organization/superadmin/${editingOrganization.organization_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingOrganization),
      });

      if (response.ok) {
        alert("Organization updated successfully");
        setShowModal(false); // Hide modal
        setEditingOrganization(null); // Exit edit mode
        fetchOrganizations(); // Call the function to fetch the latest organizations
      } else {
        alert("Failed to update organization");
      }
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };

  // Handle input change for edit
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingOrganization({ ...editingOrganization, [name]: value });
  };

  // Calculate pagination values
  const indexOfLastOrg = currentPage * organizationsPerPage;
  const indexOfFirstOrg = indexOfLastOrg - organizationsPerPage;
  const currentOrganizations = organizations.slice(indexOfFirstOrg, indexOfLastOrg);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mt-4">
      <h2 className="mb-3">Organizations</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Organization Name</th>
            <th>Type</th>
            <th>City</th>
            <th>State</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Edit</th>
            {/* <th>Delete</th> */}
          </tr>
        </thead>
        <tbody>
          {currentOrganizations.map((org, index) => (
            <tr key={org.organization_id}>
              <td>{indexOfFirstOrg + index + 1}</td>
              <td>{org.name}</td>
              <td>{org.type}</td>
              <td>{org.city}</td>
              <td>{org.state}</td>
              <td>{org.phone_number}</td>
              <td>{org.email}</td>
              <td>
                <MdEdit
                  onClick={() => handleEdit(org)}
                  style={{ cursor: 'pointer', color: 'blue' }}
                />
              </td>
              {/* <td>
                <MdDelete
                  onClick={() => handleDelete(org.organization_id)}
                  style={{ cursor: 'pointer', color: 'red' }}
                />
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Back to dashboard button */}
      <button type="button" className="btn btn-secondary mt-3 mb-3" onClick={() => navigate("/superadmindashboard")}>
        Back to dashboard
      </button>

      {/* Pagination */}
      <Pagination>
        {Array.from({ length: Math.ceil(organizations.length / organizationsPerPage) }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Edit Organization</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {editingOrganization && (
      <Form>
        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>Organization Name</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="name"
              value={editingOrganization.name}
              onChange={handleInputChange}
              placeholder="Enter organization name"
              required
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>Type</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Check
              type="radio"
              id="hospital"
              name="type"
              value="Hospital"
              checked={editingOrganization.type === "Hospital"}
              onChange={handleInputChange}
              label="Hospital"
              inline
              required
            />
            <Form.Check
              type="radio"
              id="gp"
              name="type"
              value="General Practitioner"
              checked={editingOrganization.type === "General Practitioner"}
              onChange={handleInputChange}
              label="General Practitioner"
              inline
              required
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>City</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="city"
              value={editingOrganization.city}
              onChange={handleInputChange}
              placeholder="Enter city"
              required
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>State</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="state"
              value={editingOrganization.state}
              onChange={handleInputChange}
              placeholder="Enter state"
              required
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>Contact</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="phone_number"
              value={editingOrganization.phone_number}
              onChange={handleInputChange}
              placeholder="Enter contact number"
              required
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm={4}>
            <Form.Label>Email</Form.Label>
          </Col>
          <Col sm={8}>
            <Form.Control
              type="email"
              name="email"
              value={editingOrganization.email}
              onChange={handleInputChange}
              placeholder="Enter email"
              required
            />
          </Col>
        </Row>
      </Form>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Close
    </Button>
    <Button variant="primary" onClick={handleSaveEdit}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default OrganizationsTable;
