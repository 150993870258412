import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import { configJson } from "../../Constent/config";
import { Alert, Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import CreateNewPatient from "./CreateNewPatient";
import "../../App.css";

function CreateNewToken() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 9;
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  // Capitalize helper function
  const capitalize = (word) => {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  // Fetch data from backend using organization_id
  const fetchData = async (organization_id, doctorId) => {
    setLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        `${configJson.backend_URL}/doctor/${organization_id}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch doctors");
      }
      const data = await response.json();

      if (doctorId !== null) {
        const userdata = data.filter((doctor) => {
          return doctor.doctor_id === doctorId;
        });
        if (userdata?.length > 0) {
          setSelectedUser(userdata[0]);
          setShow(true);
          setUsers(userdata);
        } else {
          setUsers(data);
        }
      } else {
        setUsers(data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Effect to handle token and organization_id fetching
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      // fetchData(decoded.organization_id);
      if (decoded.role === "doctor") {
        fetchData(decoded.organization_id, decoded.doctor_id);
      } else {
        fetchData(decoded.organization_id, null);
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // Pagination logic
  const totalUsers = users.length;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const indexOfFirstUser = (currentPage - 1) * usersPerPage;
  const currentUsers = users.slice(
    indexOfFirstUser,
    indexOfFirstUser + usersPerPage
  );

  // Handle user click to open the modal
  const handleUserClick = (user) => {
    if (user) {
      setSelectedUser(user);
      setShow(true);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Display error message if there was an error during fetch
  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  return (
    <Sidebar setuser={true}>
      <TokenPageContainer>
        {show && (
          <CreateNewPatient
            show={show}
            handleClose={handleClose}
            tokendata={selectedUser}
            tokensStatus={() => {}}
          />
        )}
        <div className="heading-text">
          <h4 className="gradient-text">Create Appointments</h4>
          <span className="heading-text-line"></span>
        </div>

        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {users.length === 0 ? (
              <Alert variant="info" className="show-hide">
                No Doctor found.
              </Alert>
            ) : (
              <div className="token-buttons-pagination-container">
                <div className="token-buttons-container">
                  {currentUsers.map((user, index) => (
                    <button
                      key={index} // Use a unique identifier like user.id
                      onClick={() => handleUserClick(user)}
                      className="token-button"
                    >
                      <span>Dr.</span> {capitalize(user.first_name)}{" "}
                      <span> </span>
                      {capitalize(user.last_name)}
                    </button>
                  ))}
                </div>
                <div className="pagination">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                  >
                    Previous
                  </button>

                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => paginate(i + 1)}
                      className={`pagination-button ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}

                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </TokenPageContainer>
    </Sidebar>
  );
}

export default CreateNewToken;
