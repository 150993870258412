import React, { useRef, useEffect, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { CiPlay1, CiPause1 } from "react-icons/ci";
import { AudioWaveformBot } from "../MainEngineStyle";

export default function CustomAudioPlayer({ audioSrc }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (waveformRef.current && !wavesurfer.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: ["#88bbfd", "#f388d0", "#3a8afd", "#eedaf3"],
        progressColor: ["#5B5B5B", "#5B5B5B"],
        cursorColor: "transparent",
        height: 50,
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 1,
      });

      wavesurfer.current.on("ready", () => {
        setDuration(wavesurfer.current.getDuration());
      });

      wavesurfer.current.on("audioprocess", () => {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      });

      wavesurfer.current.on("seek", () => {
        setCurrentTime(wavesurfer.current.getCurrentTime());
      });
      wavesurfer.current.on("finish", () => {
        setPlaying(false);
      });
      wavesurfer.current.on("error", (error) => {
        console.error("Wavesurfer error:", error);
      });
    }

    if (audioSrc) {
      // console.log("Loading audio source:", audioSrc);
      wavesurfer.current.load(audioSrc);
    }

    return () => wavesurfer.current && wavesurfer.current.destroy();
  }, []);

  const togglePlay = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
  };

  const handleSeek = (event) => {
    const newTime = parseFloat(event.target.value);
    wavesurfer.current.seekTo(newTime / duration);
  };

  return (
    <AudioWaveformBot>
      <div className="bot-waveform-container">
        <div className="bot-audio-wave">
          {/* <button onClick={togglePlay} className="bot-audio-wave-button">
            {playing ? <CiPause1 /> : <CiPlay1 />}
          </button> */}
          <div ref={waveformRef} className="bot-audio-wave-container">
            {/* <input
        type="range"
        min="0"
        max={duration}
        value={currentTime}
        onChange={handleSeek}
        step="0.01"
        style={{ width: "100%", marginTop: "10px" }}
      /> */}
          </div>
        </div>
      </div>
    </AudioWaveformBot>
  );
}
