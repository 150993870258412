import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, Button, Modal } from "react-bootstrap";
import { UserContext } from "../context/UserContext";
import { configJson } from "../../Constent/config";
import eyeicon from "../../assets/signup/eye.png";
import { OrganizationContainer, OrganizationForm } from "./LoginStyle";
import eyeClose from "../../assets/signup/eyeclose.png";
import Spinner from "react-bootstrap/Spinner";
import { SocialMedia } from "../ThemeStyle/RegisterStyle";
import { SignInButton } from "./Components/SignupBtton";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { callMsGraph } from "../../graph";
import { jwtDecode } from "jwt-decode";
import Medscribefbicon from "../../assets/Login/Medscribe_FB.png";
import Medscribeicon from "../../assets/Login/Medscribe_Logo_Final.png";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const LoginUser = () => {
  // const [formData, setFormData] = useState({ username: "", password: "" });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [, setToken] = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();
  const [showField, setShowField] = useState(true);
  const [reset, setReset] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  //google popup screen size
  const signWidth =
    window.innerWidth < 767
      ? "290px"
      : window.innerWidth >= 768 && window.innerWidth <= 1023
      ? "400px"
      : "400px";

  const email = watch("email"); // Track email field changes
  const isValidEmail = (email) => {
    // Simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  //back button
  const handleBack = () => {
    setShowField(true);
    setReset(false);
  };
  //microsoft signin start
  const handleMsUserSubmit = async (account) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: account.username,
      }),
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/microsoft`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        setToken(null);
        setErrorMessage(data.message);
        setShowErrorModal(true);
      } else {
        if (data.status) {
          sessionStorage.setItem("orgtoken", data.token);
          setToken(data.token);

          // Check the user's role and navigate accordingly
          if (data.role === "admin") {
            navigate("/adminpage");
          } else if (data.role === "doctor") {
            navigate("/token");
          } else if (data.role === "front office staff") {
            navigate("/createtoken");
          } else if (data.role === "technician") {
            navigate("/tech-activity")
          }
        } else {
          setErrorMessage(data.message);
          setShowErrorModal(true);
          setToken(null);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setShowErrorModal(true);
    }
  };

  const signInWithMicrosoft = async (account) => {
    try {
      // Attempt to acquire a token silently
      const silentResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      if (silentResponse) {
        // callMsGraph(silentResponse.accessToken).then((response) =>
        //  console.log(response)
        // );
        handleMsUserSubmit(account);
      } else {
        console.error("Silent token acquisition failed.");
      }
    } catch (error) {
      console.error("Silent token acquisition error:", error);
    }
  };

  const handleMicrosoftLogin = async (loginType) => {
    if (loginType === "popup") {
      try {
        const response = await instance.loginPopup(loginRequest);
        if (response) {
          signInWithMicrosoft(response.account);
        } else {
          console.error("Login failed.");
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  //microsoft signin end

  //Sign with google start
  const handleUserSubmit = async (emailid) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: emailid,
      }),
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/google`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        setToken(null);
        setErrorMessage(data.message);
        setShowErrorModal(true);
      } else {
        if (data.status) {
          sessionStorage.setItem("orgtoken", data.token);
          setToken(data.token);

          // Check the user's role and navigate accordingly
          if (data.role === "admin") {
            navigate("/adminpage");
          } else if (data.role === "doctor") {
            navigate("/token");
          } else if (data.role === "front office staff") {
            navigate("/createtoken");
          } else if (data.role === "technician") {
            navigate("/tech-activity")
          }
        } else {
          setErrorMessage(data.message);
          setShowErrorModal(true);
          setToken(null);
        }
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setShowErrorModal(true);
    }
  };

  function handleCallbackResponse(response) {
    let userObject = jwtDecode(response.credential);
    let emailid = userObject.email;
    handleUserSubmit(emailid);
  }
  function signInWithGoogle() {
    /*global google*/
    google.accounts.id.initialize({
      client_id:
        "691495889125-m6g1jm2greku6k93pu41gf5fiki54tom.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    // google.accounts.id.renderButton(document.getElementById("signInDiv"), {
    //   theme: "outline",
    //   size: "large",
    //   width: signWidth,
    //   logo_alignment: "center",
    //   shape: "rectangular",
    // });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
      width: signWidth,
      type: "icon", // Set to "icon" for the G icon
      logo_alignment: "center",
      shape: "rectangular", // Optionally set the shape to circle for better icon appearance
    });
    // Display the One Tap prompt
    google.accounts.id.prompt();
  }
  useEffect(() => {
    signInWithGoogle();
  }, []);
  //sign with google end

  // Update checkbox state based on sessionStorage
  useEffect(() => {
    const policyAccepted = sessionStorage.getItem("policyAccepted");
    if (policyAccepted === "true") {
      setIsPolicyAccepted(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Submit Login API call
  const submitLogin = async (data) => {
    if (!data.password_hash || !data.email) {
      setErrorMessage("Email and password required");
      setShowErrorModal(true);
      setToken(null);
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    setLoading(true);
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/login`,
        requestOptions
      );
      const data = await response.json();

      if (!response.ok) {
        setErrorMessage(data.message);
        setShowErrorModal(true);
        setToken(null);
      } else {
        if (data.status) {
          sessionStorage.setItem("orgtoken", data.token);
          setToken(data.token);

          // Check the user's role and navigate accordingly
          if (data.role === "admin") {
            navigate("/adminpage");
          } else if (data.role === "doctor") {
            navigate("/token");
          } else if (data.role === "front office staff") {
            navigate("/createtoken");
          } else if (data.role === "technician") {
            navigate("/tech-activity")
          }
        } else {
          setErrorMessage(data.message);
          setShowErrorModal(true);
          setToken(null);
        }
      }
    } catch (error) {
      setErrorMessage(error);
      setShowErrorModal(true);
      setToken(null);
    } finally {
      setLoading(false);
    }
  };

  // Form submit
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   submitLogin();
  // };
  const onSubmit = (data) => {
    submitLogin(data);
  };

  // Error modal hide
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  // Privacy and Terms Modal toggle
  const handleShowPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const handleClosePolicyModal = () => {
    setShowPolicyModal(false);
  };

  // Handle checkbox change
  const handlePolicyChange = (e) => {
    const isChecked = e.target.checked;
    setIsPolicyAccepted(isChecked);

    // Save acceptance status in sessionStorage
    sessionStorage.setItem("policyAccepted", isChecked);
  };
  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
  }, []);
  return (
    <OrganizationContainer>
      <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100">
        <div
          className="login-box p-4 border rounded-5 shadow-sm w-100 "
          style={{
            maxWidth: "450px",
            // backgroundColor: "white",
            color: "black",
          }}
        >
          {reset ? (
            <ResetPassword email={email} handleBack={handleBack} />
          ) : (
            <>
              <div className="top-image-container">
                <div className="top-logo">
                  <img src={Medscribefbicon} alt="" title="" />
                  <img src={Medscribeicon} alt="" title="" />
                </div>
                <div className="top-text">
                  <p className="text-line">Login</p>
                </div>
              </div>

              <div className="header-text">
                <h3 className="header-text-part">Welcome back</h3>
              </div>
              <OrganizationForm>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ color: "black" }}
                  autoComplete="off"
                >
                  {showField ? (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="email"
                          {...register("email", { required: true })}
                          type="email"
                          autoComplete="off"
                        />
                        {errors.email && (
                          <p className="text-danger">Email is required</p>
                        )}
                      </Form.Group>
                      <Button
                        type="button"
                        className="org-log-sub"
                        onClick={() => setShowField(false)}
                        disabled={!isValidEmail(email)}
                      >
                        Sign in with email
                      </Button>
                      <p className="or-division">Or</p>
                    </>
                  ) : (
                    <>
                      <div className="user-email">
                        <p className="email-text">Enter your password for</p>
                        <p className="email-text">{email}</p>
                      </div>
                      <Form.Group className="mb-3 sh-pass">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          name="password_hash"
                          {...register("password_hash", { required: true })}
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                        />
                        {errors.password_hash && (
                          <p className="text-danger">Password is required</p>
                        )}
                        <span className="show-pass">
                          {showPassword ? (
                            <img
                              onClick={togglePasswordVisibility}
                              src={eyeClose}
                              title=""
                              alt=""
                              className="sign-input-image-close"
                            />
                          ) : (
                            <img
                              onClick={togglePasswordVisibility}
                              src={eyeicon}
                              title=""
                              alt=""
                              className="sign-input-image"
                            />
                          )}
                        </span>
                      </Form.Group>
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="org-log-sub"
                          disabled={!isPolicyAccepted || loading}
                        >
                          {loading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Continue"
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                  {showField ? (
                    <SocialMedia>
                      <div className="google-button">
                        <div className="g-signin pb-2">
                          <div id="signInDiv" className="google-icon"></div>
                        </div>
                      </div>
                      {/* <div className="g-signin pb-2">
                        <div id="signInDiv" className="google-icon">
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                            alt="Google Icon"
                            className="google-logo"
                          />
                        </div>
                      </div> */}
                      <div className="text-center ms-signup">
                        <SignInButton handleLogin={handleMicrosoftLogin} />
                      </div>
                    </SocialMedia>
                  ) : (
                    ""
                  )}
                  {!showField ? (
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label={
                          <>
                            I accept the{" "}
                            <Button
                              type="button"
                              variant="link"
                              className="p-0"
                              onClick={handleShowPolicyModal}
                            >
                              <span style={{ color: "blue" }}>
                                Privacy Policy & Terms of Service
                              </span>
                            </Button>
                          </>
                        }
                        checked={isPolicyAccepted}
                        onChange={handlePolicyChange}
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )}

                  {!showField ? (
                    <div className="back-button-container">
                      <Button
                        type="button"
                        className="back-button"
                        onClick={() => setShowField(true)}
                      >
                        Back
                      </Button>
                      <p className="text-end mb-0 loginlinks">
                        <span>Can't sign in?</span>
                        <button
                          type="button"
                          className="reset-button"
                          onClick={() => setReset(true)}
                        >
                          {" "}
                          Reset password
                        </button>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </Form>
              </OrganizationForm>
            </>
          )}
        </div>
      </Container>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            className="fa fa-exclamation-triangle text-danger mb-3"
            aria-hidden="true"
            style={{ fontSize: "2rem" }}
          ></i>
          <p>{errorMessage}</p>
        </Modal.Body>
      </Modal>

      {/* Combined Privacy Policy & Terms Modal */}
      <Modal show={showPolicyModal} onHide={handleClosePolicyModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy & Terms of Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Privacy Policy</h3>
          <p>
            This medical application facilitates the transcription of
            interactions between doctors and patients. Patient data, audio
            recordings, and transcriptions are processed with the utmost
            confidentiality in compliance with applicable privacy regulations.
          </p>
          <p>
            We ensure that all audio-to-text conversions and patient information
            are securely stored and managed in a safe environment. The data is
            used solely for medical purposes and will not be shared without
            explicit consent.
          </p>

          <h3>Terms of Service</h3>
          <p>
            By using this platform, you agree to ensure that all medical data,
            including patient conversations, is recorded and managed
            responsibly. Any unauthorized sharing or distribution of patient
            information is strictly prohibited and may result in legal
            consequences.
          </p>
          <p>
            The service provided here is intended to aid doctors in managing
            their interactions and patient data effectively. Any misuse of the
            platform, especially in violation of medical ethics and privacy
            regulations, will result in termination of access to the service.
          </p>
        </Modal.Body>
      </Modal>
    </OrganizationContainer>
  );
};

export default LoginUser;

export function ResetPassword({ email, handleBack }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [passwordField, setPasswordField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordconfirm, setShowPasswordconfirm] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCode(e.target.value);
    if (e.target.value) {
      setError(""); // Clear error on change if there's input
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordconfirm(!showPasswordconfirm);
  };

  const onSubmit = async (data) => {
    if (data.password1 !== data.password2) {
      toast.error("password and Confirm password not matched");
      return;
    }

    const obj = { email: email, password_hash: data.password1 };
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/resetpassword`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message);
      } else {
        handleBack();
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const handleSubmitCode = async (e) => {
    e.preventDefault();

    // Validate input manually
    if (!code) {
      setError("Code is required");
      return;
    }
    const obj = { mailid: email, enteredOtp: code };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/verify`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message);
      }
      if (data.verifycode) {
        setPasswordField(true);
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  async function sentotpmail(email) {
    const obj = { mailid: email };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };
    try {
      const response = await fetch(
        `${configJson.backend_URL}/organization/code`,
        requestOptions
      );
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    if (email) {
      sentotpmail(email);
    }
  }, [email]);

  return (
    <>
      <div className="top-image-container">
        <div className="top-logo">
          <img src={Medscribefbicon} alt="" title="" />
          <img src={Medscribeicon} alt="" title="" />
        </div>
        <div className="top-text">
          <p className="text-line">Login</p>
        </div>
      </div>
      <div className="header-text">
        <h4 className="header-text-part">We've sent you a one-time code</h4>
        <p className="reset-email">
          Enter the code we sent to{" "}
          <span className="reset-email-text"> {email}</span> to finish signing
          in.
        </p>
      </div>
      <OrganizationForm>
        {!passwordField && (
          <Form
            onSubmit={handleSubmitCode}
            style={{ color: "black" }}
            autoComplete="off"
          >
            <Form.Group className="mb-3">
              <Form.Label>Code</Form.Label>
              <Form.Control
                name="code"
                value={code}
                onChange={handleChange}
                type="number"
                autoComplete="off"
              />
              {error && <p className="text-danger">{error}</p>}
            </Form.Group>
            <div className="text-center">
              <Button type="submit" className="org-log-sub">
                Continue
              </Button>
            </div>
          </Form>
        )}{" "}
        {passwordField && (
          <Form
            onSubmit={handleSubmit(onSubmit)}
            style={{ color: "black" }}
            autoComplete="off"
          >
            <Form.Group className="mb-3 sh-pass">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password1"
                {...register("password1", { required: true })}
                type={showPassword ? "text" : "password"}
                autoComplete="off"
              />
              {errors.password1 && (
                <p className="text-danger">Password is required</p>
              )}
              <span className="show-pass">
                {showPassword ? (
                  <img
                    onClick={togglePasswordVisibility}
                    src={eyeClose}
                    title=""
                    alt=""
                    className="sign-input-image-close"
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibility}
                    src={eyeicon}
                    title=""
                    alt=""
                    className="sign-input-image"
                  />
                )}
              </span>
            </Form.Group>
            <Form.Group className="mb-3 sh-pass">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                name="password2"
                {...register("password2", { required: true })}
                type={showPasswordconfirm ? "text" : "password"}
                autoComplete="off"
              />
              {errors.password1 && (
                <p className="text-danger">Confirm Password is required</p>
              )}
              <span className="show-pass">
                {showPasswordconfirm ? (
                  <img
                    onClick={togglePasswordVisibilityConfirm}
                    src={eyeClose}
                    title=""
                    alt=""
                    className="sign-input-image-close"
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibilityConfirm}
                    src={eyeicon}
                    title=""
                    alt=""
                    className="sign-input-image"
                  />
                )}
              </span>
            </Form.Group>

            <div className="text-center">
              <Button type="submit" className="org-log-sub">
                {" "}
                Continue
                {/* {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Continue"
                    )} */}
              </Button>
            </div>
          </Form>
        )}
        {!passwordField ? (
          <>
            <div className="mt-10"></div>
            <div className="back-button-container">
              <Button
                type="button"
                className="back-button"
                onClick={handleBack}
              >
                Back
              </Button>
              <p className="text-end mb-0 loginlinks">
                <span>Didn't receive a code?</span>
                <button
                  type="button"
                  className="reset-button"
                  onClick={() => sentotpmail(email)}
                >
                  Resend code
                </button>
              </p>
            </div>
          </>
        ) : (
          ""
        )}
      </OrganizationForm>
    </>
  );
}
