import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { configJson } from "../../Constent/config.js";
import { useNavigate } from "react-router-dom";

const SoapPromptsList = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEdit, setEdit] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [editedPrompt, setEditedPrompt] = useState("");
  const navigate = useNavigate();

  // Fetching SOAP prompts
  const fetchPrompts = async () => {
    try {
      const response = await axios.get(`${configJson.backend_URL}/soapPrompt/getsoap-prompts`);
      const filteredFiles = response.data.files.filter(
        (file) => file.name.includes(".")
      );
      setPrompts(filteredFiles);
    } catch (error) {
      console.error("Error fetching SOAP prompts:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch specialities from the API
  const fetchSpecialities = async () => {
    try {
      const response = await axios.get(`${configJson.backend_URL}/specialties/`);
      setSpecialities(response.data);
    } catch (error) {
      console.error("Error fetching specialities:", error);
    }
  };

  useEffect(() => {
    fetchPrompts();
    fetchSpecialities();
  }, [isEdit]);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }
    if (!selectedSpeciality) {
      alert("Please select a speciality.");
      return;
    }

    const formData = new FormData();
    const fileName = `${selectedSpeciality}.txt`;
    formData.append("file_name", `Prompts/${fileName}`);
    formData.append("audio_file", selectedFile);

    try {
      const response = await axios.post(
        "https://back.denotes.app/upload_audio",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response) {
        alert("File uploaded successfully");
        setSelectedFile(null);
        document.getElementById("fileInput").value = "";
        fetchPrompts();
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  // Handle prompt selection from dropdown
  const handlePromptSelect = (e) => {
    const selectedFileName = e.target.value;
    const prompt = prompts.find((p) => p.name === `Prompts/${selectedFileName}.txt`); // Match the file name
    if (prompt) {
      setSelectedPrompt(prompt);
      setEditedPrompt(prompt.content); // Set the content for editing
    } else {
      setSelectedPrompt(null); // Reset if default option is selected
    }
  };

  // Handle editing prompt
  const handleEditPrompt = async () => {
    if (!selectedPrompt) return;

    try {
      const response = await axios.put(
        `${configJson.backend_URL}/soapPrompt/edit-prompt`,
        {
          fileName: selectedPrompt.name,
          content: editedPrompt,
        }
      );
      console.log(response.data);
      setEdit("."); // Trigger any necessary updates
      alert("File saved successfully");
    } catch (error) {
      console.error("Error editing the prompt:", error);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <h1 style={{ fontSize: '24px' }} className="mb-4 display-4 font-weight-bolder mb-4">SOAP Note Prompts</h1>

      <div className="row">
        <div className="col-6">
          <select
            className="form-select"
            onChange={handlePromptSelect}
            value={selectedPrompt ? selectedPrompt.name.replace("Prompts/", "").replace(".txt", "") : ""}
          >
            <option value="">Select a SOAP Prompt</option>
            {prompts.map((prompt) => (
              <option key={prompt.name} value={prompt.name.replace("Prompts/", "").replace(".txt", "")}>
                {prompt.name.replace("Prompts/", "").replace(".txt", "")}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedPrompt && selectedPrompt.name !== "" && (
        <div className="mt-4">
          {/* Full view text area for editing */}
          <div className="mb-3">
            <label htmlFor="editTextarea" className="form-label">Edit Prompt Content</label>
            <textarea
              id="editTextarea"
              className="form-control"
              rows="10" // Set the number of rows for better visibility
              value={editedPrompt}
              onChange={(e) => setEditedPrompt(e.target.value)}
            />
            <Button className="btn btn-primary mt-3" onClick={handleEditPrompt}>
              Save Changes
            </Button>
          </div>
        </div>
      )}

      <div className="row mt-4">
      <h1 style={{ fontSize: '24px' }} className="display-4 font-weight-bolder mb-4 fs-6">Select Speciality and Upload Your Prompts</h1>

        <div className="col-6">
  
          <select
            className="form-select"
            onChange={(e) => setSelectedSpeciality(e.target.value)}
            value={selectedSpeciality}
          >
            <option value="">Select Speciality</option>
            {specialities.map((speciality) => (
              <option key={speciality.speciality_id} value={speciality.specialty_name}>
                {speciality.specialty_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6">
          <input
            id="fileInput"
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
          <Button className="btn btn-success mt-3" onClick={handleUpload}>
            Upload
          </Button>
          
        </div>
        <button style={{display:"flex",width:"200px",justifyContent:"center"}} type="button" className="btn btn-secondary mt-3" onClick={()=>navigate("/superadmindashboard")}>Back to dashboard</button>
      </div>
    </div>
  );
};

export default SoapPromptsList;
