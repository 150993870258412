import styled from "styled-components";

export const TopbarContainer = styled.div`
  height: 90px;
  width: 100vw;
  background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
  padding: 10px 49px;
  position: fixed;
  top: 0px;
  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .denotes-logo {
      width: auto;
      height: auto;
      @media (max-width: 767px) {
        width: 130px;
        height: 40px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 21px 20px;
  }
`;
export const SideTopbarContainer = styled.div`
  width: 100vw;
  background-image: linear-gradient(to right, #1C1C1C, #4D4D4D);
  padding: 8px 44px;
  position: fixed;
  top: 0px;
  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .denotes-logo {
      width: 130px;
      height: 40px;
    }
  }
  @media (max-width: 767px) {
    padding: 8px 20px;
  }
`;

export const MobileTop = styled.div`
  height: 100px;
  width: 100vw;
  z-index: 9;
  background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
  padding: 10px 49px;
  position: fixed;
  top: 0px;
  .heading-top-text {
    text-align: center;
    font-size: 36px;
    color: #fff;
  }
`;
export const MobileTopforgot = styled.div`
  height: 100px;
  width: 100vw;
  z-index: 9;
  background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
  padding: 10px 49px;
  position: fixed;
  top: 0px;
  .heading-top-text {
    text-align: center;
    font-size: 36px;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 28px;
    }
  }
`;
export const TopbarRight = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  .ic-bg {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    // color: ${(props) => props.theme.themeTextColor};
    cursor: pointer;
    &:hover {
      // background-color: ${(props) => props.theme.themeSecondaryColor};
    }
  }
  @media (max-width: 767px) {
    gap: 1px;
  }
`;
export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  .dropdown-menu {
    min-width: 250px;
    max-width: 300px;
    padding: 10px;
    z-index: 1050;
  }
  .hp-img-right {
    height: auto;
  }
    a.se-link.dropdown-item {
    display: flex;
    align-items: center;
}

  .dropdown-toggle {
    display: flex;
    align-items: center;
    // color: ${(props) => props.theme.primaryTextColor};
    justify-content: center;
    padding: 2px;
    // background-color: ${(props) => props.theme.primaryColor};
    // border: 1px solid ${(props) => props.theme.primaryColor};
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-weight: 600;
    font-size: 14px;
    &::after,
    &::before {
      display: none;
    }
    svg {
      margin-right: 4px;
      // fill: ${(props) => props.theme.primaryTextColor};
    }
    &:hover,
    &:focus,
    &:active {
      // color: ${(props) => props.theme.primaryHoverTextColor};
      // border: 1px solid ${(props) => props.theme.primaryHoverColor};
      // background-color: ${(props) =>
        props.theme.primaryHoverColor} !important;
    }
  }
  .dropdown-item {
    font-size: 14px;
    padding: 6px 10px;
    h5 {
      font-size: 15px;
      margin-bottom: 0;
      font-weight: 500;
      color: #1a202c;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
    p {
      font-size: 13px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      margin-bottom: 0px;
    }
    h6 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 2px;
      color: #1a202c;
    }
    &:hover,
    &:focus,
    &:active {
      color: #000;
      background-color: #f5f5f5 !important;
    }
  }
  .se-link {
    font-weight: 500;
    color: #1a202c;
    a {
      color: #1a202c;
      text-decoration: none;
      font-weight: 500;
    }
    svg {
      margin-right: 5px;
      color: #1a202c;
    }
  }
`;
