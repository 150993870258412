import React from "react";
import { Modal } from "react-bootstrap";
import { ModelContainers } from "../MainEngineStyle";

function MessageModel({ showModal, setShowModal, messageData }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <ModelContainers>
      <Modal.Body ><p className="message-line">{messageData}</p></Modal.Body>
      </ModelContainers>
    </Modal>
  );
}

export default MessageModel;
