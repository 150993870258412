import "bootstrap/dist/css/bootstrap.min.css";
import LoginUser from "./pages/Login-page/LoginUser";
import Home from "./pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Doctorlist from "./pages/Doctor-Page/Doctorlistpage";
import MainEngine from "./pages/Engine-Page/mainEngine/MainEngine";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import PatientList from "./pages/Doctor-Page/Components/PatientList";
import SoapNotes from "./pages/Engine-Page/mainEngine/Components/SoapNotes";
import Visithistory from "./pages/Doctor-Page/Components/ConversationHistory";
import Token from "./pages/Doctor-Page/Components/Token";
import SuperAdminlogin from "./pages/Super-admin/SuperadminLogin";
import SuperAdminDashboard from "./pages/Super-admin/SuperAdminDashboard";
import SuperadminRegister from "./pages/Super-admin/SuperAdminRegistration";
import Organisationtesting from "./pages/Super-admin/Organisationtesting";
import OrganizationsTable from "./pages/Super-admin/OrganizationList";
import SpecialtiesTable from "./pages/Super-admin/Components/SpecialtiesTable";
import AddSpecialtyForm from "./pages/Super-admin/Components/AddSpecialtyForm";
import AddPatient from "./pages/Patient-page/AddPatient";
import InteractionTable from "./pages/Doctor-Page/Components/InteractionList";
// import InteractionDetail from "./pages/Interaction-Detail-Page/InteractionDetail";
import SoapnotesDisplay from "./pages/Soap-notes/SoapnotesDisplay";
import InteractionPatient from "./pages/Patient-page/InteractionPatient";
import CreateNewToken from "./pages/FrontOffice/CreateNewToken";
import PatientListedit from "./pages/FrontOffice/PatientListedit";
import MyActivity from "./pages/Interaction-Detail-Page/MyActivity";
import CreateAssessment from "./pages/AssessmentTemplate/AssessmentPage";
import TechActivity from "./pages/FrontOffice/TechActivity";
import SidePatients from "./pages/Interaction-Detail-Page/SidePatients";
// import DoctorFeed from "./pages/Doctor-Page/Components/DoctorFeed/DoctorFeed";
import FrontActivity from "./pages/FrontOffice/FrontActivity";
import AdminPage from "./pages/AdminPage/AdminPage";
import Settings from "./pages/SettingsPage/SettingsPage"


const msalInstance = new PublicClientApplication(msalConfig);

// Routers
function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<LoginUser />} />
            <Route path="/memberlist" element={<Doctorlist />} />
            <Route path="/engine" element={<MainEngine />} />
            <Route path="/patientlist" element={<PatientList />} />
            <Route path="/visithistory" element={<Visithistory />} />
            <Route path="/soapnotes" element={<SoapNotes />} />
            <Route path="/token" element={<Token />} />
            <Route path="/superadminlogin" element={<SuperAdminlogin />} />
            <Route
              path="/superadmindashboard"
              element={<SuperAdminDashboard />}
            />
            <Route
              path="/superadminregister"
              element={<SuperadminRegister />}
            />
            <Route
              path="/organizationtesting"
              element={<Organisationtesting />}
            />
            <Route path="/organizationlist" element={<OrganizationsTable />} />
            <Route
              path="/interactionPatient"
              element={<InteractionPatient />}
            />
            <Route path="/specialties" element={<AddSpecialtyForm />} />
            <Route path="/specialtieslist" element={<SpecialtiesTable />} />
            <Route path="/addpatient" element={<AddPatient />} />
            <Route path="/interactionlist" element={<InteractionTable />} />
            {/* <Route path="/interactiondetail" element={<InteractionDetail />} /> */}
            <Route path="/soapnotesdetail" element={<SoapnotesDisplay />} />
            <Route path="/createtoken" element={<CreateNewToken />} />
            <Route path="/patientedit" element={<PatientListedit />} />
            <Route path="/myactivity" element={<MyActivity />} />
            <Route path="/createassessment" element={<CreateAssessment />} />
            <Route path="/activity" element={<SidePatients />} />
            <Route path="/tech-activity" element={<TechActivity />} />
            <Route path="/front-activity" element={<FrontActivity />} />
            {/* <Route path="/doctorfeed" element={<DoctorFeed/>}/> */}
            <Route path="/adminpage" element={<AdminPage />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </BrowserRouter>
      </div>
    </MsalProvider>
  );
}

export default App;
