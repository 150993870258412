import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //    height: calc(100vh - 103px) !important;
  height: 100vh;
  .top-btn {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 50px;
    margin-top: 50px;
    gap: 5px;
    .line-button {
      display: flex;
      flex-direction: row;
      background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
      width: 254px;
      height: 72px;
      border-radius: 156px;
      padding: 5px;
      .line-btn-round {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn-text {
        width: 185px;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0px;
      align-items: center;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    .welcome {
      margin: 50px;
      .welcome-text {
        background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 50px;
        @media (max-width: 767px) {
          font-size: 32px;
        }
      }
      @media (max-width: 767px) {
        margin: 14px;
      }
    }
    .text-part {
      margin: 30px;
      font-size: 20px;
      .text-part-p {
        text-align: center;
      }
    }
  }
`;
