import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import { TokenPageContainer } from "../Doctorstyle";
import { PatientSession } from "./PatientSession";
import { configJson } from "../../../Constent/config";
import { Alert, Button, Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { DateTime } from "luxon";
import DoctorCreatePatient from "./DoctorCreatePatient";

function Token() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]); // Replacing tokens with users from backend
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [show, setShow] = useState(false);
  // const [selectedUser, setSelectedUser] = useState(null); // Storing selected user details
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [decode, setDecode] = useState(null);
  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  // Fetch user tokens when the component mounts
  const fetchTokenUsers = async (doctor_id, formattedDate) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/interaction/token/${doctor_id}/99/${formattedDate}`
      ); // Replace with the correct API route
      if (!response.ok) {
        throw new Error("Failed to fetch token users");
      }
      const data = await response.json();
      setUsers(data.interaction);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      // const today = new Date();
      // const year = today.getFullYear();
      // const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
      // const day = String(today.getDate()).padStart(2, "0");
      // const formattedDate = `${year}-${month}-${day}`;
      // const timestamp = Date.now();
      // // console.log(timestamp); // Logs the current timestamp

      // const now = new Date(timestamp);

      // // Extract year, month, and day, and format them as YYYY-MM-DD
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-indexed
      // const day = String(now.getDate()).padStart(2, "0");

      // const formattedDate = `${year}-${month}-${day}`;
      // console.log("date", formattedDate); // Output
      const formattedDates = DateTime.now().toUTC().toFormat("yyyy-MM-dd");
      const formattedDateTime = DateTime.now()
        .toUTC()
        .toFormat("yyyy-MM-dd, HH:mm:ss");
      console.log("date", formattedDates, "time", formattedDateTime); // Output

      fetchTokenUsers(decoded.doctor_id, formattedDates);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  // useEffect(() => {
  //   // Fetch data from the backend on component mount
  //   fetchTokenUsers();

  // }, []);

  // Pagination logic
  const totalUsers = users.length;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const indexOfFirstUser = (currentPage - 1) * usersPerPage;
  const currentUsers = users.slice(
    indexOfFirstUser,
    indexOfFirstUser + usersPerPage
  );
  // Handle user click to open the session modal
  const handleUserClick = (user) => {
    if (user) {
      // const userdata = {
      //   hospital_patient_id: user.Patient.hospital_patient_id,
      //   first_name: user.Patient.first_name,
      //   last_name: user.Patient.last_name,
      //   date_of_birth: user.Patient.date_of_birth,
      //   language: user.Patient.language,
      //   phone_number: user.Patient.phone_number,
      //   patient_id: user.Patient.patient_id,
      //   interaction_id: user.interaction_id,
      // };
      navigate("/engine", {
        state: {
          patientFirstName: user.Patient.first_name,
          patientLastName: user.Patient.last_name,
          patient_id: user.Patient.patient_id,
          doctorFirstName: decode.first_name,
          doctorLastName: decode.last_name,
          doctor_id: decode.doctor_id,
          organization_id: decode.organization_id,
          language: user.Patient.language,
          interaction_id: user.interaction_id,
        },
      });
      // setSelectedUser(userdata);
      // setShow(true);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Display error message if there was an error during fetch
  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  return (
    <Sidebar setuser={true}>
      <TokenPageContainer>
        {/* {show && (
          <PatientSession
            show={show}
            handleClose={handleClose}
            tokendata={selectedUser}
            tokensStatus={() => {}}
          />
        )} */}
        {show && <DoctorCreatePatient show={show} handleClose={handleClose} />}
        <div className="heading-text">
          <h4 className="gradient-text">Today's Appointments</h4>
          <span className="heading-text-line"></span>
          <Button type="button" onClick={() => setShow(true)}>
            Create Token
          </Button>
        </div>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {users.length === 0 ? (
              <>
                <Alert variant="info" className="show-hide">
                  No New Hospital Appointment found. Please create Appointment
                  from front office
                </Alert>
                {/* <Button
                  type="button"
                  onClick={() => navigate("/createtoken")}
                >Create Token</Button> */}
              </>
            ) : (
              ""
            )}
          </>
        )}
        {users.length > 0 ? (
          <div className="token-buttons-pagination-container">
            <div className="token-buttons-container">
              {currentUsers.map((user) => (
                <button
                  key={user.Patient.first_name + user.Patient.last_name} // Using name as unique key
                  onClick={() => handleUserClick(user)}
                  className={
                    user.interaction_status === "2"
                      ? "token-button active"
                      : "token-button"
                  }
                  //className="token-button"
                >
                  {user.Patient.first_name.charAt(0).toUpperCase() +
                    user.Patient.first_name.slice(1).toLowerCase() +
                    " " +
                    user.Patient.last_name.charAt(0).toUpperCase() +
                    user.Patient.last_name.slice(1).toLowerCase()}
                  <br></br>
                </button>
              ))}
            </div>
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>

              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`pagination-button ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                >
                  {i + 1}
                </button>
              ))}

              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </TokenPageContainer>
    </Sidebar>
  );
}

export default Token;
