import React, { useEffect, useState } from "react";
import Sidebar from "../../Home/Components/Sidebar/Sidebar";
import {
  CardContainer,
  PatientListContainer,
  TableContainers,
  TableHeader,
} from "../Doctorstyle";
import searchicon from "../../../assets/sidebar/searchicon.png";
import Table from "react-bootstrap/Table";
import { configJson } from "../../../Constent/config";
import sorticon from "../../../assets/table/sort.png";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Mybtn } from "../../Engine-Page/mainEngine/MainEngineStyle";
import { jwtDecode } from "jwt-decode";
import { Alert, Spinner } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import App from "../../../App.css";
import { convertToKolkataTimeDOB, convertToKolkataTimeFormat } from "../../../helpers";

function PatientList() {
  const screenView = true;
  const [searchvalue, setSearchvalue] = useState("");
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("patientPage")
      ? localStorage.getItem("patientPage")
      : 1
  );
  const itemsPerPage = 7;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const navigate = useNavigate();

  // Filter data based on search term
  const filteredData = patients.filter((item) => {
    // const patientDate = new Date(item.last_visited.split("T")[0]);
    // const from = fromDate ? new Date(fromDate) : null;
    // const to = toDate ? new Date(toDate) : null;
    const patientDate = convertToKolkataTimeDOB(item.updated_at);
    const from = fromDate ? convertToKolkataTimeDOB(fromDate) : null;
    const to = toDate ? convertToKolkataTimeDOB(toDate) : null;
    const matchesSearch =
      item.Patient.first_name
        .toLowerCase()
        .includes(searchvalue.toLowerCase()) ||
      item.Patient.last_name.toLowerCase().includes(searchvalue.toLowerCase());

    const withinDateRange =
      (!from || patientDate >= from) && (!to || patientDate <= to);

    return matchesSearch && withinDateRange;
  });

  // Calculate paginated data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Next button click handler
  const handleNextPage = () => {
    if (startIndex + itemsPerPage < filteredData.length) {
      setCurrentPage((prevPage) => prevPage + 1);
      localStorage.setItem("patientPage", currentPage + 1);
    }
  };

  // Previous button click handler (if needed)
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      localStorage.setItem("patientPage", currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //get vist History
  async function getVisitHistory(doctor_id) {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/doctor/patient/${doctor_id}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        setPatients(result);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const handlePatient = (patient) => {
    navigate("/interactionPatient", {
      state: {
        patientId: patient.patient_id,
        patientFirstName: patient.Patient.first_name,
        patientLastName: patient.Patient.last_name,
      },
    });
  };
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      getVisitHistory(decoded.doctor_id);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const visiblePageButtons = 5;
  const pageNumbers = [];
  const startPage = Math.max(
    1,
    currentPage - Math.floor(visiblePageButtons / 2)
  );
  const endPage = Math.min(totalPages, startPage + visiblePageButtons - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Sidebar setuser={true}>
      <PatientListContainer>
        {screenView ? (
          <TableContainers>
            <div className="heading-text">
              <h4 className="gradient-text">My Patients</h4>
              <span className="heading-text-line"></span>
            </div>
            <div className="search-items">
              <div className="date-filters">
                <div className="date-header">
                  <div className="date">
                    <input
                      type="date"
                      className="date-field"
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                  <span className="arrow">
                    <FaArrowRight />
                  </span>
                  <div className="date">
                    <input
                      type="date"
                      className="date-field"
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e.target.value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
              <input
                className="input-text"
                type="text"
                name="search"
                placeholder="Patient Name"
                value={searchvalue}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
              <span className="search-icon-container">
                <img
                  src={searchicon}
                  alt=""
                  title=""
                  className="search-input-icon"
                />{" "}
              </span>
            </div>
            <TableHeader>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : error ? (
                <Alert variant="danger">{error}</Alert>
              ) : patients.length > 0 ? (
                <Table
                  borderless
                  responsive
                  className="table-styled tablecustomshrink"
                >
                  <thead style={{ textAlign: "center" }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>
                        <div
                          style={{ textAlign: "center" }}
                          className="d-flex flex-row justify-content-center"
                        >
                          Patient Name
                        </div>
                      </th>

                      <th>Last Visit Date/Time</th>
                      <th>DOB</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {paginatedData
                      // .sort((a, b) => {
                      //   // Get the first names
                      //   const firstNameA = a.Patient.first_name.toLowerCase();
                      //   const firstNameB = b.Patient.first_name.toLowerCase();

                      //   // Compare the first names
                      //   if (firstNameA < firstNameB) return -1; // A comes before B
                      //   if (firstNameA > firstNameB) return 1; // A comes after B
                      //   return 0; // They are equal
                      // })
                      .map((patient) => (
                        <tr
                          key={patient.interaction_id}
                          onClick={() => handlePatient(patient)}
                        >
                          <td>
                            <span>
                              {patient.Patient.first_name
                                .charAt(0)
                                .toUpperCase() +
                                patient.Patient.first_name
                                  .slice(1)
                                  .toLowerCase()}{" "}
                              {patient.Patient.last_name
                                .charAt(0)
                                .toUpperCase() +
                                patient.Patient.last_name
                                  .slice(1)
                                  .toLowerCase()}
                            </span>
                          </td>
                          {/* Displaying the date of birth */}

                          <td>
                            {convertToKolkataTimeFormat(patient.updated_at)}
                          </td>
                          <td>
                          {convertToKolkataTimeDOB(patient.Patient.date_of_birth)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <Alert variant="info">No Patient found</Alert>
              )}
              <div className="next-btn-container mt-2">
                {currentPage === 1 ? (
                  <div></div>
                ) : (
                  <Mybtn>
                    <Button className="new-style-btn" onClick={handlePrevPage}>
                      Previous
                    </Button>
                  </Mybtn>
                )}

                <div className="flex items-center gap-2">
                  {/* Always show the first page */}
                  {startPage > 1 && (
                    <>
                      <Button
                        className={`!border-none ${
                          currentPage === 1 ? "!bg-gray-400" : "!bg-[#414040]"
                        }`}
                        onClick={() => handlePageClick(1)}
                      >
                        1
                      </Button>
                      {startPage > 2 && <span>...</span>}
                    </>
                  )}

                  {/* Show visible page buttons */}
                  {pageNumbers.map((pageNumber) => (
                    <Button
                      key={pageNumber}
                      className={`!border-none ${
                        pageNumber === currentPage
                          ? "!bg-gray-400"
                          : "!bg-[#414040]"
                      }`}
                      onClick={() => handlePageClick(pageNumber)}
                    >
                      {pageNumber}
                    </Button>
                  ))}

                  {/* Always show the last page */}
                  {endPage < totalPages && (
                    <>
                      {endPage < totalPages - 1 && <span>...</span>}
                      <Button
                        className={`!border-none ${
                          currentPage === totalPages
                            ? "!bg-gray-400"
                            : "!bg-[#414040]"
                        }`}
                        onClick={() => handlePageClick(totalPages)}
                      >
                        {totalPages}
                      </Button>
                    </>
                  )}
                </div>

                {startIndex + itemsPerPage >= filteredData.length ? (
                  <p></p>
                ) : (
                  <Mybtn>
                    <Button className="new-style-btn" onClick={handleNextPage}>
                      Next
                    </Button>
                  </Mybtn>
                )}
              </div>
            </TableHeader>
          </TableContainers>
        ) : (
          <CardContainer></CardContainer>
        )}
      </PatientListContainer>
    </Sidebar>
  );
}

export default PatientList;

export const OrderChange = ({ selectdata1, selectdata2, handleSelected }) => {
  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={
        <img
          src={sorticon}
          alt="Sort Icon"
          style={{ width: "20px", height: "20px" }}
        />
      }
      onSelect={handleSelected}
      variant="light"
      align="end"
    >
      <Dropdown.Item eventKey={selectdata1}>Ascending</Dropdown.Item>
      <Dropdown.Item eventKey={selectdata2}>Descending</Dropdown.Item>
    </DropdownButton>
  );
};
