import styled from "styled-components";


export const SuperadminContainer = styled.div`
  .header-text {
    text-align: center;
    .header-text-part {
      background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
`;
export const SuperadminForm = styled.div`
  .form-control {
    padding-left: 46px;
    margin-bottom: -10px;
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 43px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
  .input-img-container {
      width: 20px;
  display:flex;
      align-items: center;
    position: relative;
    bottom: 24px;
    left: 8px;
    .sign-input-image {
      width: 15px;
      height: 15px;
    }
    .sign-input-or {
      padding: 5px;
    }
  }
  .doc-log-sub {
    color: #fff;
    background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
    padding: 5px 20px 5px 20px;
  }
`;