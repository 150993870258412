import React, { useEffect, useState, useRef } from "react";
import { configJson } from "../../../../Constent/config";
import {
  Form,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
  Image,
  InputGroup,
} from "react-bootstrap";
import {
  MicSection,
  ChatFooter,
  UserResponse,
  BotResponse,
  QueryResponse,
  ChatContainer,
} from "../../../ThemeStyle/ChatbotStyle";
import { v4 as uuidv4 } from "uuid";
import { FaMicrophone, FaStop, FaVideo } from "react-icons/fa6";

import MarkdownDisplay from "./MarkdownDisplay";
import FormatMessage from "./FormatMessages";
import userImg from "../../../../assets/user/AvatarIcon.png";
import bot from "../../../../assets/user/bot-icon.png";
import ChatAudioText from "./ChatAudioText";
import "./chatbot.css";
import { FaCamera } from "react-icons/fa";
import RecordingScreen from "../../../Interaction-Detail-Page/RecordingScreen";
import { RecordContainer } from "../../../Interaction-Detail-Page/ActivityStyle";

const ChatbotQuery = ({
  organizationId,
  doctorId,
  patientId,
  // staticQns,
  // setStaticQns,
  role,
  ISChecked,
  setChecked,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [botError, setBotError] = useState(false);
  const [botFetch, setBotFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receivedFiles, setReceivedFiles] = useState([]);
  const [voiceInput, setVoiceInput] = useState("");
  const sessionId = useRef(uuidv4());
  const [queryResponse, setQueryResponse] = useState();
  const [imageFile, setImageFile] = useState(null);
  const [staticQns, setStaticQns] = useState("");

  const setVoiceInputText = (data) => {
    // setStaticQns(null);
    setInputValue(data);
  };
  const _init_message = [
    {
      author: "bot",
      content: "How can i help you",
    },
  ];
  const [messages, setMessage] = useState(_init_message);
  const [close, setClose] = useState(true);

  // Define the function to handle receiving files data from ChatImg
  const handleFilesData = (filesData) => {
    // setReceivedFiles(filesData);
    setReceivedFiles((prevFiles) => [...prevFiles, ...filesData]);
  };
  //   const handleCamFilesData = (camFilesData) => {
  //     console.log(233, camFilesData);
  //     setReceivedFiles((prevFiles) => [...prevFiles, ...camFilesData]);
  //   };
  const handleInputChange = (event) => {
    // setStaticQns(null);
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    console.log("keypress");
    if (!inputValue || botFetch) {
      return null;
    }
    if (event.key === "Enter") {
      sendQuestion();
    }
  };

  function uploadChatImg(receivedFiles) {
    console.log(228, receivedFiles);
    return new Promise(async (resolve) => {
      try {
        let formdata = new FormData();
        if (!receivedFiles?.length) {
          throw "file cannot be empty";
        }
        receivedFiles.forEach((file) => formdata.append("file", file));

        // let requestOptions = {
        //   method: "POST",
        //   body: formdata,
        //   redirect: "follow",
        // };
        // let url = `${configJson.CHATBOT_URL}/api/simmer/v1/accounts/${merchant_user["company_id"]}/file/upload/chatinputimages`;

        // const response = await fetch(url, requestOptions);
        // if (!response.ok) {
        //   throw "error";
        // }
        // let data = await response.json();
        // resolve([null, data.public_link]);
      } catch (error) {
        console.log(error);
        resolve([error, null]);
      }
    });
  }
  const removeFile = (name) => {
    setReceivedFiles((files) => files.filter((file) => file.name !== name));
  };
  async function middlewaresubmission({
    question,
    product_name = "",
    blob_url = null,
  }) {
    if (botFetch) {
      return;
    }
    setBotFetch(true);

    await sendQuestion({
      question: question,
      product_name: product_name,
      blob_url,
      // chatImgLink,

      //   user_id = form_data.get("user_id")
      //   patient_id = form_data.get("patient_id", None)
      //   doctor_id = int(form_data.get('doctor_id',0))
      //   collection_name = form_data.get('collection_name')
      //   question_text = form_data.get("question_text")
      //   question_audio = files.get('question_audio')
    });
    setBotFetch(false);
  }

  function changelastmessage(newmessageObj, fallback = false) {
    setMessage((prevstate) => {
      let tempArr = [...prevstate];
      tempArr.pop();
      if (newmessageObj["question_audio_link"]) {
        tempArr.pop();
        tempArr.push({
          audio_link: newmessageObj["question_audio_link"],
          author: "user",
          content: "",
          audio_text: newmessageObj["question"],
        });
      }
      let final_output;
      if (fallback) {
        newmessageObj = {
          author: "bot",
          content: "",
          temp: true,
          chatLoader: true,
        };
      }
      final_output = [...tempArr, newmessageObj];
      // sync_message_session(final_output);
      return final_output;
    });
  }
  function sendQuestion() {
    setQueryResponse();
    setLoading(true);

    return new Promise(async (resolve) => {
      const session_id = sessionId.current;
      const formData = new FormData();
      formData.append("user_id", organizationId);
      formData.append("question_text", staticQns ? staticQns : inputValue);
      // formData.append("organization_id", organizationId);
      formData.append("patient_id", patientId);
      // formData.append("patient_id", "170b94c0");
      // https://medscribe-server-sand.medscribe.in/api/simmer/v1/accounts/medscribe/chat/230447-a2d-420f-85c7-47bb2c0c8bec85?user_id=ff547fa8-732a-46b2-9c6f-dc532ef33e2b&patient_id=4&doctor_id=4&collection_name=medscribe'

      formData.append("doctor_id", doctorId);
      // formData.append("doctor_id", "31");

      try {
        for (let pair of formData.entries()) {
          console.log(pair[0], pair[1]);
        }

        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };
        let url = new URL(
          `${configJson.CHATBOT_URL}/api/simmer/v1/accounts/${organizationId}/chat/${session_id}`
          // `${configJson.CHATBOT_URL}/api/simmer/v1/accounts/kuraray-sandbox2/chat/${session_id}`
        );
        url.searchParams.set("lp", "true");
        url = url.toString();

        fetch(url, requestOptions)
          .then((data) => {
            if (data.status !== 200) {
              setBotError(true);
              return Promise.reject(new Error("Fetch failed"));
            }
            return data.json();
          })

          .then((data) => {
            // clearTimeout(loadingTimeout);
            setQueryResponse(data);
            setLoading(false);
            setClose(true);

            // changelastmessage({
            //   ...data,
            //   // product_name: product_name || activeChip?.product_name || "",
            //   author: "bot",
            //   content: data.answer.replaceAll("\\xa0", " "),
            //   bot_answer_id: data?.conversation_id,
            //   session_id: data?.session_id,
            //   time: data?.answered_at,
            //   map_link: data?.map_link,
            //   audio_link: data?.answer_audio_link,
            //   source: data?.source,
            //   question_audio_link: data?.question_audio_link,
            //   product_name: data?.product_name,
            //   answer_video_url: data?.answer_video_link,
            // });
            // return data; //need to enable this when uncomment image
            resolve("fetch done");
          });
      } catch (err) {
        // setStaticQns('')
        console.log(err);
      } finally {
        setStaticQns("");
        setInputValue("");
      }
    });
  }

  useEffect(() => {
    if (staticQns != "") {
      sendQuestion();
    }

    console.log("QQQQ", staticQns);
  }, [staticQns]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Save the uploaded image in the state
    }
  };

  // console.log("III", imageFile);

  const displayImage = () => {
    if (!imageFile) return null; // Handle case where no image is selected

    // Handle different image loading scenarios:
    if (imageFile instanceof File) {
      // Assuming you're using FileReader:
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageFile(e.target.result); // Update state with image data URL
      };
      reader.readAsDataURL(imageFile);
    } else if (typeof imageFile === "string") {
      // If imageFile is a URL:
      return <img src={imageFile} alt="" width={50} />;
    } else {
      console.error("Unexpected imageFile format");
      return null; // Handle unexpected format
    }
  };
  const stopRecording = () => {
    setChecked(false);
  };
  const startRecording = () => {
    setChecked(true);
  };

  return (
    <div>
      <ChatContainer>
        <div className="flex items-center justify-center gap-3 text-sm flex-wrap">
          <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => setStaticQns("Generate Soap notes")}
          >
            Generate Soap notes
          </div>
          <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => setStaticQns("Create Prescription")}
          >
            Create Prescription
          </div>
          <div
            className="p-2 px-4 rounded-full bg-gray-200 cursor-pointer"
            onClick={() => setStaticQns("Create Assessment")}
          >
            Create Assessments
          </div>
        </div>
        <ChatFooter className="chatbot-input">
          <div className="foot-section">
            {Boolean(!botError) && (
              <>
                <div className="chatt-input">
                  <InputGroup>
                    {imageFile && displayImage()}
                    <div className="input-section">
                      <Form.Control
                        placeholder={"Ask your question?"}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                      />

                      {/* <button
                      type="button"
                      variant="link"
                      className="text-xl mr-1"
                      onClick={() => document.getElementById('imageUpload').click()}
                    >
                      <FaCamera />
                    </button> */}
                      <input
                        type="file"
                        id="imageUpload"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                      {role === "doctor" ? (
                        <RecordContainer>
                          <div className="record-group-button">
                            <button
                              type="button"
                              className="start-stop-btn"
                              onClick={
                                ISChecked ? stopRecording : startRecording
                              }
                            >
                              <div className="button-content">
                                <span className="rec-btn-icon">
                                  {ISChecked ? (
                                    <FaStop size={20} />
                                  ) : (
                                    <FaMicrophone size={20} />
                                  )}
                                </span>
                              </div>
                            </button>
                          </div>
                        </RecordContainer>
                      ) : (
                        <MicSection>
                          <div className="pca-mic">
                            <ChatAudioText
                              setVoiceInputText={setVoiceInputText}
                            />
                          </div>
                        </MicSection>
                      )}
                      <InputGroup.Text id="basic-addon2">
                        <Button
                          type="button"
                          variant="link"
                          onClick={sendQuestion}
                          disabled={!inputValue || botFetch}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="send-btn"
                          >
                            <title>ionicons-v5-q</title>
                            <path d="M16,464,496,256,16,48V208l320,48L16,304Z" />
                          </svg>
                        </Button>
                      </InputGroup.Text>
                    </div>
                    {/* <ThumbContainer>{thumbs}</ThumbContainer> */}
                  </InputGroup>
                  {/* <div className="camera-container">
                  <button
                    type="button"
                    variant="link"
                    className="text-xl mr-1"
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                  >
                    <FaCamera />
                  </button>
                  <button type="button"><FaVideo size={20}/></button>
                </div> */}
                </div>
              </>
            )}
          </div>
        </ChatFooter>
      </ChatContainer>
      {queryResponse && (
        <div>
          {close ? (
            <QueryResponse>
              <div className="pb-3">
                <div className="text-end">
                  <Button
                    type="button"
                    className="theme-button"
                    onClick={() => setClose(false)}
                  >
                    Close
                  </Button>
                </div>
                <UserResponse>
                  <div className="user-msg-section">
                    <div>
                      <Image
                        className={"user-img"}
                        src={userImg}
                        alt="bot image"
                      />
                    </div>
                    <div className={`user-txt`}>
                      {queryResponse?.question}
                      {/* <FormatMessage
                text={queryResponse.question}
                // text={inputValue}
                message={"currentEditData"}
              /> */}
                    </div>
                  </div>
                </UserResponse>
                <BotResponse>
                  <div>
                    <Image className={"bot-img"} src={bot} alt="bot image" />
                  </div>

                  <>
                    <div className="bot-content">
                      <div>
                        <MarkdownDisplay
                          markdownContent={queryResponse?.answer}
                          // markdownContent={
                          //   "To provide the most relevant information about what is panavia, please specify which product you are referring to?"
                          // }
                        />
                      </div>
                    </div>
                  </>
                </BotResponse>
              </div>
            </QueryResponse>
          ) : (
            ""
          )}
        </div>
      )}
      {loading && (
        <QueryResponse>
          <div class="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </QueryResponse>
      )}
    </div>
  );
};
export default ChatbotQuery;
