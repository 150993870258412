import React, { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { configJson } from "../../../Constent/config";

const AddSpecialtyForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Handle form submission
  const onSubmit = async (data) => {
    setMessage("");
    setError("");

    try {
      // Send POST request to create a new specialty
      const response = await fetch(
        `${configJson.backend_URL}/specialties/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            specialty_name: data.specialty_name,
            description: data.description, // Send description if provided
          }),
        }
      );

      // Check for HTTP errors
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      // Handle success response
      const result = await response.json();
      setMessage("Specialty created successfully ");
    } catch (error) {
      setMessage("");
      setError(error.message);
    }
  };

  return (
    <Container>
      <h2>Add Specialty</h2>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="custom-form"
        autoComplete="off"
      >
        <Form.Group controlId="specialtyName">
          <Form.Label>Specialty Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter specialty name"
            {...register("specialty_name", {
              required: "Specialty name is required",
              pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message:
                  "Specialty name should not contain special characters.",
              },
            })}
            isInvalid={!!errors.specialty_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.specialty_name?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter description "
            {...register("description")}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Specialty
        </Button>
      </Form>

      {/* Display success or error message */}
      {message && (
        <Alert variant="success" className="mt-3">
          {message}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default AddSpecialtyForm;
