import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { configJson } from "../../Constent/config";
import { AddNewDoctor } from "./Doctorstyle";
import { useForm } from "react-hook-form";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import eyeicon from "../../assets/signup/eye.png";
import eyeClose from "../../assets/signup/eyeclose.png";
import { AdddoctorForm } from "../Login-page/LoginStyle";

const AddDoctorModal = ({ show, handleClose, token }) => {
  const [decode, setDecode] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue, // This allows us to manually set the form value for react-hook-form
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState("doctor"); // New state for member type
  const [specialities, setSpecialities] = useState([]); // State for storing specialities
  const navigate = useNavigate();

  // Function to fetch specialties from the API
  const fetchSpecialities = async () => {
    try {
      const response = await fetch(`${configJson.backend_URL}/specialties/`);
      const data = await response.json();
      setSpecialities(data); // Set specialties if data is an array
    } catch (error) {
      console.error("Error fetching specialities:", error);
    }
  };

  // Handle specialty change
  const handleSpecialityChange = (e) => {
    const selected = e.target.value;
    setValue("specialty_id", selected); // Manually set the form value using react-hook-form
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle member type change (doctor/technician)
  const handleMemberTypeChange = (e) => {
    const selected = e.target.value;
    setSelectedMemberType(selected); // Update member type state
  };

  // Submit the form
  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/create`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          organization_id: decode.organization_id,
          speciality_id:
            selectedMemberType === "doctor" ? data.specialty_id : null, // Send speciality only for doctors
          first_name: data.first_name,
          last_name: data.last_name,
          license_no: data.license_no,
          password_hash: data.password,
          phone_number: data.phone_number,
          email: data.email,
          role: selectedMemberType, // Set role based on selected member type
        }),
      });

      const result = await response.json();

      if (response.ok) {
        reset();
        handleClose(); // Close the modal
      } else {
        setErrorMessage(result.message || "An error occurred");
      }
    } catch (error) {
      setErrorMessage(`An unexpected error occurred: ${error}`);
    }
  };

  // Decode JWT token
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      fetchSpecialities();
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (

    <Modal className="Memberaddition"
      show={show}
      onHide={handleClose}
      style={{ position: "absolute", left: "0%", top: "17%" }}
      backdrop="static"
    >
      <AddNewDoctor>
      <div >
        <Modal.Header closeButton style={{ alignItems: "center" }}>
          <div className="head-text-container">
            <h2 className="head-text">Add Member</h2>
          </div>
        </Modal.Header>
        <Modal.Body className="Modalmember">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <AdddoctorForm>
            <Form  onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3 sh-pass" as={Row} controlId="formMemberType">
                <Form.Label column sm={4}>
                  Member Type <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm={8} className="d-flex">
                  <Form.Check
                    type="radio"
                    label="Doctor"
                    value="doctor"
                    {...register("member_type", {
                      required: "Member type is required",
                    })}
                    onChange={handleMemberTypeChange}
                    checked={selectedMemberType === "doctor"}
                  />
                  <Form.Check
                    type="radio"
                    label="Technician"
                    value="technician"
                    {...register("member_type", {
                      required: "Member type is required",
                    })}
                    onChange={handleMemberTypeChange}
                    checked={selectedMemberType === "technician"}
                  />
                  
                     <Form.Check
                    type="radio"
                    label="Front Office Staff"
                    value="front office staff"
                    {...register("member_type", {
                      required: "Member type is required",
                    })}
                    onChange={handleMemberTypeChange}
                    checked={selectedMemberType === "front office staff"}
                  />
                  {errors.member_type && (
                    <Form.Text className="text-danger">
                      {errors.member_type.message}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>

              {/* Conditionally render the speciality field if member type is Doctor */}
              {selectedMemberType === "doctor" && (
                <Form.Group className="mb-3 sh-pass" as={Row} controlId="formSpeciality">
                  <Form.Label column sm={4}>
                    Speciality <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      {...register("specialty_id", {
                        required: "Speciality is required",
                      })}
                      onChange={handleSpecialityChange} // Update state and form value
                    >
                      <option value="">Select Speciality</option>
                      {specialities.map((speciality) => (
                        <option
                          key={speciality.speciality_id}
                          value={speciality.speciality_id}
                        >
                          {speciality.specialty_name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.specialty_id && (
                      <Form.Text className="text-danger">
                        {errors.specialty_id.message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              )}

              {[
                // The rest of the form fields
                { label: "First Name", name: "first_name", required: true },
                { label: "Last Name", name: "last_name", required: true },
                { label: "License Number", name: "license_no", required: true },
                { label: "Phone Number", name: "phone_number", required: true },
                { label: "Email", name: "email", required: true },
                { label: "Password", name: "password", required: true },
              ].map(({ label, name, required }) => (
                <Form.Group as={Row} controlId={`form${name}`} key={name}>
                  <Form.Label column sm={4}>
                    {label}{" "}
                    {required && <span style={{ color: "red" }}>*</span>}
                  </Form.Label>
                  <Col sm={8}>
                    <div className="mb-3 sh-pass">
                      <Form.Control
                        type={
                          name === "email"
                            ? "email"
                            : name === "password"
                            ? showPassword
                              ? "text"
                              : "password"
                            : "text"
                        }
                        placeholder={`Enter ${label.toLowerCase()}`}
                        {...register(name, {
                          required: required && `${label} is required`,
                        })}
                      />
                      {name === "password" && (
                        <span className="show-pass">
                          {showPassword ? (
                            <img
                              onClick={togglePasswordVisibility}
                              src={eyeClose}
                              title="Hide password"
                              alt="Hide password"
                              className="sign-input-image-close"
                            />
                          ) : (
                            <img
                              onClick={togglePasswordVisibility}
                              src={eyeicon}
                              title="Show password"
                              alt="Show password"
                              className="sign-input-image"
                            />
                          )}
                        </span>
                      )}
                    </div>
                    {errors[name] && (
                      <Form.Text className="text-danger">
                        {errors[name].message}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
              ))}

              <Button
                variant="primary"
                type="submit"
                style={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Form>
          </AdddoctorForm>
        </Modal.Body>
        </div>
      </AddNewDoctor>
    </Modal>
  );
};

export default AddDoctorModal;