import React, { useState, useEffect } from "react";
import TopBar from "../Home/Components/Topbar/TopBar";
import { useNavigate } from "react-router-dom"; 
import UsersTable from "./UsersList";
import ChatBotAdmin from "./AdminChat";
import { jwtDecode } from "jwt-decode";

const AdminPage = () => {
    const [decode, setDecode] = useState({});
    const navigate = useNavigate();
  
    useEffect(() => {
      const token = sessionStorage.getItem("orgtoken"); // Get the token directly from sessionStorage
      if (token) {
        const decoded = jwtDecode(token);
        setDecode(decoded);
        if(decoded.role!=='admin'){
          navigate('/login')
        }
      } else {
        navigate("/login");
      }
    }, [navigate]);
  
    const fetchData = async () => {
      const token = sessionStorage.getItem("orgtoken");
      if (token) {
        const decoded = jwtDecode(token);
        setDecode(decoded);
      } else {
        navigate("/login");
      }
    };
  
    return (
      <div>
        <TopBar showUser={true} />
        <div style={{ marginTop: "100px" }}></div>
        <UsersTable decode={decode} fetchData={fetchData} />
        <ChatBotAdmin />
      </div>
    );
  };
  
  

export default AdminPage;
