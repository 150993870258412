import React, { useContext, useEffect, useState } from "react";
import { TopbarContainer, UserProfile, TopbarRight, SideTopbarContainer } from "./TopbarStyle";
import { Image } from "react-bootstrap";
import logo from "../../../../assets/medscribe/Medscribe-logo-White.png";
import usericons from "../../../../assets/user/AvatarIcon.png";
import { Dropdown } from "react-bootstrap";
import { FaRegUser } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { jwtDecode } from "jwt-decode";
import './topbar.css'
import { Tooltip } from "antd";


function TopBar({ showUser }) {
  const navigate = useNavigate();
  const [persondata, setpersondata] = useState({});
  const [, setToken] = useContext(UserContext);

  useEffect(() => {
    let token = sessionStorage.getItem("orgtoken");
    if (token === null) {
      token = sessionStorage.getItem("orgtoken");
    }

    if (token === null) {
      console.log("null");
      setpersondata({
        name: "",
        email: "",
      });
    } else {
      if (token) {
        console.log("nullnot");
        let data = jwtDecode(token);
        if (data.last_name !== null) {
          setpersondata({
            name: data.first_name + " " + data.last_name,
            email: data.email,
          });
        } else {
          setpersondata({
            name: data.first_name,
            email: data.email,
          });
        }
      }
    }
  }, []);

  const handlelogout = () => {
    console.log("logout");
    navigate("/login");
    sessionStorage.removeItem("orgtoken");
    setToken(null)
  };
  return (
    <>
      <TopbarContainer>
        <div className="logo-container">
          <Image
            src={logo}
            title="DeNotes"
            alt="DeNotes"
            className="denotes-logo"
            width={40}
          />
          {showUser ? (
            <TopbarRight className="!z-50">
              <div className="text-white mr-2">{persondata.email}</div>
              <UserProfile>
                <Dropdown>
                  <Dropdown.Toggle  id="dropdown-basic" size="sm">
                    <Image
                      className="hp-img-right"
                      src={usericons}
                      // roundedCircle
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h5>{persondata.name}</h5>
                      <p>{persondata.email}</p>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {/* <Dropdown.Item className="se-link" as={Link} to={``}>
                      <FaRegUser size={14} />
                      {"Manage Account"}
                    </Dropdown.Item> */}
                    <Dropdown.Item className="se-link" onClick={handlelogout}>
                      <MdLogout size={16} /> {"Logout"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </UserProfile>
            </TopbarRight>
          ) : (
            <div></div>
          )}
        </div>
      </TopbarContainer>
    </>
  );
}

export default TopBar;

export function SideTopbar({ showUser }) {
  const navigate = useNavigate();
  const [persondata, setpersondata] = useState({});
  const [, setToken] = useContext(UserContext);
  const [decode, setDecode] = useState([])

  useEffect(() => {
    let token = sessionStorage.getItem("orgtoken");
    if (token === null) {
      token = sessionStorage.getItem("doctor_token");
    }

    if (token === null) {
      console.log("null");
      setpersondata({
        name: "",
        email: "",
      });
    } else {
      if (token) {
        console.log("nullnot");
        let data = jwtDecode(token);
        if (data.last_name !== null) {
          setpersondata({
            name: data.first_name + " " + data.last_name,
            email: data.email,
          });
        } else {
          setpersondata({
            name: data.first_name,
            email: data.email,
          });
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   const token = sessionStorage.getItem("orgtoken");
  //   if (token) {
  //     const decoded = jwtDecode(token);
  //     console.log("DESoocede", decoded);
  //     setDecode(decoded);
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  // console.log('DOCCC', decode)


  const handlelogout = () => {
    console.log("logout");
    navigate("/login");
    sessionStorage.removeItem("orgtoken");
    setToken(null)
  };
  return (
    <>
      <SideTopbarContainer>
        <div className="logo-container">
          <Image
            src={logo}
            title="Medscribe"
            alt="medscribe"
            className="denotes-logo"
          />
          {showUser ? (
            <TopbarRight>
              <div className="text-white mr-2 hidden sm:block">{persondata.email}</div>
              <UserProfile className="cursor-pointer">
                {/* <Dropdown>
                  <Dropdown.Toggle  id="dropdown-basic" size="sm">
                    <Image
                      className="hp-img-right"
                      src={usericons}
                      // roundedCircle
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <h5>{persondata.name}</h5>
                      <p>{persondata.email}</p>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="se-link" as={Link} to={``}>
                      <FaRegUser size={14} />
                      {"Manage Account"}
                    </Dropdown.Item>
                    <Dropdown.Item className="se-link" onClick={handlelogout}>
                      <MdLogout size={16} /> {"Logout"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Tooltip 
                title={<TopMenu persondata={persondata} handlelogout={handlelogout} overlayStyle={{ backgroundColor: '#2db7f5' }} />}
                >
                  <Image
                      className="hp-img-right"
                      src={usericons}
                      width={40}
                      // roundedCircle
                    />
                  </Tooltip>
              </UserProfile>
            </TopbarRight>
          ) : (
            <div></div>
          )}
        </div>
      </SideTopbarContainer>
    </>
  );
}

const TopMenu = ({ persondata, handlelogout }) => {
  return (
    <div className="p-2">
      {/* <h5>{persondata.name}</h5> */}
      <p>{persondata.email}</p>

      {/* <div className="border-t border-black"></div> */}

      <div className="flex items-center gap-2 cursor-pointer" onClick={handlelogout}>
        <MdLogout size={16} /> 
        <div>Logout</div>
      </div>
    </div>
  )
}