import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import MobileTopBar from "../Home/Components/Topbar/MobileTopBar";
import TopBar from "../Home/Components/Topbar/TopBar";
import "../../App.css";
import {useNavigate } from "react-router-dom";
import { SuperadminContainer } from "./SuperAdminStyle";
import { configJson } from "../../Constent/config";
import { jwtDecode } from "jwt-decode";
import eyeClose from "../../assets/signup/eyeclose.png";
import eyeicon from "../../assets/signup/eye.png";
import { AddorgForm } from "../Login-page/LoginStyle";


const SuperAdminDashboard = () => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [decode,setDecode] =  useState({})
  console.log(decode,"Decode")
  const [organisationData, setOrganisationData] = useState({
    name: "",
    type: "",
    state: "",
    city: "",
    country: "",
    pin_code: "",
    phone_number: "",
    email: "",
    website: "",
    fax_no: "",
    tax_id_no: "",
    address1: "",
    // address2: "",
    description: "",
    // Admin details, optional
    role: "admin",
    first_name: "",
    last_name: "",
    username: "",
    adminemail:"",
    password_hash: ""
  });

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    //set token
    useEffect(() => {
      const token1 = sessionStorage.getItem("Super_admin_token");
      if (token1) {
        const decoded = jwtDecode(token1);
        setDecode(decoded);
      }else{
        navigate("/superadminlogin");
      }
    }, [navigate]);

  const handleShow = () => setShowAddOrgModal(true);
  const handleClose = () => setShowAddOrgModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganisationData({ ...organisationData, [name]: value });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${configJson.backend_URL}/superadmin/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(organisationData),
      });
 
      const data = await response.json();

      if (response.ok) {

        alert("Created Successfully");
        handleClose();
      } else {
        alert(`${data.message}`);
      }
    } catch (error) {
      console.log(error, "data error handle");
      alert("Error:", error);
    }
  };

  return (
    <SuperadminContainer>
      <div className="Dashboardsuperadminclass">
        {containerWidth < 767 ? (
          <MobileTopBar titleName="Super Admin Dashboard" />
        ) : (
          <TopBar showUser={true} />
        )}

        <Container fluid style={{marginTop:'100px'}}>
          <h1 className="text-center mb-4">Super Admin Dashboard</h1>
          <Row className="welcome-text">
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Organization Management</Card.Title>
                  <Card.Text>
                    Manage Organizations, add new Organizations, and view
                    details.
                  </Card.Text>
                  <Button
                    className="reg-sub-btn mr-5"
                    style={{ backgroundColor: '#5a5a5a', color: 'white' }}
                    variant="success"
                    onClick={handleShow}
                  >
                    Add Organization
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => navigate("/organizationlist")}
                  >
                   Edit or View Organization
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Add Specialties</Card.Title>
                  <Card.Text>
                    Manage Specialties, add new Specialties.
                  </Card.Text>
                  <Button className="reg-sub-btn mr-5"
                    style={{ backgroundColor: '#5a5a5a', color: 'white', border:"none" }}
                    onClick={() => navigate("/specialties")}
                  >
                    Add Specialties
                  </Button>
                  <Button
                    variant="primary"
                    style={{ backgroundColor: '##272626', color: 'white' }}
                    onClick={() => navigate("/specialtieslist")}
                  >
            Edit or View Specialties
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Soap Note Prompts</Card.Title>
                  <Card.Text>
                  You can view and edit the SOAP note generated from the patient’s audio 
  recording. After making your changes, submit the updated SOAP note to save it 
  to the system.
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => navigate("/soapnotesdetail")}
                  >
                    Go to Soap Note List
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Add Organization Modal */}
        <Modal show={showAddOrgModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <AddorgForm>
            <Form onSubmit={handleSubmit}>
              {/* Organization Details Section */}
              <h3 className="mt-4">Organization Details</h3>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="name"
                    value={organisationData.name}
                    onChange={handleChange}
                    placeholder="Enter organization name"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Type</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Check
                    type="radio"
                    id="hospital"
                    name="type"
                    value="Hospital"
                    checked={organisationData.type === "Hospital"}
                    onChange={handleChange}
                    label="Hospital"
                    inline
                    required
                  />
                  <Form.Check
                    type="radio"
                    id="gp"
                    name="type"
                    value="General Practitioner"
                    checked={organisationData.type === "General Practitioner"}
                    onChange={handleChange}
                    label="General Practitioner"
                    inline
                    required
                  />
                </Col>
              </Row>

              {/* Address and Contact Details */}

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Address</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="address1"
                    value={organisationData.address1}
                    onChange={handleChange}
                    placeholder="Address Line"
                    required
                  />
                </Col>
              </Row>

              {/* <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Address 2</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="address2"
                    value={organisationData.address2}
                    onChange={handleChange}
                    placeholder="Address Line 2"
                  />
                </Col>
              </Row> */}

           

              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="city"
                    value={organisationData.city}
                    onChange={handleChange}
                    placeholder="City"
                    required
                  />
                </Col>
        
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="state"
                    value={organisationData.state}
                    onChange={handleChange}
                    placeholder="State"
                    required
                  />
                </Col>

          
              </Row>

              <Row className="mt-3">
             
          
              <Col sm={12}>
                  <Form.Control
                    type="text"
                    name="country"
                    value={organisationData.country}
                    onChange={handleChange}
                    placeholder="Country"
                    required
                  />
                </Col>
                </Row>


              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="pin_code"
                    value={organisationData.pin_code}
                    onChange={handleChange}
                    placeholder="Pin Code"
                    required
                  />
                </Col>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={organisationData.phone_number}
                    onChange={handleChange}
                    placeholder="Phone Number"
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="email"
                    value={organisationData.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </Col>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="website"
                    value={organisationData.website}
                    onChange={handleChange}
                    placeholder="Website"
                  />
                </Col>
              </Row>

              {/* Additional Details */}
              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="fax_no"
                    value={organisationData.fax_no}
                    onChange={handleChange}
                    placeholder="Fax No"
                  />
                </Col>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="tax_id_no"
                    value={organisationData.tax_id_no}
                    onChange={handleChange}
                    placeholder="Tax ID No"
                  />
                </Col>
              </Row>

          

              <Row className="mt-3">
                <Col sm={12}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={organisationData.description}
                    onChange={handleChange}
                    placeholder="Description"
                  />
                </Col>
              </Row>

              {/* Admin Details Section */}
              <h3 className="mt-4">Admin Details</h3>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={organisationData.first_name}
                    onChange={handleChange}
                    placeholder="Enter first name"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={organisationData.last_name}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Username</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="username"
                    value={organisationData.username}
                    onChange={handleChange}
                    placeholder="Enter username"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    name="adminemail"
                    value={organisationData.adminemail}
                    onChange={handleChange}
                    placeholder="Enter email"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col sm={8} className="mb-3 sh-pass">
                  <Form.Control
                    name="password_hash"
                    value={organisationData.password_hash}
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    placeholder="Enter password"
                    required
                  />
                    <span className="show-pass">
                  {showPassword ? (
                    <img
                      onClick={togglePasswordVisibility}
                      src={eyeClose}
                      title=""
                      alt=""
                      className="sign-input-image-close"
                    />
                  ) : (
                    <img
                      onClick={togglePasswordVisibility}
                      src={eyeicon}
                      title=""
                      alt=""
                      className="sign-input-image"
                    />
                  )}
                </span>
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-4 w-100">
                Submit
              </Button>
            </Form>
            </AddorgForm>
          </Modal.Body>
        </Modal>
      </div>
    </SuperadminContainer>
  );
};

export default SuperAdminDashboard;
