import styled from "styled-components";

export const RegWrapper = styled.div`
  height: 100vh;
  .register-bg {
    padding: 32px 40px;
    @media (max-width: 767px) {
      padding: 20px 0px;
    }
  }
  .signup-content {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  @media (max-width: 767px) {
    .sg-container {
      flex-direction: column-reverse;
    }
  }
`;
export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 4px 20px;
  flex-direction: column;
  border-bottom: 1px solid #e3e4f3;
  > img {
    width: 115px;
  }
  h4 {
    color: #69707d;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
  }
`;
export const RegSection = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: #fff;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 0 rgba(50, 50, 93, 0.25), 0 1px 4px rgba(0, 0, 0, 0.16);
`;

export const SignupContent = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    color: #cbcdde;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  #signInDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 89px;
    height: 38px;
    overflow: hidden;
    // background-color: #e4e4e4;
    #container > div:nth-child(2) {
      background-color: #e4e4e4;
    }
  }

  .g-signin {
    display: flex;
    align-items: center;
    justify-content: center;

    // .google-icon button {
    //   border-radius: 50%;
    //   width: 48px;
    //   height: 48px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   overflow: hidden;
    // }

    // .google-icon button img {
    //   width: 24px;
    //   height: 24px;
    // }
  }
`;
export const SignupForm = styled.div`
  padding: 20px;
  .or-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: #69707d;
    font-size: 13px;
  }
  .divide-line {
    flex-basis: auto;
    flex-grow: 1;
  }
  .custom-form {
    padding-top: 10px;
    .form-control {
      padding: 0.45rem 0.75rem;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
        sans-serif;
      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
  .reg-lbl {
    font-size: 14px;
    font-weight: 600;
    color: #1c2127;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
  }
  .reg-phone {
    font-size: 15px;
    box-shadow: none;
    border-radius: 6px !important;
    padding: 6px 16px;
    height: 38px;
    border: 1px solid #dee2e6;
  }
  .er-message {
    color: #d8000c;
    font-size: 13px;
  }
  .admin-reg-btn {
    color: #fff;
    padding: 7px 20px;
    font-size: 18px;
    background-color: #0b8cc6;
    /* font-weight: 500; */
    border-radius: 4px;
    margin-bottom: 15px;
    width: 100%;
    border: none;
  }
  .admin-reg-btn:hover {
    background-color: #337ab7;
  }
  .login-link {
    text-align: center;
    color: #6b7280;
    font-size: 14px;
    a {
      text-decoration: none;
      color: #264cd6;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 7px;
      right: 12px;
      cursor: pointer;
    }
  }
`;

export const TimeCounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  margin-bottom: 10px;
  .time-cards {
    border: 1px solid black;
    padding: 0px 10px;
    border-radius: 10px;
    background-color: #fff5f5;
    .time-font {
      font-size: 40px;
    }
  }
`;
