import React, { useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { FaMicrophone, FaStop } from "react-icons/fa6";
import MessageModel from "../Engine-Page/mainEngine/Components/MessageModel";
import { useStopwatch } from "react-timer-hook";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RecordContainer } from "./ActivityStyle";
const Mp3Recorder = new MicRecorder({ bitRate: 129 });

function RecordingScreen() {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  // const [recordtime, setRecordtime] = useState(0);
  // const [recordtimeminutes, setRecordtimeminutes] = useState(0);
  const [date, setDate] = useState(null);
  const [interaction, setInteraction] = useState({});
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });
  const navigate = useNavigate();
  //mic-recorder-to-mp3
  //start Audio recording
  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
        // setRecordtime(0);
        // setRecordtimeminutes(0);
        start();
      })
      .catch((e) => console.error(e));
  };

  //stop Audio recording
  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobUrl(blobURL);
        setIsRecording(false);
        // setRecordtime(seconds);
        // setRecordtimeminutes(minutes);
        handleSubmit(seconds, minutes, blobURL);
        reset();
        pause();
      })
      .catch((e) => console.log(e));
  };
  //decode token get data
  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);
  //set Today Date
  useEffect(() => {
    const timestamp = Date.now();
    const now = new Date(timestamp);
    const formattedDate = now.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setDate(formattedDate);
    setInteraction({
      interaction_id: 642,
      patient_id: "282aaf4d",
      doctor_id: 8,
      interaction_date: "2024-11-06T08:54:00.000Z",
      interaction_type: null,
      interaction_status: "99",
      interaction_notes: null,
      location: null,
      created_at: "2024-11-06T08:54:56.000Z",
      updated_at: "2024-11-06T08:54:56.000Z",
      Patient: {
        patient_id: "282aaf4d",
        first_name: "front",
        last_name: "office",
        date_of_birth: "2024-11-03",
        language: "ta-IN",
        hospital_patient_id: "84634",
        phone_number: "7896541230",
      },
      Doctor: {
        doctor_id: 8,
        first_name: "cdot",
        last_name: "siva",
      },
    });
  }, []);

  //   console.log(state.interaction_id);
  //   async function updatedata(url) {
  //     const mydata = {
  //       interaction_id: interaction.interaction_id,
  //       interaction_status: 0,
  //       interaction_notes: "soap",
  //       interaction_date: date,
  //       interaction_detail_type: "Audio url",
  //       audioUrl: url,
  //     };
  //     try {
  //       const response = await axios.put(
  //         `${configJson.backend_URL}/interaction/updatedata`,
  //         mydata,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.data.status === true) {
  //         console.log("Audio upload success full");
  //         navigate("/token");
  //       } else {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }

  //Api Call for Audio upload backend
  const handleSubmit = async (seconds, minutes, blobUrl) => {
    //   e.preventDefault();
    setIsLoading(true);

    if (!blobUrl) {
      setMessage("Please record audio or upload a file.");
      setShowModal(true);
      setIsLoading(false);
      return;
    }

    if (minutes <= 0 && seconds <= 25) {
      setMessage("Minimum recording is 25 seconds");
      setShowModal(true);
      setIsLoading(false);
      reset();
      pause();
      return;
    }

    const audioBlob = await fetch(blobUrl).then((res) => res.blob());

    let result = date.replace(/[-:]/g, "_").replace(", ", "_");

    const formData2 = new FormData();
    formData2.append(
      "file_name",
      `audio_files/${interaction.Patient.patient_id}_${result}.mp3`
    );
    formData2.append(
      "audio_file",
      audioBlob,
      `${interaction.Patient.patient_id}.mp3`
    );
    console.log(formData2);
    //   try {
    //     // const response = await fetch("https://back.denotes.app/upload_audio", {
    //     //check connectivity
    //     const response = await fetch(
    //       `${configJson.backend_URL}/soapPrompt/upload`,
    //       {
    //         method: "POST",
    //         body: formData2,
    //       }
    //     );

    //     if (response.ok) {
    //       const result = await response.json();
    //       console.log("Audio stored in bucket");
    //       const url = result.gcs_uri;
    //       await updatedata(url);
    //     } else {
    //       let errorMessage = "Failed to submit form data.";
    //       if (response.status === 400) {
    //         errorMessage = "Bad request. Please check your inputs.";
    //       } else if (response.status === 404) {
    //         errorMessage = "API endpoint not found.";
    //       }
    //       alert(errorMessage);
    //     }
    //   } catch (error) {
    //     console.error("Error:", error);
    //     alert(`An error occurred while submitting the form.${error}`);
    //   } finally {
    //     setIsLoading(true);
    //   }
  };

  //show or close model
  const setShowModals = () => {
    setShowModal(false);
  };
  //close Model 2s
  // useEffect(() => {
  //   if (showModal === true) {
  //     setTimeout(() => {
  //       setShowModal(false);
  //     }, 2000);
  //   }
  // }, [showModal]);
  return (
    <RecordContainer>
      <div className="record-group-button">
        <Button
          type="button"
          className="start-stop-btn"
          onClick={isRecording ? stopRecording : startRecording}
        >
          <div className="button-content">
            <span className="rec-btn-icon">
              {isRecording ? <FaStop size={15} /> : <FaMicrophone size={15} />}
            </span>
          </div>
        </Button>
        <div className="mb-1 audio">
          <audio src={blobUrl} controls className="w-100" />
        </div>
        <MessageModel
          showModal={showModal}
          messageData={message}
          setShowModal={setShowModals}
        />
      </div>
    </RecordContainer>
  );
}

export default RecordingScreen;
