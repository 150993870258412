import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
import MobileTopBar from "../Home/Components/Topbar/MobileTopBar";
import TopBar from "../Home/Components/Topbar/TopBar";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { SuperadminContainer } from "../Super-admin/SuperAdminStyle";
import { configJson } from "../../Constent/config";

const AddPatient = () => {
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [showPatientListModal, setShowPatientListModal] = useState(false);
  const [patientData, setPatientData] = useState({
    hospital_patient_id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    insurance_provider: "",
    insurance_policy_number: "",
    billing_address: "",
    emergency_contact: "",
    emergency_phone: "",
  });
  const [patientList, setPatientList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleShowAddPatient = () => setShowAddPatientModal(true);
  const handleCloseAddPatient = () => setShowAddPatientModal(false);
  const handleShowPatientList = () => {
    fetchPatients();
    setShowPatientListModal(true);
  };
  const handleClosePatientList = () => setShowPatientListModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatientData({ ...patientData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${configJson.backend_URL}/patients/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(patientData),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Success:", data.message);
        alert("Patient Added Successfully");
        handleCloseAddPatient();
      } else {
        alert(`${data.message}`);
      }
    } catch (error) {
      console.log(error);
      alert("Error:", error);
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await fetch("/patients");
      const data = await response.json();
      if (response.ok) {
        setPatientList(data.patients);
      } else {
        alert("Error fetching patients");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <SuperadminContainer>
      <div className="Dashboardsuperadminclass">
        {containerWidth < 767 ? (
          <MobileTopBar titleName="Super Admin Dashboard" />
        ) : (
          <TopBar showUser={true} />
        )}

        <Container fluid className="mt-4">
          <h1 className="text-center mb-4">Super Admin Dashboard</h1>
          <Row className="welcome-text">
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Patient Management</Card.Title>
                  <Card.Text>
                    Add new patients and manage existing patient records.
                  </Card.Text>
                  <Button
                    className="reg-sub-btn"
                    variant="success"
                    onClick={handleShowAddPatient}
                  >
                    Add Patient
                  </Button>{" "}
                  <Button
                    className="reg-sub-btn"
                    variant="info"
                    onClick={handleShowPatientList}
                  >
                    Show Patient List
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Add Patient Modal */}
        <Modal show={showAddPatientModal} onHide={handleCloseAddPatient} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <h3 className="mt-4">Patient Details</h3>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Hospital Patient ID</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="hospital_patient_id"
                    value={patientData.hospital_patient_id}
                    onChange={handleChange}
                    placeholder="Enter hospital patient ID"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>First Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={patientData.first_name}
                    onChange={handleChange}
                    placeholder="Enter first name"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Last Name</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={patientData.last_name}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Date of Birth</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    name="date_of_birth"
                    value={patientData.date_of_birth}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Insurance Provider</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="insurance_provider"
                    value={patientData.insurance_provider}
                    onChange={handleChange}
                    placeholder="Enter insurance provider"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Insurance Policy Number</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="insurance_policy_number"
                    value={patientData.insurance_policy_number}
                    onChange={handleChange}
                    placeholder="Enter policy number"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Billing Address</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="billing_address"
                    value={patientData.billing_address}
                    onChange={handleChange}
                    placeholder="Enter billing address"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Emergency Contact</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="emergency_contact"
                    value={patientData.emergency_contact}
                    onChange={handleChange}
                    placeholder="Enter emergency contact"
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  <Form.Label>Emergency Phone</Form.Label>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="emergency_phone"
                    value={patientData.emergency_phone}
                    onChange={handleChange}
                    placeholder="Enter emergency phone"
                    required
                  />
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-4 w-100">
                Add Patient
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Patient List Modal */}
        <Modal show={showPatientListModal} onHide={handleClosePatientList} centered>
          <Modal.Header closeButton>
            <Modal.Title>Patient List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {patientList.length > 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Hospital Patient ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>Insurance Provider</th>
                    <th>Insurance Policy Number</th>
                    <th>Billing Address</th>
                    <th>Emergency Contact</th>
                    <th>Emergency Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.map((patient) => (
                    <tr key={patient.hospital_patient_id}>
                      <td>{patient.hospital_patient_id}</td>
                      <td>{patient.first_name}</td>
                      <td>{patient.last_name}</td>
                      <td>{patient.date_of_birth}</td>
                      <td>{patient.insurance_provider}</td>
                      <td>{patient.insurance_policy_number}</td>
                      <td>{patient.billing_address}</td>
                      <td>{patient.emergency_contact}</td>
                      <td>{patient.emergency_phone}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No patients found</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </SuperadminContainer>
  );
};

export default AddPatient;
