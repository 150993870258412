import styled from "styled-components";

export const PageContainer = styled.div`
  min-height: 100vh; /* Set the minimum height to at least 100% of the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AdminSection = styled.div`
  width: 100%;
  align-items: center;
  padding: 32px 40px;
  @media (max-width: 767px) {
    padding: 32px 0px;
  }
`;

export const MerLoginLogo = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e3e4f3;
  h4 {
    color: #69707d;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
  }
  .merchant-logo {
    width: 110px;
  }
`;

export const MerchantLogin = styled.div`
  max-width: 450px;
  width: 100%;
  background-color: #fff;
  margin: auto;
  box-shadow: 0 0 0 0 rgba(50, 50, 93, 0.25), 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  .merchant-form {
    padding: 0.45rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .merchant-lbl {
    font-size: 14px;
    font-weight: 600;
    color: #1c2127;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16,
      sans-serif;
  }
  .merchant-lg-button {
    color: #fff;
    font-size: 16px;
    padding: 8px 16px;
    background-color: #0b8cc6;
    /* font-weight: 500; */
    border-radius: 4px;
    width: 100%;
    border: none;
    &:hover {
      background-color: #337ab7;
    }
  }
  .divider {
    --tw-bg-opacity: 1;
    background-color: rgba(214, 214, 214, var(--tw-bg-opacity));
    height: 1px;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  .gbtn-wth {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eye-icon {
    cursor: pointer;
  }
  .login-eye {
    min-width: 50px;
    justify-content: center;
    background-color: transparent;
  }
  .pass-input {
    border-right: 0;
  }
  .forgot-pass {
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    &:hover {
      color: #0d6efd;
    }
  }
  .forgot-btn {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .login-arrow {
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #0d6efd;
    }
  }
  .custom-login-form {
    padding: 16px 24px;
    @media (max-width: 767px) {
      padding: 16px 20px;
    }
    .header-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
    }
    .sm-login {
      padding-top: 20px;
    }
    .or-divider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      color: #69707d;
      font-size: 13px;
      padding-top: 15px;
      padding-bottom: 10px;
    }
    .divide-line {
      flex-basis: auto;
      flex-grow: 1;
    }
    .fr-pass {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4;
      color: #69707d !important;
      padding-bottom: 8px;
      a {
        color: #264cd6 !important;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .signup-link {
    text-align: center;
    color: #6b7280;
    font-size: 14px;
    padding: 20px 0;
    a {
      text-decoration: none;
      color: #264cd6;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .forgot-section {
    padding: 24px 32px;
    .forgot-header {
      background-color: #d9e2ff;
      color: #264cd6;
      padding: 10px;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
    .error-msg {
      background-color: #fae9eb;
      color: #2c313a;
      padding: 16px;
      margin-bottom: 20px;
      h5 {
        color: #c41535;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      p {
        color: #2c313a;
        font-size: 14px;
        line-height: 1.4;
      }
    }
    .info-msg {
      background-color: #dfffea;
      color: #2c313a;
      padding: 16px;
      margin-bottom: 20px;
      h5 {
        color: #17c653;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      p {
        color: #2c313a;
        font-size: 14px;
        line-height: 1.4;
      }
    }
    .fr-ins {
      color: #69707d;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4;
      padding-bottom: 8px;
    }
  }
  .logn-link {
    text-align: center;
    color: #6b7280;
    font-size: 14px;
    a {
      text-decoration: none;
      color: #264cd6;
      margin-left: 4px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export const Ericon = styled.div`
  .err-icon {
    font-size: 20px;
    margin-bottom: 10px;
    color: red;
  }
`;
export const MerchantCopyright = styled.div`
  text-align: center;
  color: #4b5675;
  font-size: 12px;
  font-family: Montserrat;
  padding-top: 20px;
  .merchant-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #4b5675;
      text-decoration: none;
      &:hover {
        color: #0b8cc6;
      }
    }
  }
`;

export const MsLogin = styled.div`
  /* display: inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  .ms-btn {
    padding: 8px 10px;
    border: 1px solid #dadce0;
    color: #3c4043;
    text-decoration: none;
    max-width: 400px;
    font-weight: 500;
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: 767px) {
      max-width: 290px;
    }
    svg {
      margin-right: 8px;
    }
  }
`;

export const MsLoginbtn = styled.div`
  /* display: inline-block; */
  display: flex;
  align-items: center;
  justify-content: center;
  .ms-btn {
    padding: 10px 10px;
    // border: 1px solid #dadce0;
     border: 1px solid black;
    color: #3c4043;
    text-decoration: none;
    max-width: 400px;
    font-weight: 500;
    // width: 100%;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    // background-color: #e4e4e4;
    @media (max-width: 767px) {
      max-width: 290px;
    }
    svg {
      // margin-right: 8px;
    }
  }
`;

export const DoctorlogContainer = styled.div`
  .header-text {
    text-align: center;
    .header-text-part {
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
`;
export const DoctorForm = styled.div`
  .form-control {
    padding-left: 46px;
    margin-bottom: -10px;
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 36px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
  .input-img-container {
    position: relative;
    bottom: 24px;
    left: 8px;
    .sign-input-image {
      width: 15px;
      height: 15px;
    }
    .sign-input-or {
      padding: 5px;
    }
  }
  .doc-log-sub {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
  }
`;

export const OrganizationContainer = styled.div`
  .top-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    .top-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
    }
    .top-text {
      text-align: right;
      .text-line {
        margin-right: 106px;
      }
    }
  }

  .header-text {
    text-align: left;
    .header-text-part {
      color: black;
    }
    .reset-email {
      .reset-email-text {
        font-weight: 700;
      }
    }
  }
  .reset-button {
    color: blue;
    text-decoration: underline;
    border: none;
  }
`;
export const OrganizationForm = styled.div`
  .form-control {
    height: 50px;
    border: 1px solid black;
  }
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 48px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }

  .org-log-sub {
    color: #fff;
    background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
    padding: 5px 20px 5px 20px;
    border: none;
    width: 100%;
    height: 50px;
  }
  .or-division {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  .form-check {
    margin-top: 10px;
  }
  .form-check-input {
    background-color: #5b5b5bc2;
  }
  .user-email {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 5px;
    margin-bottom: 50px;
    .email-text {
      padding: 0px;
      margin: 0px;
    }
  }
  .back-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .back-button {
      color: #fff;
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      padding: 5px 20px 5px 20px;
      border: none;
    }
  }
`;

export const AdddoctorForm = styled.div`
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 5px;
      right: 12px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
`;

export const ForgotContainer = styled.div`
  .header-text {
    text-align: center;
    .logo-top {
      width: 250px;
      height: 100px;
    }
    .header-text-part {
      background-image: linear-gradient(to right, #5b5b5b, #1c1b1b);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
`;
export const AddorgForm = styled.div`
  .sh-pass {
    position: relative;
    .show-pass {
      position: absolute;
      top: 11px;
      right: 22px;
      cursor: pointer;
      .sign-input-image {
        width: 18px;
        height: 15px;
      }
      .sign-input-image-close {
        width: 21px;
        height: 18px;
      }
    }
  }
`;
