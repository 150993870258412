import React, { useState, useEffect } from "react";
import { Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
import { jwtDecode } from "jwt-decode"; // Fix the import here
import EditPatientModal from "./Components/EditPatientModal"; // Import the modal
import editicon from "../../assets/table/edit.png";
import Sidebar from "../Home/Components/Sidebar/Sidebar";

const PatientListedit = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]); // State for filtered patients
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const navigate = useNavigate();

  // Fetch patients by organization_id
  const fetchPatients = async (organization_id) => {
    try {
      const response = await fetch(`${configJson.backend_URL}/patient/listbyorg/${organization_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`, // Include the token for authorization
        },
      });
      if (!response.ok) throw new Error("Failed to fetch patients");

      const data = await response.json();
      setPatients(data); // Assuming data contains the list of patients
      setFilteredPatients(data); // Initialize filtered patients
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  // Set token and fetch patients using organization_id
  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      fetchPatients(decoded.organization_id); // Fetch patients based on decoded org_id
    } else {
      navigate("/login"); // Navigate to login if token is not present
    }
  }, [navigate]);

  const handleEdit = (patient) => {
    setSelectedPatient(patient);
    setShowEditModal(true); // Open the modal for editing
  };

  const updatePatient = async (patientData) => {
    try {
      const response = await fetch(`${configJson.backend_URL}/patient/updatepatientlist/${patientData.patient_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("orgtoken")}`,
        },
        body: JSON.stringify(patientData),
      });

      if (!response.ok) throw new Error("Failed to update patient");

      const updatedPatients = patients.map((p) =>
        p.patient_id === patientData.patient_id ? patientData : p
      );
      setPatients(updatedPatients);
      setFilteredPatients(updatedPatients); // Update filtered patients as well
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };


 // Handle search input change
const handleSearch = (e) => {
  const { value } = e.target;
  setSearchTerm(value);

  // If searchTerm is empty, reset filteredPatients to all patients
  if (value === "") {
      setFilteredPatients(patients);
      return;
  }

  // Filter patients based on search input
  const lowerCaseValue = value.toLowerCase();
  const filtered = patients.filter((patient) => {
      return (
          (patient.hospital_patient_id && patient.hospital_patient_id.toString().toLowerCase().includes(lowerCaseValue)) || // Filter by Hospital Patient ID
          (patient.first_name && patient.first_name.toLowerCase().includes(lowerCaseValue)) || // Filter by First Name
          (patient.last_name && patient.last_name.toLowerCase().includes(lowerCaseValue)) || // Filter by Last Name
          (patient.phone_number && patient.phone_number.toLowerCase().includes(lowerCaseValue)) || // Filter by Phone Number
          (patient.email && patient.email.toLowerCase().includes(lowerCaseValue)) // Filter by Email
      );
  });

  setFilteredPatients(filtered); // Update the state with filtered patients

  // Debugging: Log the filtered result
  console.log("Search Term:", lowerCaseValue, "Filtered Patients:", filtered);
};


  return (
    <Sidebar setuser={true}>
      <div className="container mt-4">
        <h2>Patient List</h2>
        <p>Total Patients: {patients.length}</p> {/* Display total patient count */}

        {/* Search Input */}
        <Form.Group controlId="formSearch" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by name, phone number, or email"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form.Group>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Serial No</th> {/* Added Serial No header */}
              <th>Hospital Patient ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient, index) => (
              <tr key={index}>
                <td>{index + 1}</td> {/* Display serial number */}
                <td>{patient.hospital_patient_id}</td>
                <td>{patient.first_name}</td>
                <td>{patient.last_name}</td>
                <td>{patient.phone_number}</td>
                <td>{patient.email}</td>
                <td>
                  <button className="edit-btn" onClick={() => handleEdit(patient)}>
                    <img
                      style={{
                        width: '18px',
                        height: '18px',
                        filter: 'invert(0%) grayscale(100%) brightness(0%)', // Change color to black
                      }}
                      className="edit-btn-image"
                      src={editicon}
                      alt="Edit Patient Details"
                      title="Edit Patient Details"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {showEditModal && (
          <EditPatientModal
            show={showEditModal}
            handleClose={() => setShowEditModal(false)}
            patientData={selectedPatient}
            updatePatient={updatePatient} // Pass the update function
          />
        )}
      </div>
    </Sidebar>
  );
};

export default PatientListedit;
