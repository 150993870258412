import React from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextColorText } from "../Doctorstyle";

const AssessmentModal = ({ show, handleClose, assessment, headingName }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{
        position: "fixed",
        top: "80%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none", 
        zIndex: 1050,
      }}
      backdrop={false}
      keyboard={false}
    >
      <TextColorText>{headingName}</TextColorText>
      <Modal.Body>{assessment}</Modal.Body>
    </Modal>
  );
};

export default AssessmentModal;
