import React from "react";
import styled from "styled-components";
const FmatMsg = styled.pre`
  margin-bottom: 0;
  font-family: "Helvetica Neue", sans-serif !important;
  a {
    color: ${(props) => props.theme.linkColor};
    &:hover {
      color: ${(props) => props.theme.linkHoverColor};
    }
  }
  img {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
`;

const FormatMessage = ({ text, message }) => {
  // Regular expressions to match formatting symbols
  const boldRegex = /\*\*(.*?)\*\*/g;
  const italicRegex = /_(.*?)_/g;
  const strikethroughRegex = /~(.*?)~/g;
  const monospaceRegex = /```(.*?)```/g;
  const codeBlockRegex = /```(.*?)```/g;
  const keyword = /``(.*?)``/g;
  const capitalRegex = /(?<!style=['"].*?)([A-Z\d.*?]{2,})(?!<\/style>)/g;
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const phonePattern = /(?:[\(\+]?\d{1,4}(?:\))?)[ \d+-]{8,20}\b/g;
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const imageFileRegex = /\.(png|jpg|jpeg|gif|bmp|svg?g)$/i;
  const wwwPattern = /(www\.[^\s]+)/i;
  const blobUrlPattern = /blob:(https?:\/\/\S+)/g;
  //   const capitalRegex = />(.*?)>/g;
  let formattedUrl;

  // Apply formatting
  let formattedText = text
    .replace(blobUrlPattern, (_, url) => {
      formattedUrl.push(url);
      return "";
      // return `<audio src=${_}  class="audioplay" controls></audio>`;
    })
    .replace(urlPattern, (_, url) =>
      url.match(imageFileRegex)
        ? `<div><img src='${url}' alt='Image'/></div>`
        : `<a style='text-decoration: none' href=${url} target='_blank'> ${url} </a>`
    )
    .replace(
      phonePattern,
      (match) =>
        `<a style='font-weight:bold;font-size:14px' href=tel:${match.replace(
          /[\s\+\(\)-]/g,
          ""
        )} target='_blank'>${match}</a>`
    )
    .replace(
      emailPattern,
      "<a style='font-weight:bold;font-size:14px' href=mailto:$& target='_blank'>$&</a>"
    )
    .replace(
      wwwPattern,
      (match) => `<a href="https://${match}" target="_blank">${match}</a>`
    );
  // .replace(boldRegex, "<strong style='color: rgb(246, 112, 199)'>$1</strong>")
  // .replace(keyword, "<strong style='color: #fff'>$1</strong>")
  // // .replace(italicRegex, "<em>$1</em>")
  // .replace(strikethroughRegex, "<s>$1</s>")
  // .replace(monospaceRegex, "<code>$1</code>")
  // .replace(codeBlockRegex, "<pre><code>$1</code></pre>")
  // .replace(
  //   capitalRegex,
  //   "<strong style='color: rgb(246, 112, 199)'>$1</strong>"
  // );
  // if (message.source) {
  //   let source = message.source.replace(urlPattern, (_, url) => {
  //     return `<a style='text-decoration: none' href=${url} target='_blank'>${url} </a>`;
  //   });
  //   formattedText += `<div style="margin-top: 5px ;">Source: <span>${source}</span></div>`;
  // }
  // if (message.audio_link) {
  //   formattedText += `<audio src=${message.audio_link}  class="audioplay" controls></audio>`;
  // }
  if (message.map_link) {
    formattedText += `<p><a style='font-weight:bold;font-size:14px' href='${message.map_link}' target='_blank'>View on Map</a></p>`;
  }
  if (message.chatLoader) {
    formattedText = `<div class="content-loader" style="margin-top:5px;"></div>`;
  }
  if (message.image_url) {
    formattedText += `<div><img src='${message.image_url}' alt='Image'/></div>`;
  }
  return (
    <FmatMsg
      className="bot-msg"
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  );
};
export default FormatMessage;
