import styled from "styled-components";

export const ConsentContainer = styled.div`
  .header-text-part {
    text-align: center;
    .header-text {
      background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-size: 30px;
    }
  }
  .qr-image-card {
    width: 100%;
    text-align: center;
    .qr-image {
      width: 350px;
      height: 350px;
      padding: 10px;
    }
  }
  .btn-log {
    text-align: center;
    .reg-sub-btn {
      color: #fff;
      background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
      padding: 5px 20px 5px 20px;
    }
  }
  .okbtn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .reg-sub-btn {
      color: #fff;
      background-image: linear-gradient(to right, #5B5B5B, #1C1B1B);
      padding: 5px 20px 5px 20px;
    }
  }
`;
