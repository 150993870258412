import React from "react";
import { Buttonstyle } from "./SidebarStyle";


function SidebarButton({ image, names, active,handleNavigate}) {

  return (
    <Buttonstyle>
      <button type="button" className={active ? "side-btn active" : "side-btn"} onClick={handleNavigate}>
        <span>
          <img src={image} title="" alt="" className="btn-image" />
        </span>{" "}
        {names}
      </button>
    </Buttonstyle>
  );
}

export default SidebarButton;
