import React from "react";
import { MobileTop, MobileTopforgot } from "./TopbarStyle";

function MobileTopBar({titleName}) {
  return (
    <MobileTop>
      <p className="heading-top-text">{titleName}</p>
    </MobileTop>
  );
}

export default MobileTopBar;

export function MobileTopBarfogot({titleName}) {
  return (
    <MobileTopforgot>
      <p className="heading-top-text">{titleName}</p>
    </MobileTopforgot>
  );
}

