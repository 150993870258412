import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { PrintContainer } from "../MainEngineStyle";
// import ReactToPrint from "react-to-print";
import ReactMarkdown from "react-markdown";
// import { MdLocalPrintshop } from "react-icons/md";

const InteractionDetailModal = ({
  show,
  handleClose,
  interactionDetail,
  url,
  formData,
  date,
  doctor
}) => {
  const componentRefrawtext = useRef();
  const handlePrint = () => {
    const contentToPrint = componentRefrawtext;
    // console.log(contentToPrint.current);
    // Create a hidden iframe element
    const printFrame = document.createElement("iframe");
    printFrame.style.display = "none";
    document.body.appendChild(printFrame);

    const originalTitle = document.title;
    document.title = ""; // Set blank title during print
    // Set the iframe content to the content to be printed
    const doc = printFrame.contentDocument;
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            @page {
              margin: 0; /* Remove default margins for page */
            }
            body {
              margin: 1cm; /* Set custom margin for printed content */
            }
          </style>
        </head>
        <body>
          ${contentToPrint.current.innerHTML}
        </body>
      </html>
    `);
    doc.close();

    // Trigger the print dialog using the iframe
    printFrame.contentWindow.print();
    document.title = originalTitle;
    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Interaction Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <audio controls className="w-full">
          <source src={url.processedFileContent} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        {interactionDetail ? (
          <div>
            <p>
              {" "}
              <ReactMarkdown>{interactionDetail}</ReactMarkdown>
            </p>
          </div>
        ) : (
          <p>No detail available.</p>
        )}
      </Modal.Body>
      <PrintContainer ref={componentRefrawtext} className="print-content">
        <div>
          <p>
            <span>Patient Name : </span>
            {formData.patientFirstName?.charAt(0).toUpperCase() +
              formData.patientFirstName?.slice(1).toLowerCase() +
              " " +
              formData.patientLastName?.charAt(0).toUpperCase() +
              formData.patientLastName?.slice(1).toLowerCase()}
          </p>
          <p>
            <span>Doctor Name :</span>
            <span>
              {doctor.first_name.charAt(0).toUpperCase() +
                doctor.first_name.slice(1).toLowerCase() +
                " " +
                doctor.last_name.charAt(0).toUpperCase() +
                doctor.last_name.slice(1).toLowerCase()}
            </span>
          </p>
          <p>
            <span>Date : </span> <span>{date}</span>
          </p>
          <h3>Speech To Text</h3>
          <p>
            <ReactMarkdown>{interactionDetail}</ReactMarkdown>
          </p>
        </div>
        {/* <p>{interactionDetail}</p> */}
      </PrintContainer>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => handlePrint()}
        >
          Print
        </button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InteractionDetailModal;
