import React from "react";
import ReactMarkdown from "react-markdown";
import { DateTime } from "luxon";


const imgRef = React.createRef()

export const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric", hour: 'numeric', minute: 'numeric', hour12: true,}; // Format: 24 Apr, 2023
    return new Date(dateString).toLocaleDateString("en-US", options); // Use 'en-GB' for this format
  };

  
  export const renderMedia = (url, openFullScreen) => {
    if (!url) {
      return;
    }
  
    const extension = url.split(".").pop().toLowerCase();
  
    if (extension === "mp3" || extension === "wav") {
      return (
        <audio controls className="w-full !relative !z-[1]">
          <source src={url} type={`audio/${extension}`} />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return (
        <img src={url} alt="" width={200} className="m-auto" onClick={openFullScreen} />
      );
    } else if (extension === "mp4" || extension === "webm" || extension === "ogg") {
      return (
        <video controls className="w-full !relative !z-[1]">
          <source src={url} type={`video/${extension}`} />
          Your browser does not support the video element.
        </video>
      );
    } else if (extension === "txt") {
      return (
        <p>
          <ReactMarkdown>{url}</ReactMarkdown>
        </p>
      );
    } else {
      return (
        <p>
          <ReactMarkdown>{url}</ReactMarkdown>
        </p>
      );
    }
  };
  
  const timeZone=sessionStorage.getItem("timeZone")

export function convertToKolkataTime(utcDateTime) {
    return DateTime.fromISO(utcDateTime, { zone: 'utc' })
        .setZone(timeZone)
        .toFormat('yyyy-MM-dd, HH:mm:ss');
}


export function convertToUTC() {
  // Get the current date and time in Asia/Kolkata
  const kolkataDateTime = DateTime.now().setZone(timeZone);

  // Convert to UTC and return in ISO format
  return kolkataDateTime.setZone("utc").toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function convertToKolkataTimeFormat(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: 'utc' })
      .setZone(timeZone)
      .toFormat('dd-MM-yyyy, HH:mm');
}
export function convertToKolkataTimeDOB(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: 'utc' })
      .setZone(timeZone)
      .toFormat('dd-MM-yyyy');
}
export function searchDateField(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: 'utc' })
      .setZone(timeZone)
      .toFormat('dd-MM-yyyy');
}