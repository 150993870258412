import { React, useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { ChatImage, MarkdownWrapper } from "../../../ThemeStyle/ChatbotStyle";
import { Popover } from "antd";
import { Image, ListGroup, Col } from "react-bootstrap";

const MarkdownDisplay = ({ markdownContent, message }) => {
  let enhancedContent = markdownContent;

  if (enhancedContent === "|") {
    enhancedContent += `<div><table>test</table></div>`;
  }

  // if (message.audio_link) {
  //   enhancedContent += `<div><audio src="${message.audio_link}" class='audioplay' controls></audio></div>`;
  // }
  if (message && message.map_link) {
    enhancedContent += `<p><a style='font-weight:bold;font-size:14px' href='${message.map_link}' target='_blank'>View on Map</a></p>`;
  }

  if (enhancedContent?.endsWith(".jpg")) {
    return (
      <div className="bot-image-container">
        <MediaImage image_url={enhancedContent} />
      </div>
    );
  }

  function MediaImage({ image_url }) {
    const mdImage = (
      <a href={image_url} targeMarkdownDisplayt="_blank">
        <Image src={image_url} className="pop-pr-img" alt="dummy" />
      </a>
    );
    return (
      <>
        <ChatImage>
          <Popover
            placement={window.innerWidth > 768 ? "right" : "bottom"}
            content={mdImage}
            title={"Input Image"}
          >
            <Image src={image_url} className="product-img" alt="dummy" />
          </Popover>
        </ChatImage>
      </>
    );
  }
  const components = {
    h1: "h2",
    table: (props) => (
      <div className="ai-table">
        <table {...props} />
      </div>
    ), // Use the custom table component
    a: (props) => <a target="_blank" {...props} />,
    img: ({ src, ...props }) => (
      <div className="bot-image-container">
        <MediaImage image_url={src} {...props} />
      </div>
    ),
  };

  return (
    <MarkdownWrapper>
      <div className="markdown-container">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={components}
        >
          {enhancedContent}
        </ReactMarkdown>
        {/* <video width="600" controls>
          <source src={sampleVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        {message?.source && (
          <div>
            {Object.entries(JSON.parse(message.source)).map(
              ([product, links]) =>
                links?.map((link, index) => {
                  const key = Object.keys(link)[0];
                  const value = link[key];
                  return (
                    <div key={index}>
                      {" "}
                      {/* Make sure to add a key */}
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {product === "source_link" ? (
                          <>{product.replace(/_/g, " ")}</>
                        ) : (
                          <>
                            {product} {key.toUpperCase()}
                          </>
                        )}
                      </a>
                    </div>
                  );
                })
            )}
          </div>
        )}
      </div>
    </MarkdownWrapper>
  );
};

export default MarkdownDisplay;
